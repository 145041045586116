import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { ManifestoCargaState } from './types';

const initialState: ManifestoCargaState = {
    // EFFECTS
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    imprimir: initReduxFn,
    preVisualizar: initReduxFn,
    totalizador: initReduxFn,
    compartilharSend: initReduxFn,
    encerrar: initReduxFn,
    cancelar: initReduxFn,
    emitir: { ...initReduxFn, cancel: false },

    // TABELAS
    getTable: {
        // FILTROS
        filterStatus: '',
        filterDateRange: {
            start: dayjs().startOf('date').subtract(30, 'day'),
            end: dayjs().endOf('date'),
        },
        filterNome: '',

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    // MOSTRAR DRAWERS
    showDrawerDetalhes: false,
    showDrawerCompartilhar: false,
    showDrawerDocumentosCarga: false,
    showDrawerEncerramento: false,
    showDrawerCancelar: false,
    showDrawerEmitir: false,

    // DADOS DO DRAWER
    dadosCompartilhar: {
        link: '',
        assunto: '',
        mensagem: '',
        idpk: 0,
    },

    totaisManifestoCarga: {
        totalQtdCte: 0,
        totalQtdNfe: 0,
        totalValorCarga: 0,
        totalPesoCarga: 0,
    },
};

export const ManifestoCargaModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ManifestoCargaState>(),
    effects,
});
