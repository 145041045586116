import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/p/pagamento/$id/')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        tipo?: 'Boleto' | 'Pix' | 'Cartao';
    } => {
        return {
            tipo: search?.tipo,
        };
    },
});
