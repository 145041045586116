import type { Endpoint } from 'std/types/enum/endpoint';

/** Aceita qualquer parâmetro que seja um objeto e que as propriedades respeitem essas restrições */
export type BuildUrlParams = Record<
    string,
    string | string[] | number | number[] | undefined | null
>;

/** Constrói a url usando o endpoint, parâmetros e opcionalmente um idpk */
export function buildUrl(
    endpoint: string | Endpoint,
    params?: BuildUrlParams,
    idpk?: number | string,
): string {
    let url = idpk ? `${endpoint}/${idpk}?` : `${endpoint}?`;

    if (params) {
        for (const key of Object.keys(params)) {
            const param = params[key];

            if (param !== undefined && param !== null) {
                let value = '';

                if (Array.isArray(param)) {
                    value = `[${param.map((item: string | number) =>
                        typeof item === 'string' ? `"${item}"` : item,
                    )}]`;
                } else {
                    value = String(param);
                }

                url = `${url}${key}=${value}&`;
            }
        }
    }

    url = url.substring(0, url.length - 1);
    return url;
}
