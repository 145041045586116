import { type RematchDispatch, type RematchRootState, init } from '@rematch/core';
import { type RootModel, models } from 'models';

/** @deprecated */
export const store = init({
    redux: {
        // ativaçao redux tracing
        // (serve para rastrear onde foi invocada ação que lida com state)
        // descomentar somente quando precisar
        devtoolOptions: {
            trace: false,
            traceLimit: 25,
        },
    },
    models,
});

/** @deprecated */
export type RootDispatch = RematchDispatch<RootModel>;
/** @deprecated */
export type RootState = RematchRootState<RootModel>;
