import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/a/cobranca-pix/')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        tipo: 'instantaneo' | 'cobranca';
        idpk?: number;
    } => {
        return {
            tipo: search?.tipo || 'instantaneo',
            idpk: search?.idpk,
        };
    },
});
