import type { ApiFinanceiroContaSmartPos } from 'features/financeiro-conta/types/ApiFinanceiroContaSmartPos';
import { newRequest } from 'std/api/newRequest';
import type { ApiBaseParams } from 'std/api/types';

export type ApiFinanceiroContaSmartPostGetParams = ApiBaseParams &
    Partial<{
        financeiro_conta_banco_idpk: number;
        status: 1 | 2;
    }>;

export function apiFinanceiroContaSmartPosGet(
    params?: ApiFinanceiroContaSmartPostGetParams,
): Promise<ApiFinanceiroContaSmartPos[]> {
    return newRequest({
        url: 'FinanceiroConta/SmartPos',
        type: 'get',
        params,
    });
}
