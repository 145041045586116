import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import type { Dayjs } from 'dayjs';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { DefaultColors, TipoConsulta } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import { GetTypes } from 'std/types/interfaces/GetTypes';

const DATE_FORMAT = 'DD-MM-YYYY';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            type: GetTypes;
            rom_idpk?: number;
            tipo_consulta?: TipoConsulta;
        },
        state: RootState,
    ): Promise<void> {
        const { type, rom_idpk, tipo_consulta = TipoConsulta.Resumida } = payload;

        const {
            pagination,
            sortParams,
            filterStatus,
            filterDataEmissao,
            filterSearch,
            filterNumber,
        } = state.romaneio;

        dispatch.romaneio.setState({
            get: startReduxFn(),
        });

        try {
            if (
                type === GetTypes.recordFiltering ||
                type === GetTypes.addRecord ||
                type === GetTypes.singleRecord ||
                type === GetTypes.totalizadores
            ) {
                dispatch.romaneio.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                });
            }

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                total_registros: type === GetTypes.paginationChange ? 'N' : 'S',
                registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
                qtde_registros: pagination?.pageSize || 10,
                status: filterStatus === 'Previsão' ? null : filterStatus,
                ...(sortParams.shouldSort
                    ? { orderby: `${sortParams.fieldName} ${sortParams.orderDirection}` }
                    : { orderby: 'rom_data' }),
                pesquisar: filterSearch,
                numero: filterNumber,
                tipo_consulta,
            };

            if (filterDataEmissao) {
                params.data_inicio = filterDataEmissao.start.format(DATE_FORMAT);
                params.data_fim = filterDataEmissao.end.format(DATE_FORMAT);
            }

            let url = '';

            if (type === GetTypes.singleRecord && rom_idpk) {
                url = buildUrl(Endpoint.RomaneioGet, params, rom_idpk);
            } else {
                url = buildUrl(Endpoint.RomaneioGet, params);
            }

            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            // paginação e filtros devem ser zerados após um get que alteraria a qtde de paginas
            if (
                type === GetTypes.firstGet ||
                type === GetTypes.addRecord ||
                type === GetTypes.totalizadores
            ) {
                dispatch.romaneio.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            // a paginação que já existe no state deve ser mantida, mas o total/showTotal deve mudar
            if (type === GetTypes.deleteRecord || type === GetTypes.recordFiltering) {
                dispatch.romaneio.setState({
                    pagination: {
                        ...pagination,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            dispatch.romaneio.setState({
                get: endReduxFnOk(response.data.registros || []),
            });
        } catch (error) {
            dispatch.romaneio.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async post(payload: { body: Record<string, any> }): Promise<void> {
        dispatch.romaneio.setState({
            post: startReduxFn(),
        });

        try {
            const { body } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.RomaneioPost, params);
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].rom_idpk
                : 0;

            dispatch.romaneio.setState({
                post: endReduxFnOk(idpk),
            });

            notification.success({
                message: 'Feito!',
                description: 'Romaneio cadastrado',
            });
        } catch (error) {
            dispatch.romaneio.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao cadastrar romaneio!',
                description: error.message,
            });
        }
    },

    async put(payload: {
        idpk: number;
        body: Record<string, any>;
    }): Promise<void> {
        dispatch.romaneio.setState({
            put: startReduxFn(),
        });

        try {
            const { body, idpk } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.RomaneioPut, params, idpk);
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.romaneio.setState({
                put: endReduxFnOk(null),
            });

            notification.success({
                message: 'Feito!',
                description: 'Romaneio atualizado',
            });
        } catch (error) {
            dispatch.romaneio.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao atualizar romaneio!',
                description: error.message,
            });
        }
    },

    async remove(payload: { idpk: number }): Promise<void> {
        dispatch.romaneio.setState({
            remove: startReduxFn(),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.RomaneioDelete, params, payload.idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.romaneio.setState({
                remove: endReduxFnOk(null),
            });

            notification.success({
                message: 'Feito!',
                description: 'Romaneio excluído',
            });
        } catch (error) {
            dispatch.romaneio.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao excluir romaneio',
                description: error.message,
            });
        }
    },

    async getTotais(_, state: RootState): Promise<void> {
        const { romaneio } = state;
        const { filterDataEmissao, pagination, getTotais } = romaneio;

        dispatch.romaneio.setState({
            getTotais: startReduxFn(getTotais.data),
        });

        try {
            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
                qtde_registros: pagination.pageSize || 10,
            };

            if (filterDataEmissao) {
                params.data_inicio = filterDataEmissao.start.format(DATE_FORMAT);
                params.data_fim = filterDataEmissao.end.format(DATE_FORMAT);
            }

            const url = buildUrl(Endpoint.RomaneioValoresTotais, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const cores = {
                Pendente: DefaultColors.Green,
                Finalizado: DefaultColors.Blue,
                Previsão: DefaultColors.Black,
            };

            type TValorTotal = { tipo: string; quantidade_total: number };
            const data: TValorTotal[] = response.data.registros || [];

            const totais: CardProperties[] = data.map((total: TValorTotal) => ({
                color: cores[total.tipo],
                title: total.tipo,
                amount: total.quantidade_total,
                number: total.quantidade_total,
                value: total.tipo,
            }));

            dispatch.romaneio.setState({
                getTotais: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.romaneio.setState({
                getTotais: endReduxFnError(error),
            });
        }
    },

    async getVendas(payload: {
        acao?: 'EX';
        where?: string;
        pesquisar?: string;
        orderby?: string;
        date?: Interval<Dayjs>;
    }): Promise<void> {
        dispatch.romaneio.setState({
            getVendas: startReduxFn(),
        });

        try {
            const { acao, where, pesquisar, orderby, date } = payload;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                acao,
                where,
                pesquisar,
                orderby,
            };

            if (date) {
                params.data_inicio = date.start.format('DD-MM-YYYY');
                params.data_fim = date.end.format('DD-MM-YYYY');
            }

            const url = buildUrl(Endpoint.Venda, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const { registros = [] } = response.data;

            dispatch.romaneio.setState({
                getVendas: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.romaneio.setState({
                getVendas: endReduxFnError(error),
            });
        }
    },

    async getNotas(payload: {
        acao?: 'EX';
        where?: string;
        orderby?: string;
        date?: Interval<Dayjs>;
        pesquisar?: string;
    }): Promise<void> {
        dispatch.romaneio.setState({
            getNotas: startReduxFn(),
        });

        try {
            const { acao, where, orderby, date, pesquisar } = payload;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                acao,
                where,
                orderby,
                pesquisar,
            };

            if (date) {
                params.data_inicio = date.start.format('DD-MM-YYYY');
                params.data_fim = date.end.format('DD-MM-YYYY');
            }

            const url = buildUrl(Endpoint.NotaFiscal, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const { registros = [] } = response.data;

            dispatch.romaneio.setState({
                getNotas: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.romaneio.setState({
                getNotas: endReduxFnError(error),
            });
        }
    },
});
