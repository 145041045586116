import { Outlet, getRouterContext, useMatches } from '@tanstack/react-router';
import { type MotionProps, motion, useIsPresent } from 'framer-motion';
import { forwardRef, useContext, useRef } from 'react';
import { deepClone } from 'std/util';

type Direction = 'left' | 'right' | 'up' | 'down';

type AnimatedOutletProps = MotionProps & {
    direction?: Direction;
};

export const AnimatedOutlet = forwardRef<HTMLDivElement, AnimatedOutletProps>(
    ({ direction, ...props }, ref) => {
        const isPresent = useIsPresent();

        const matches = useMatches();
        const prevMatches = useRef(matches);

        const RouterContext = getRouterContext();
        const routerContext = useContext(RouterContext);

        let renderedContext = routerContext;

        if (isPresent) {
            prevMatches.current = deepClone(matches);
        } else {
            renderedContext = deepClone(routerContext);
            renderedContext.__store.state.matches = [
                ...matches.map((m, i) => ({
                    ...(prevMatches.current[i] || m),
                    id: m.id,
                })),
                ...prevMatches.current.slice(matches.length),
            ];
        }

        return (
            <motion.div
                ref={ref}
                className='outlet'
                custom={direction}
                transition={{ duration: 0.2 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                {...props}
            >
                <RouterContext.Provider value={renderedContext}>
                    <Outlet />
                </RouterContext.Provider>
            </motion.div>
        );
    },
);
