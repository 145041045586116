import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { ConsultaPublicaState } from './types';

const initialState: ConsultaPublicaState = {
    getLayoutSistema: initReduxFn,
    cobrancasPorCliente: initReduxFn,

    filterSituacaoCobranca: 'pendentes',
};

export const ConsultaPublicaModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ConsultaPublicaState>(),
    effects,
});
