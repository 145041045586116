import { FrownOutlined } from '@ant-design/icons';
import { type ErrorComponentProps, Link } from '@tanstack/react-router';
import { Button } from 'antd';

export function DefaultErrorComponent({ error }: { error: ErrorComponentProps }) {
    const erro =
        error.error && typeof error.error === 'object' && 'mensagem' in error.error
            ? (error.error.mensagem as string)
            : String(error.error);

    return (
        <div className='container pt-10 pl-5 pr-5 mb-auto text-black'>
            <div className='mb-3' style={{ display: 'flex', alignItems: 'start' }}>
                <p className='font-bold mb-3 text-4xl'>Ops!</p>
                <FrownOutlined className='text-4xl pt-2' style={{ marginLeft: 10 }} />
            </div>

            <div className='text-gray-500 text-2xl'>
                Seu navegador se comportou de forma inesperada e não foi possível exibir a página.
            </div>

            <div
                style={{
                    margin: '50px 0px',
                    border: '2px solid #eaeaea',
                    backgroundColor: 'rgba(250, 250, 250, 0.7)',
                    width: '100%',
                    padding: 40,
                    borderRadius: 5,
                }}
            >
                <p className='font-bold text-2xl'>Descrição do problema:</p>
                <p className='text-gray-500 text-base'>{erro}</p>
            </div>

            <Link to='/'>
                <Button>Voltar para a página inicial</Button>
            </Link>
        </div>
    );
}
