import { ApiFormaPagamentoClassificacao } from 'features/forma-pagamento/api/apiFormaPagamentoConsultar';
import { z } from 'zod';
import { vendaFormaPagamentoAntecipadoSchema } from './VendaFormaPagamentoAntecipado';
import { vendaFormaPagamentoParcelaSchema } from './VendaFormaPagamentoParcela';

export enum VendaFormaPagamentoTipo {
    PosPago = 1,
    PrePago = 2,
}

export enum VendaFormaPagamentoFixarParcelas {
    Sim = 1,
    Nao = 2,
}

export const vendaFormaPagamentoSchema = z.object({
    parcelas: z.array(vendaFormaPagamentoParcelaSchema).nullish(),
    antecipado: vendaFormaPagamentoAntecipadoSchema.nullish(),

    vfp_idpk: z.number().nullish(),
    vfp_venda_idpk: z.number().nullish(),
    vfp_forma_pagamento_valor: z.number().nullish(),
    vfp_forma_pagamento_troco: z.number().nullish(),
    vfp_forma_pagamento_valor_recebido: z.number().nullish(),
    vfp_tipo: z.nativeEnum(VendaFormaPagamentoTipo).nullish(),
    vfp_classificacao: z.nativeEnum(ApiFormaPagamentoClassificacao).nullish(),
    vfp_fixar_parcelas: z.nativeEnum(VendaFormaPagamentoFixarParcelas).nullish(),
    vfp_forma_pagamento_idpk: z.number().nullish(),
    vfp_forma_pagamento_descricao: z.string().nullish(),
    vfp_forma_pagamento_parcelas: z.number().nullish(),
    vfp_financeiro_banco_pos_idpk: z.number().nullish(),
    vfp_financeiro_conta_idpk: z.number().nullish(),
    vfp_financeiro_conta_lancamento_idpk: z.number().nullish(),
    vfp_condicao_pagamento_idpk: z.number().nullish(),
    vfp_condicao_pagamento: z.string().nullish(),
    vfp_ultima_alteracao: z.string().nullish(),
});

export type VendaFormaPagamento = z.infer<typeof vendaFormaPagamentoSchema>;
