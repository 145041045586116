type ShowProps = {
    when: boolean | undefined | null | string;
    fallback?: React.ReactNode;
    children: React.ReactNode | React.ReactNode[];
};

// https://www.solidjs.com/docs/latest/api#%3Cshow%3E
/** @deprecated Usar a forma normal do react */
export function Show(props: ShowProps): React.ReactNode | null {
    const { when, fallback, children } = props;

    if (when) {
        return <>{children}</>;
    }

    return fallback || null;
}
