import { newRequest } from 'std/api/newRequest';
import type { ApiBaseParams } from 'std/api/types';
import type { ApiProduto, ApiProdutoTipo } from '../types/ApiProduto';

export type ApiProdutoGetParams = ApiBaseParams &
    Partial<{
        nome: string;
        codigo: string;
        codigo_auxiliar: string;
        codigo_barra: string;
        estoque_menor: number;
        tipo_fiscal: string[];
        pesquisa_composta: string;
        tabela_preco_idpk: number;
        venda_externa_idpk: number;
        marcadores: string;
        regra_fiscal_idpk: number;
        tipo: ApiProdutoTipo[];
        categoria_idpk: number;
        ncm: string;
        ncm_status: 'P' | 'I' | 'V';
        /** 1 Ativo 2 Inativo */
        status: 1 | 2;
    }>;

export function apiProdutoGet({
    idpk,
    params,
}: { idpk?: number; params?: ApiProdutoGetParams }): Promise<ApiProduto[]> {
    return newRequest({
        url: 'Produto/Produto',
        type: 'get',
        idpk,
        params,
    });
}
