import { createModel } from '@rematch/core';
import type { RootModel } from 'models';
import { initReduxFn, setStateReducer } from 'std/redux';
import { effects } from './effects';
import type { ContasPagarState } from './types';

const initialState: ContasPagarState = {
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    estornar: initReduxFn,
    getTotais: initReduxFn,
    postAnexo: initReduxFn,
    deleteAnexo: initReduxFn,
    postLancamento: initReduxFn,
    estornarLancamento: initReduxFn,

    selectedRows: [],
    selectedRowKeys: [],

    showModal: false,
    showPagar: false,
    showPixCobranca: false,
    showCompartilhar: false,
    showDrawerCompartilhar: false,
    showSelectAll: false,

    obrigarPreenchimentoCompetenciaDRe: false,

    tableHistorico: [],
    selectAll: false,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 22,
        showTotal: () => '',
        showSizeChanger: false,
    },
    sortParams: {
        shouldSort: false,
        fieldName: '',
        orderDirection: 'asc',
    },
    recebimentoTotais: {
        selecionadas: 0,
        valorOriginal: 0,
        valorQuitado: 0,
        jurosMulta: 0,
        valorAtualizado: 0,
    },
};

export const ContasPagarModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ContasPagarState>(),
    effects,
});
