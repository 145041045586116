import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import type { PickerProps } from 'antd/lib/date-picker/generatePicker';
import dayjs, { type Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { DATE_FORMAT } from 'std/const/date';

type InputCalendarioProps = Omit<
    PickerProps<Dayjs>,
    'value' | 'style' | 'className' | 'onOpen' | 'onChange' | 'placeholder' | 'picker'
> & {
    value?: string | Dayjs | Date;
    style?: React.CSSProperties;
    className?: string;
    onOpen?: () => void;
    onChange?: (value: string | null, date: Dayjs | null) => void;
    placeholder?: string;
    format?: string;
    entryFormat?: string;
    picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
    showTime?: boolean;
};

export function InputCalendario(props: InputCalendarioProps) {
    const {
        value,
        onChange,
        onOpen,
        className,
        style,
        placeholder,
        format,
        entryFormat,
        showTime,
        ...rest
    } = props;

    return (
        <DatePicker
            className={className}
            locale={locale}
            value={value ? dayjs(value, entryFormat || DATE_FORMAT) : undefined}
            format={format || DATE_FORMAT}
            style={style}
            onOpenChange={onOpen}
            placeholder={placeholder}
            showTime={showTime}
            onChange={(date) => {
                if (Array.isArray(date)) {
                    return;
                }

                onChange?.(date ? date.format(format || DATE_FORMAT) : null, date);
            }}
            {...rest}
        />
    );
}
