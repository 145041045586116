import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import type { TFormCondicaoPagamento } from 'pages/setting/padroes/PrazosPagamento/modal/forms/types';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
        },
        state: RootState,
    ): Promise<void> {
        const { condicaoPagamento } = state;
        const { getTable, get } = condicaoPagamento;

        dispatch.condicaoPagamento.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        const { registroInitial, qtdRegistros, sortParams, pagination } = getTable;

        const { total_registros, registro_inicial, qtde_registros, sort, ...restPayload } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            orderby: 'cop_idpk desc',
            ...restPayload,
        };

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        const url = buildUrl(Endpoint.CondicaoPagamentoConsultar, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            const { condicaoPagamento: newCondicaoPagamento } = state;
            const { getTable: newGetTable } = newCondicaoPagamento;

            dispatch.condicaoPagamento.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...newGetTable,
                    updateTable: false,
                    registroInitial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    qtdRegistros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.condicaoPagamento.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: {
            body: TFormCondicaoPagamento;
            updateTable: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.condicaoPagamento.setState({
            post: startReduxFn(state.condicaoPagamento.post.data),
        });

        const { body, updateTable } = payload;

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.CondicaoPagamentoInserir, params);

            const response: ApiResponse = await comTokenPost(url, body);

            throwIfResponseIsErr(response);

            const cop_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].cop_idpk
                : 0;

            dispatch.condicaoPagamento.setState({
                post: endReduxFnOk(cop_idpk),
                showModalCondicaoPagamento: false,
            });

            if (updateTable) {
                dispatch.condicaoPagamento.get({ total_registros: 'S' });
            }

            notification.success({
                message: 'Feito!',
                description: 'Condição de Pagamento cadastrada.',
            });
        } catch (error) {
            dispatch.condicaoPagamento.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar essa condição de pagamento.',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            cop_idpk: number;
            body: TFormCondicaoPagamento;
            updateTable: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.condicaoPagamento.setState({
            put: startReduxFn(state.condicaoPagamento.put.data),
        });

        try {
            const { cop_idpk, body, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.CondicaoPagamentoAlterar, params, cop_idpk);

            const response: ApiResponse = await comTokenPut(url, body);

            throwIfResponseIsErr(response);

            dispatch.condicaoPagamento.setState({
                put: endReduxFnOk('success'),
                showModalCondicaoPagamento: false,
            });

            if (updateTable) {
                dispatch.condicaoPagamento.get({ total_registros: 'S' });
            }

            notification.success({
                message: 'Feito!',
                description: 'Condição de Pagamento atualizada',
            });
        } catch (error) {
            dispatch.condicaoPagamento.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a condição de pagamento',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { cop_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.condicaoPagamento.setState({
            remove: startReduxFn(),
        });

        try {
            const { cop_idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.CondicaoPagamentoRemover, params, cop_idpk);

            const response: ApiResponse = await comTokenRemove(url);

            throwIfResponseIsErr(response);

            dispatch.condicaoPagamento.setState({
                remove: endReduxFnOk('success'),
            });

            if (updateTable) {
                const { condicaoPagamento } = state;
                const { getTable } = condicaoPagamento;

                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.condicaoPagamento.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.condicaoPagamento.get({ total_registros: 'S' });
                }
            }

            notification.success({
                message: 'Feito.',
                description: 'Condição de Pagamento excluída',
            });
        } catch (error) {
            dispatch.condicaoPagamento.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou.',
                description: String(error),
            });
        }
    },
});
