import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/p/cliente/$slug')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        documento?: string;
    } => {
        return {
            documento: search?.documento,
        };
    },
});
