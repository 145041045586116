import { useEffect, useState } from 'react';

// sources:
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#a_simple_example
// https://www.webtips.dev/webtips/react-hooks/element-in-viewport

/**
 * element: ref do elemento para checar sua visibilidade
 * rootMargin: a partir de qtos pixels é para considerar como visível. ex: '0px'
 */
export function useIsElementInView<T extends HTMLElement>(
    element: React.RefObject<T>,
    rootMargin: string,
): boolean {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry) {
                    return;
                }

                setState(entry.isIntersecting);
            },
            { rootMargin },
        );

        const elRef = element.current;

        if (elRef) {
            observer.observe(elRef);
        }

        return () => {
            if (elRef) {
                observer.unobserve(elRef);
            }
        };
    }, [element, rootMargin]);

    return isVisible;
}
