import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/a/configuracao/empresa/')({
    validateSearch: (
        search: Record<string, unknown>,
    ): {
        tab?: string;
        menu?: string;
    } => {
        return {
            tab: String(search?.tab || ''),
            menu: String(search?.menu || ''),
        };
    },
});
