import { createFileRoute, redirect } from '@tanstack/react-router';
import { isNumber } from 'std/string';
import { type FormEditMode, formEditMode } from 'std/types/enum';

export const Route = createFileRoute('/a/romaneio/$mode/$idpk/')({
    beforeLoad: ({ params }) => {
        const { mode, idpk } = params;

        if (!formEditMode.includes(mode as FormEditMode)) {
            throw redirect({ to: '/' });
        }

        if (!isNumber(idpk)) {
            throw redirect({ to: '/' });
        }
    },
});
