import type { TreeItem } from '@nosferatu500/react-sortable-tree';
import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import { nanoid } from 'nanoid';
import type { RootDispatch, RootState } from 'state/store';
import { buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponseStatus } from 'std/api/types';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: { tipo: 'Despesa' | 'Receita' | null; fields?: string },
        state: RootState,
    ): Promise<void> {
        dispatch.financeiroCategoriaReceita.setState({
            get: startReduxFn(state.financeiroCategoriaReceita.get.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...(payload.tipo && { tipo: payload.tipo }),
            ...(payload.fields && { fields: payload.fields }),
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registrosData = response.data?.registros ? response.data.registros : [];

            dispatch.financeiroCategoriaReceita.setState({
                get: endReduxFnOk(registrosData),
            });
        } catch (error) {
            dispatch.financeiroCategoriaReceita.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getTreeViewData(
        payload: { tipo: 'Despesa' | 'Receita' | null; fields?: string },
        state: RootState,
    ): Promise<void> {
        dispatch.financeiroCategoriaReceita.setState({
            getTreeViewData: startReduxFn(state.financeiroCategoriaReceita.getTreeViewData.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            treeview: 'S',
            ...(payload.tipo && { tipo: payload.tipo }),
            ...(payload.fields && { fields: payload.fields }),
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registrosData = response.data ? response.data.object : [];

            dispatch.financeiroCategoriaReceita.setState({
                getTreeViewData: endReduxFnOk([...registrosData]),
            });
        } catch (error) {
            dispatch.financeiroCategoriaReceita.setState({
                getTreeViewData: endReduxFnError(error),
            });
        }
    },

    async put(
        payload: {
            dados: TreeItem[];
        },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        const { dados } = payload;

        dispatch.financeiroCategoriaReceita.setState({
            put: startReduxFn(state.financeiroCategoriaReceita.put.data),
        });
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params, 'Receita');

        try {
            const response: ApiResponse = await comTokenPut(url, dados);
            throwIfResponseIsErr(response);

            dispatch.financeiroCategoriaReceita.setState({
                put: endReduxFnOk(null),
                triggerUpdate: nanoid(),
            });

            return 'sucesso';
        } catch (error) {
            dispatch.financeiroCategoriaReceita.setState({
                put: endReduxFnError(error),
            });
            return 'erro';
        }
    },

    async remove(
        payload: {
            message: string;
            position: string;
        },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        const { message, position } = payload;
        dispatch.financeiroCategoriaReceita.setState({
            remove: startReduxFn(state.financeiroCategoriaReceita.remove.data),
        });
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            position,
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params, 'Receita');

        try {
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            notification.success({
                message: 'Feito!',
                description: message || '',
            });

            dispatch.financeiroCategoriaReceita.setState({
                remove: endReduxFnOk(null),
                triggerUpdate: nanoid(),
            });

            return 'sucesso';
        } catch (error) {
            dispatch.financeiroCategoriaReceita.setState({
                remove: endReduxFnError(error),
            });
            return 'erro';
        }
    },
});
