import { ArrowLeftOutlined, InboxOutlined, SendOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { Button, Col, Form, Row, Spin, Typography, Upload, type UploadProps } from 'antd';
import { InputNumber } from 'components/input/InputNumber';
import { InputCalendario } from 'components/revisar/input/InputCalendario';

const { Paragraph } = Typography;
const { Dragger } = Upload;

export type FormImportacaoDiValues = {
    DI_numero: string;
    DI_data_desembaraco: string;
};

type StepInfoBoxProps = {
    draggerProps: UploadProps;
    proccessFiles: (formValues?: FormImportacaoDiValues) => void;
    disableProcessarBtn: boolean;
    isUploading: boolean;
    tipo: 'nfe' | 'di';
};

export function StepOne({
    draggerProps,
    proccessFiles,
    disableProcessarBtn,
    isUploading,
    tipo,
}: StepInfoBoxProps) {
    const navigate = useNavigate();
    const [form] = Form.useForm<FormImportacaoDiValues>();

    const processar = async (): Promise<void> => {
        if (tipo === 'di') {
            const values = await form.validateFields();
            proccessFiles(values);
        } else {
            proccessFiles();
        }
    };

    return (
        <>
            <Row className='w-full'>
                {tipo === 'nfe' ? (
                    <>
                        <Paragraph style={{ fontSize: 16 }}>
                            Este procedimento permite que sejam importadas notas fiscais emitidas
                            por outros sistemas.
                        </Paragraph>
                        <Paragraph style={{ fontSize: 16 }}>
                            Serão alimentados os seguintes cadastros: Notas Fiscais, Vendas,
                            Clientes e Produtos.
                        </Paragraph>
                    </>
                ) : (
                    <>
                        <Paragraph style={{ fontSize: 16 }}>
                            Este procedimento permite a importação do XML espelho de uma DI
                            (Declaração de importação) gerado pelo Siscomex para ser emitida pela
                            sua empresa.
                        </Paragraph>
                    </>
                )}
            </Row>

            {tipo === 'di' && (
                <Form form={form} layout='vertical'>
                    <Row gutter={10}>
                        <Col xs={24} sm={12} md={10} lg={10} xl={6}>
                            <Form.Item
                                name='DI_numero'
                                label='Número da DI/DS/DA/DIRE/DUImp'
                                rules={[{ required: true }]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                name='DI_data_desembaraco'
                                label='Data de despacho'
                                rules={[{ required: true }]}
                            >
                                <InputCalendario />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}

            <Row justify='center' gutter={[10, 10]}>
                <Col xs={24} sm={12} md={8} lg={4}>
                    <Button
                        style={{ width: '100%' }}
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                            navigate({ to: '/a/importacao/xml/$tipo', params: { tipo } });
                        }}
                    >
                        Voltar
                    </Button>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4}>
                    <Button
                        style={{ width: '100%' }}
                        icon={<SendOutlined />}
                        type='primary'
                        onClick={processar}
                        disabled={disableProcessarBtn}
                    >
                        Processar
                    </Button>
                </Col>
            </Row>

            <div className='w-full mt-3'>
                <Dragger id='dragger-input' {...draggerProps}>
                    <Spin size='large' tip='Carregando...' spinning={isUploading}>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>
                            Clique ou arraste os arquivos nesta área para fazer o upload
                        </p>
                        <p className='ant-upload-hint'>
                            Suporta envio de múltiplos arquivos no formato XML
                        </p>
                    </Spin>
                </Dragger>
            </div>
        </>
    );
}
