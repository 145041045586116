import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import { useLoginStore } from 'features/login/store';
import type { TFormManifestoCarga } from 'pages/manifesto-carga/forms/type/types';
import type { TRowManifestoCarga } from 'pages/manifesto-carga/table/dataFormatTable';
import { type RootDispatch, type RootState, store } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { isResponseOk, throwIfResponseIsErr } from 'std/api/util';
import { openPDF } from 'std/pdf';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { DefaultColors } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';
import { deepClone } from 'std/util';
import { downloadFile } from 'std/util';
import { EStatusMdf, type TTotaisMdf } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            status?: string;
            pesquisar?: string;
            data_inicio?: string;
            data_fim?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        const { manifestoCarga } = state;
        const { getTable, get } = manifestoCarga;

        dispatch.manifestoCarga.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        try {
            const {
                filterDateRange,
                filterNome,
                filterStatus,
                registroInitial,
                qtdRegistros,
                sortParams,
                pagination,
            } = getTable;

            const {
                status,
                pesquisar,
                data_inicio,
                data_fim,
                total_registros,
                registro_inicial,
                qtde_registros,
                sort,
                resetPagination,
                ...restPayload
            } = payload;

            // MONTANDO OS PARAMETROS OBRIGATÓRIOS
            const params: BuildUrlParams = {
                data_inicio: data_inicio || filterDateRange.start.format('DD-MM-YYYY'),
                data_fim: data_fim || filterDateRange.end.format('DD-MM-YYYY'),
                registro_inicial:
                    registro_inicial !== null && registro_inicial !== undefined
                        ? registro_inicial
                        : registroInitial,
                qtde_registros: qtde_registros || qtdRegistros,
                orderby: 'mdf_data_emissao desc, mdf_idpk desc',
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                ...restPayload,
            };

            // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
            if (status || filterStatus) {
                params.status = `["${status || filterStatus}"]`;
            }

            // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
            if (pesquisar || filterNome) {
                params.pesquisar = pesquisar || filterNome;
            }

            // CASO TENHA QUE ORDENAR OS DADOS (*OPCIONAL)
            if (sort?.shouldSort || sortParams?.shouldSort) {
                params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                    sort?.orderDirection || sortParams?.orderDirection
                }`;
            }

            // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
            if (total_registros) {
                params.total_registros = total_registros;
            }

            // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
            if (resetPagination) {
                params.registro_inicial = 0;
                params.total_registros = 'S';
            }

            const url = buildUrl(Endpoint.MDFeConsultar, params);
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.manifestoCarga.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    updateTable: false,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async totalizador(_, state: RootState): Promise<void> {
        const { manifestoCarga } = state;
        const { totalizador, getTable } = manifestoCarga;

        dispatch.manifestoCarga.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const { filterNome, filterDateRange } = getTable;

        const ambiente = useLoginStore.getState().getEmpresa()?.emp_fiscal_ambiente;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            pesquisar: filterNome,
            data_inicio: filterDateRange?.start.format('DD-MM-YYYY'),
            data_fim: filterDateRange?.end.format('DD-MM-YYYY'),
            ambiente,
        };

        const url = buildUrl(Endpoint.MDFeValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                Incorreto: DefaultColors.Orange,
                Aguardando: DefaultColors.Blue,
                Emitido: DefaultColors.Green,
                Cancelado: DefaultColors.Red,
                Encerrado: DefaultColors.Purple,
                Previsão: DefaultColors.Black,
            };

            const totais: CardProperties[] = registros.map((total: TTotaisMdf) => ({
                color: cores[total.tipo],
                title: total.tipo,
                amount: total.valor_total,
                number: total.quantidade_total,
                value: total.tipo,
            }));

            dispatch.manifestoCarga.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { mdf_idpk: number }, state: RootState): Promise<void> {
        dispatch.manifestoCarga.setState({
            getOne: startReduxFn(state.manifestoCarga.getOne.data),
        });

        const { mdf_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.MDFeConsultar, params, mdf_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.manifestoCarga.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(payload: { body: TFormManifestoCarga }, state: RootState): Promise<void> {
        dispatch.manifestoCarga.setState({
            post: startReduxFn(state.manifestoCarga.post.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.MDFeInserir, params);

            const response = await comTokenPost(url, payload.body);

            throwIfResponseIsErr(response);

            const mdf_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].mdf_idpk
                : 0;

            dispatch.manifestoCarga.setState({
                post: endReduxFnOk({ mdf_idpk }),
            });

            notification.success({
                message: 'Feito!',
                description: 'Manifesto de carga cadastrado',
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar o manifesto de carga!',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            mdf_idpk: number;
            body: TFormManifestoCarga;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.manifestoCarga.setState({
            put: startReduxFn(state.manifestoCarga.put.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const { mdf_idpk = 0, body } = payload;
            const url = buildUrl(Endpoint.MDFeAlterar, params, mdf_idpk);
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.manifestoCarga.setState({
                put: endReduxFnOk('Success'),
            });

            notification.success({
                message: 'Feito!',
                description: 'Manifesto de carga atualizado',
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar o manifesto de carga!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { mdf_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.manifestoCarga.setState({
            remove: startReduxFn(state.manifestoCarga.remove.data),
        });

        try {
            const { mdf_idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

            const url = buildUrl(Endpoint.MDFeRemover, params, mdf_idpk);

            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.manifestoCarga.setState({
                remove: endReduxFnOk('Success'),
            });

            if (updateTable) {
                const { manifestoCarga } = state;
                const { getTable } = manifestoCarga;
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.manifestoCarga.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.manifestoCarga.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.manifestoCarga.get({ total_registros: 'S' });

                    dispatch.manifestoCarga.totalizador({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Manifesto de carga excluído',
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async imprimir(
        payload: { link: string; open: boolean; tipo: number },
        state: RootState,
    ): Promise<void> {
        const { manifestoCarga } = state;
        const { getTable } = manifestoCarga;

        dispatch.manifestoCarga.setState({
            imprimir: startReduxFn(state.manifestoCarga.imprimir.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { link, open, tipo } = payload;

        try {
            if (tipo !== 2) {
                if (open) {
                    openPDF({
                        access: 'autenticado',
                        type: 'link',
                        link,
                    });
                } else {
                    const filename = link.split('/').at(-1) || 'file.xml';
                    downloadFile(link, filename);
                }
            } else {
                window.open(link, '_blank');
            }

            dispatch.manifestoCarga.setState({
                imprimir: endReduxFnOk(null),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                imprimir: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description:
                    (error as { message: string })?.message || 'Não foi possível gerar o arquivo',
            });
        }
    },

    async preVisualizar(
        payload: {
            mdf_idpk: number;
            tipo?: number;
        },
        state: RootState,
    ): Promise<void> {
        const { manifestoCarga } = state;
        const { getTable } = manifestoCarga;

        dispatch.manifestoCarga.setState({
            preVisualizar: startReduxFn(state.manifestoCarga.preVisualizar.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { mdf_idpk, tipo = 1, ...rest } = payload;

            const params = {
                ...rest,
                empresa_idpk: useLoginStore.getState().empresaIdpk,
            };

            const url = buildUrl(Endpoint.MDFePreVisualizar, params, mdf_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const { data } = response;

            if (data?.mdf_mdfe_pdf && tipo === 1) {
                dispatch.manifestoCarga.imprimir({
                    link: data?.mdf_mdfe_pdf || '',
                    open: true,
                    tipo: 1,
                });
            }

            if (data?.mdf_mdfe_xml && tipo === 2) {
                dispatch.manifestoCarga.imprimir({
                    link: data?.mdf_mdfe_xml || '',
                    open: true,
                    tipo: 2,
                });
            }

            dispatch.manifestoCarga.setState({
                preVisualizar: endReduxFnOk(data),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.manifestoCarga.setState({
                preVisualizar: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async emitir(
        payload: {
            itens: TRowManifestoCarga[];
            compartilhar_por?: string;
            // sendToPrint?: boolean
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.manifestoCarga.setState({
            emitir: { data: null, error: null, loading: true, cancel: false },
        });

        const { compartilhar_por, itens, updateTable } = payload;
        const params = { compartilhar_por, empresa_idpk: useLoginStore.getState().empresaIdpk };

        let hasError = false;
        const idpksSuccess: number[] = [];

        try {
            for (let index = 0; index < itens.length; index += 1) {
                const item = itens[index] as TRowManifestoCarga;

                const { manifestoCarga } = store.getState() as RootState;
                const {
                    emitir: { cancel = false } = {},
                    getTable,
                } = manifestoCarga || {};

                if (cancel) {
                    item.emitirCancel = true;

                    dispatch.manifestoCarga.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;

                    throw new Error('Emissão da mdfe cancelada pelo usuário');
                }

                item.loading = true;

                dispatch.manifestoCarga.setState({
                    getTable: {
                        ...getTable,
                        selectedRows: [...itens.slice(0, index), item, ...itens.slice(index + 1)],
                    },
                });

                const url = buildUrl(Endpoint.MDFeEnviar, params, item.key);
                const response = await comTokenPost(url);

                if (!isResponseOk(response)) {
                    const apiErrorMessage = response?.data?.informacao;
                    item.mdf_status = 2;
                    item.loading = false;

                    if (apiErrorMessage) {
                        item.motivoErro = apiErrorMessage.map((errors) => errors?.mensagem || '');
                    } else {
                        item.motivoErro = response?.data?.mensagem;
                    }

                    dispatch.manifestoCarga.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;
                    continue;
                }

                const {
                    motivo,
                    mensagem = 'Falha ao tentar emitir a nota fiscal!',
                    mdf_mdfe_pdf = '',
                    mdf_mdfe_xml = '',
                    mdf_status = EStatusMdf.Emitido,
                } = response?.data || {};

                if (mdf_status !== EStatusMdf.Emitido) {
                    item.mdf_status = mdf_status;
                    item.loading = false;
                    item.motivoErro = motivo || mensagem;

                    dispatch.manifestoCarga.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;
                    continue;
                }

                item.mdf_mdfe_xml = mdf_mdfe_xml;
                item.mdf_mdfe_pdf = mdf_mdfe_pdf;
                item.mdf_status = mdf_status;
                item.loading = false;
                item.emitirOk = true;

                dispatch.manifestoCarga.setState({
                    getTable: {
                        ...getTable,
                        selectedRows: [...itens.slice(0, index), item, ...itens.slice(index + 1)],
                    },
                });

                idpksSuccess.push(item.key);
            }

            const { manifestoCarga } = state;
            const { showDrawerEmitir, getTable } = manifestoCarga || {};

            if (showDrawerEmitir) {
                dispatch.manifestoCarga.setState({
                    emitir: {
                        data: hasError ? null : 'Success',
                        loading: false,
                        error: hasError ? 'Houve um erro em alguma das mdfe' : null,
                        cancel: false,
                    },
                });
            } else {
                const ajustRowsSelected = itens
                    .filter((row) => !row.emitirOk)
                    .map((row) => {
                        row.emitirCancel = false;
                        row.emitirOk = false;
                        row.motivoErro = undefined;
                        row.loading = false;
                        return row;
                    });

                dispatch.manifestoCarga.setState({
                    emitir: {
                        data: 'Success',
                        loading: false,
                        error: null,
                        cancel: false,
                    },
                    getTable: {
                        ...getTable,
                        selectedRows: ajustRowsSelected,
                    },
                });
            }

            if (updateTable) {
                dispatch.manifestoCarga.get({});
            }
        } catch (error) {
            dispatch.manifestoCarga.setState({
                emitir: {
                    data: 'Error',
                    loading: false,
                    error,
                    cancel: false,
                },
            });
        }
    },

    async compartilharSend(
        payload: {
            idpk: number;
            tipo: string;
            template_id: string;
            tags?: { nome: string; valor?: string }[];
            fin_email?: string | null;
            fin_email_cc?: string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.manifestoCarga.setState({
            compartilharSend: startReduxFn(state.manifestoCarga.compartilharSend.data),
        });

        const { idpk, tags, fin_email, fin_email_cc, ...rest } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...rest,
        };
        const bodySend = payload.tipo !== 'W' ? { tags, fin_email, fin_email_cc } : undefined;

        const url = buildUrl(Endpoint.NotaFiscalCompartilhar, params, idpk);

        try {
            const response = await comTokenPost(url, bodySend);
            throwIfResponseIsErr(response);

            dispatch.manifestoCarga.setState({
                compartilharSend: endReduxFnOk(null),
                showDrawerCompartilhar: false,
            });

            if (payload.tipo === 'E') {
                notification.success({
                    message: 'Email enviado',
                    description:
                        'Atenção, a notificação pode demorar até 5 minutos para ser entregue.',
                });
            }
        } catch (error) {
            dispatch.manifestoCarga.setState({
                compartilharSend: endReduxFnError(error),
            });

            if (payload.tipo === 'E') {
                notification.error({
                    message: 'Falhou!',
                    description:
                        (error as { message: string })?.message || 'Não foi possível enviar!',
                });
            }
        }
    },

    async encerrar(
        payload: {
            mdf_idpk: number;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.manifestoCarga.setState({
            encerrar: startReduxFn(state.manifestoCarga.encerrar.data),
        });

        try {
            const { mdf_idpk, updateTable, ...rest } = payload;

            const params = {
                ...rest,
                empresa_idpk: useLoginStore.getState().empresaIdpk,
            };

            const url = buildUrl(Endpoint.MDFeEncerrar, params, mdf_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const { data } = response;

            if (data) {
                const { manifestoCarga } = state;
                const {
                    getTable,
                    getTable: { selectedRow = undefined },
                } = manifestoCarga || {};

                if (selectedRow) {
                    const newSelectedRow = deepClone(selectedRow);

                    newSelectedRow.mdf_mdfe_xml = data.evento_pdf;
                    newSelectedRow.mdf_mdfe_pdf = data.evento_xml;

                    dispatch.manifestoCarga.setState({
                        getTable: {
                            ...getTable,
                            selectedRow: newSelectedRow,
                        },
                    });
                }
            }

            dispatch.manifestoCarga.setState({
                encerrar: endReduxFnOk(data),
            });

            if (updateTable) {
                dispatch.manifestoCarga.get({});
            }
        } catch (error) {
            dispatch.manifestoCarga.setState({
                encerrar: endReduxFnError(error),
            });
        }
    },

    async cancelar(
        payload: {
            mdf_idpk: number;
            justificativa?: string;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.manifestoCarga.setState({
            cancelar: startReduxFn(state.manifestoCarga.cancelar.data),
        });

        try {
            const { mdf_idpk, updateTable, ...rest } = payload;

            const params = {
                ...rest,
                empresa_idpk: useLoginStore.getState().empresaIdpk,
            };

            const url = buildUrl(Endpoint.MDFeCancelar, params, mdf_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const { data } = response;

            if (data) {
                const { manifestoCarga } = state;
                const {
                    getTable,
                    getTable: { selectedRow = undefined },
                } = manifestoCarga || {};

                if (selectedRow) {
                    const newSelectedRow = deepClone(selectedRow);

                    newSelectedRow.mdf_mdfe_xml = data.evento_pdf;
                    newSelectedRow.mdf_mdfe_pdf = data.evento_xml;

                    dispatch.manifestoCarga.setState({
                        getTable: {
                            ...getTable,
                            selectedRow: newSelectedRow,
                        },
                    });
                }
            }

            dispatch.manifestoCarga.setState({
                cancelar: endReduxFnOk(data),
            });

            if (updateTable) {
                dispatch.manifestoCarga.get({});
            }
        } catch (error) {
            dispatch.manifestoCarga.setState({
                cancelar: endReduxFnError(error),
            });
        }
    },
});
