import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { apiSistemaPost } from 'features/sistema/api';
import type { ApiSistema } from 'features/sistema/types';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

type SistemaStore = {
    splash: boolean;
    sistema: ApiSistema | undefined;
    sistemaAtualizacaoData: Dayjs | undefined;
    downloadSistema: () => Promise<void>;
};

export const useSistemaStore = create<SistemaStore>()(
    devtools(
        persist(
            (set, get) => ({
                splash: false,
                sistema: undefined,
                sistemaAtualizacaoData: undefined,
                downloadSistema: async () => {
                    const now = dayjs();
                    const old = get().sistemaAtualizacaoData;

                    if (old && now.diff(old, 'day') < 1) {
                        return;
                    }

                    set({ splash: true });

                    try {
                        const sistemas = await apiSistemaPost();
                        set({ sistema: sistemas[0], sistemaAtualizacaoData: now });
                        set({ splash: false });
                    } catch (e) {
                        console.error(e);
                    }
                },
            }),
            { name: 'sistema' },
        ),
        { name: 'sistema' },
    ),
);
