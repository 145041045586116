import { z } from 'zod';

export const vendaNotaFiscalSchema = z.object({
    vnf_idpk: z.number().nullish(),
    vnf_venda_idpk: z.number().nullish(),
    vnf_nfce_idpk: z.number().nullish(),
    vnf_nota_fiscal_idpk: z.number().nullish(),
});

export type VendaNotaFiscal = z.infer<typeof vendaNotaFiscalSchema>;
