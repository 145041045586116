import { notification } from 'antd';
import type { AxiosResponse } from 'axios';
import { useLoginStore } from 'features/login/store';
import { axiosWithToken, axiosWithoutToken } from './axios';
import { type BuildUrlParams, buildUrl } from './buildUrl';

export async function newRequest<T>({
    token = true,
    url,
    type,
    idpk,
    params,
    body,
    empresa_idpk,
    returnFullResponse,
    timeout,
    showErrorNotification = true,
}: {
    /** Se a requisição precisa de token. Default: true */
    token?: boolean;

    /** Endpoint. Ex: Cliente/Consultar */
    url: string;
    /** Tipo da requisição HTTP */
    type: 'get' | 'post' | 'put' | 'delete';

    /** Idpk para ser incluído na url */
    idpk?: number;
    /** Parâmetros. Se token = true, será enviado o idpk da empresa. */
    params?: BuildUrlParams;
    /** Body da requisição */
    body?: Record<string, unknown> | Record<string, unknown>[];

    empresa_idpk?: number;

    /**
     * Se true vai retornar a response inteira ao invés de apenas retornar a key "registros"
     * Se não tiver a key registros ele vai retornar automaticamente a response inteira
     */
    returnFullResponse?: boolean;

    timeout?: number;
    showErrorNotification?: boolean;
}): Promise<T> {
    const renovandoToken = useLoginStore.getState().renovandoToken;

    // Espera pra sempre, quando renovar o token vai reiniciar a página e refazer as requisições
    if (renovandoToken) {
        await new Promise(() => {
            //
        });
    }

    try {
        const axios = token ? axiosWithToken : axiosWithoutToken;

        let paramsWithEmpresaIdpk: BuildUrlParams | undefined;

        if (empresa_idpk) {
            paramsWithEmpresaIdpk = {
                ...params,
                empresa_idpk,
            };
        } else {
            paramsWithEmpresaIdpk = token
                ? { empresa_idpk: useLoginStore.getState().empresaIdpk, ...params }
                : params;
        }

        const urlWithParams = buildUrl(url, paramsWithEmpresaIdpk, idpk);

        const response = await axios[type](urlWithParams, body, { timeout });
        throwIfResponseIsNotValid(response, type);

        // Se returnFullResponse, vai retornar a response inteira
        // Também trata endpoints que em caso de sucesso retornam um objeto sem a chave registros. ex: put / delete
        if (returnFullResponse || response.data.registros === undefined) {
            return response.data;
        }

        const registros = response.data.registros || [];

        // Caso algum get solicitar o total de registros
        // colocamos no primeiro registro #gambi
        if (response.data.total_registros) {
            const total_registros = response.data.total_registros;

            if (registros[0]) {
                registros[0].total_registros = total_registros;
            }
        }

        return registros;
    } catch (e) {
        if (showErrorNotification) {
            notification.error({
                message: 'Falha ao realizar requisição',
                description: e.message,
            });
        }

        console.error('newRequest', e);
        throw e;
    }
}

function throwIfResponseIsNotValid(
    response: AxiosResponse,
    type: 'get' | 'post' | 'put' | 'delete',
): void {
    if (response?.data === undefined) {
        throw new Error('Conexão falhou ou servidor está indisponível.');
    }

    if (response.data.status === 'erro') {
        throw new Error(
            response.data.mensagem ||
                response.data.mensagem_original ||
                JSON.stringify(response.data),
        );
    }

    // Ao realizar um post, pode ser que algum registro falhou ao ser inserido
    if (type !== 'post') {
        return;
    }

    if (Array.isArray(response.data.registros)) {
        for (const registro of response.data.registros) {
            if ('status' in registro) {
                if (registro.status === 'erro') {
                    throw new Error(registro.message);
                }
            }
        }
    }
}
