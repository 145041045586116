/** Cor no padrão HSL */
type HSL = {
    h: number;
    s: number;
    l: number;
};

/** Cor no padrão RGB */
export type RGB = {
    r: number;
    b: number;
    g: number;
};

export function isHex(value: string): boolean {
    const regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
    return regex.test(value);
}

export function hexToRGB(hex: string): RGB | undefined {
    if (!isHex(hex)) {
        return undefined;
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const value = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);

    if (!(result?.[1] && result[2] && result[3])) {
        return undefined;
    }

    return {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
    };
}

export function hexToHSL(hex: string): HSL | undefined {
    if (!isHex(hex)) {
        return undefined;
    }

    const rgb = hexToRGB(hex);

    if (!rgb) {
        return undefined;
    }

    // Then to HSL
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;

    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;

    if (delta === 0) {
        h = 0;
    } else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}

/**
 * @percentage em quantos % a cor deve ser alterada
 * @mode lighter para esclarecer e darker para escurecer a cor
 */
export function changeHexColorLightness(
    hex: string,
    percentage: number,
    mode: 'lighter' | 'darker',
): string | undefined {
    const hsl = hexToHSL(hex);

    if (!hsl) {
        return undefined;
    }

    return `hsl(${hsl.h} ${hsl.s}% ${
        mode === 'lighter' ? hsl.l + percentage : hsl.l - percentage
    }%)`;
}

export function getTextColor(hexcolor: string): 'black' | 'white' {
    const rgb = hexToRGB(hexcolor);

    if (!rgb) {
        return 'white';
    }

    const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
    return brightness > 125 ? 'black' : 'white';
}
