import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import type { RootModel } from 'models';
import { initReduxFn, setStateReducer } from 'std/redux';
import { effects } from './effects';
import type { ComissaoState } from './types';

const initialState: ComissaoState = {
    get: initReduxFn,
    getOne: initReduxFn,
    getTotais: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,

    // TABELAS
    getTable: {
        // FILTROS
        filterMesAno: dayjs().startOf('date').format('MM/YYYY'),

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    // MOSTRAR DRAWERS
    showDrawerGetDetalhes: false,
    showDrawerFormLancamento: false,
    showDrawerFormPagamento: false,

    // IMPRESSÃO
    impressaoRecibo: false,

    // VALORES PARA DRAWERS
};

export const ComissaoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ComissaoState>(),
    effects,
});
