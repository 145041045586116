import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'state';
import type { RootDispatch } from 'state/store';

export const useStateControlCatalogo = () => {
    const dispatch = useDispatch<RootDispatch>();

    const catalogoProdutos = useSelector((state: AppState) => state.catalogoProdutos);
    const catalogoActions = dispatch.catalogoProdutos;

    return {
        catalogoProdutos,
        catalogoActions,
        dispatch,
    };
};
