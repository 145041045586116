import { createModel } from '@rematch/core';
import { getOneMonthPeriod } from 'std/datetime';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { FinanceiroCategoriaReceitaState } from './types';

const initialState: FinanceiroCategoriaReceitaState = {
    get: initReduxFn,
    getTreeViewData: initReduxFn,
    getOne: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,

    showModal: false,
    showModalMessageError: false,
    totalRegister: 0,
    initialRegister: 0,
    registersPerPage: 10,
    currentPage: 0,
    updated: false,
    loading: false,
    filterDescricao: '',
    filterDate: getOneMonthPeriod(),
    filterUsuarioIDPK: 0,
    filterStatus: '',
};

export const FinanceiroCategoriaReceitaModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<FinanceiroCategoriaReceitaState>(),
    effects,
});
