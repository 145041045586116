import { Row, Spin, Typography } from 'antd';

const { Title } = Typography;

export function StepTwo() {
    return (
        <Row className='flex-col items-center'>
            <Title level={3} className='w-full text-center'>
                <div>Processando arquivos, por favor aguarde.</div>
                <div>Caso sair desta página ou fecha-lá, as informações serão perdidas.</div>
            </Title>
            <Spin />
        </Row>
    );
}
