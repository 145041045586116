import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { CaixaBancoState } from './types';

const initialState: CaixaBancoState = {
    // EFFECTS
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    totalizador: initReduxFn,
    estornar: initReduxFn,
    getRelatorio: initReduxFn,
    exportarCsv: initReduxFn,
    getExtrato: initReduxFn,
    carregarExtrato: initReduxFn,
    removeExtrato: initReduxFn,

    // TABELAS
    getTable: {
        // FILTROS
        filterDateRange: {
            start: dayjs(),
            end: dayjs(),
        },
        filterStatus: '',
        filterNome: '',

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },
    getTableExtrato: {
        // FILTROS
        filterDateRange: {
            start: dayjs().startOf('date').subtract(30, 'day'),
            end: dayjs().endOf('date'),
        },
        filterNome: '',

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },
    saldoAtual: 0,

    // MOSTRAR DRAWERS
    showDrawerDetalhes: false,
    showFiltroAvancado: false,
    showDrawerRecibo: false,
    showDrawerCarregarExtrato: false,
    showDrawerBuscarLancamento: false,
    showDrawerTransferenciaEntreContas: false,

    // IMPRESSÃO
    impressaoRecibo: false,
    impressaoTable: false,

    // VALORES PARA DRAWERS
    dadosCompartilhar: {
        link: '',
        assunto: '',
        mensagem: '',
        idpk: 0,
    },

    dadosRecibo: undefined,
};

export const CaixaBancoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<CaixaBancoState>(),
    effects,
});
