/** Arredonda um número conforme ABNT NBR 5891 */
export function roundAbnt(aValue: number, digits = 2, delta = 0.00001): number {
    function simpleRoundToEx(aValue: number, aDigit = -2): number {
        const lFactor = 10 ** aDigit;
        if (aValue < 0) {
            return Math.floor(aValue / lFactor - 0.5) * lFactor;
        }

        return Math.floor(aValue / lFactor + 0.5) * lFactor;
    }

    const negativo = aValue < 0;
    const pow = 10 ** Math.abs(digits);
    const powValue = Math.abs(aValue) / 10;
    const intValue = Math.trunc(powValue);
    const fracValue = powValue % 1;

    const powValueRounded = simpleRoundToEx(fracValue * 10 * pow, -9); // Simple round to eliminate recurring decimals
    let intCalc = Math.trunc(powValueRounded);
    const fracCalc = Math.trunc((powValueRounded % 1) * 100);

    if (fracCalc > 50) {
        intCalc += 1;
    } else if (fracCalc === 50) {
        const lastNumber = Math.round(((intCalc % 10) * 10) / 10);

        if (lastNumber % 2 !== 0) {
            intCalc += 1;
        } else {
            const restPart = (powValueRounded * 10) % 1;
            if (restPart > delta) {
                intCalc += 1;
            }
        }
    }

    let result = Number((intValue * 10 + intCalc / pow).toFixed(digits));
    if (negativo) {
        result = -result;
    }

    return result;
}

export function truncateDecimal(value: number, decimalPlaces: number): number {
    const power = 10 ** decimalPlaces;
    return Math.round(value * power) / power;
}
