import type { AxiosResponse } from 'axios';
import type { SimNao } from 'std/types';
import { z } from 'zod';

export const defaultSearchParamsSchema = z.object({
    // fields tem que ser string, não pode ser usado um array aqui
    // ex: usuario.usu_idpk, usuario.usu_nome
    fields: z.string().optional(),
    where: z.string().optional(),
    orderby: z.string().optional(),
    registro_inicial: z.number().catch(0),
    qtde_registros: z.number().catch(10),
    total_registros: z.enum(['S', 'N']).catch('S'),
});

export type DefaultSearchParams = z.infer<typeof defaultSearchParamsSchema>;

/** @deprecated Será substituído por DefaultSearchParams */
export type ApiBaseParams = Partial<{
    total_registros: SimNao;
    where: string;
    orderby: string;
    fields: string;
    registro_inicial: number;
    qtde_registros: number;
}>;

export type ApiResponseStatus = 'sucesso' | 'erro';

/** @deprecated */
type ApiResponseData = {
    status: ApiResponseStatus;

    // Em caso de sucesso
    registros?: any[];
    total_registros?: string;

    // Em caso de erro
    codigo?: string;
    tag?: string;
    mensagem?: string;
    mensagem_original?: string;
    personalizado?: Record<string, any>;

    // Campos personalizados
    [key: string]: any;
};

/** @deprecated Response do axios ao realizar uma requisição para o back */
export type ApiResponse = AxiosResponse<ApiResponseData>;
