import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useConfiguracaoStore } from 'features/configuracao/store';
import type { CSSProperties } from 'react';

type SwitchGridListProps = {
    style: CSSProperties;
};

export function SwitchGridList(props: SwitchGridListProps) {
    const { style } = props;

    const empresaSelectorLayout = useConfiguracaoStore((s) => s.empresaSelectorLayout);

    const setSelectorLayout = (): void => {
        const toggle = empresaSelectorLayout === 'grade' ? 'lista' : 'grade';
        useConfiguracaoStore.setState({ empresaSelectorLayout: toggle });
    };

    return (
        <div className='absolute' style={style}>
            <Tooltip
                title={`Mudar para exibição em ${
                    empresaSelectorLayout === 'grade' ? 'lista' : 'grade'
                }`}
            >
                <Button
                    icon={
                        empresaSelectorLayout === 'lista' ? (
                            <AppstoreOutlined />
                        ) : (
                            <UnorderedListOutlined />
                        )
                    }
                    type='text'
                    onClick={setSelectorLayout}
                />
            </Tooltip>
        </div>
    );
}
