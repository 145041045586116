import axios from 'axios';
import { useLoginStore } from 'features/login/store';
import { router } from 'router/Router';
import { baseUrl } from './baseUrl';

/** Instância do axios sem o bearer token */
export const axiosWithoutToken = axios.create({
    baseURL: baseUrl,
    headers: { 'Content-Type': 'application/json' },
});

/** Instância do axios com o bearer token */
export const axiosWithToken = axios.create({
    baseURL: baseUrl,
    headers: { 'Content-Type': 'application/json' },
});

// o código abaixo serve para lidar quando o usuário está sem token ou com token expirado

axiosWithToken.interceptors.request.use((request) => {
    if (!request.headers) {
        return request;
    }

    const token = useLoginStore.getState().login?.token;
    request.headers.Authorization = `Bearer ${token}`;
    return request;
});

axiosWithToken.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status !== 401) {
            return error;
        }

        if (error.response?.status === 401) {
            if (error.config.url === 'Login/RenovarToken') {
                return;
            }

            try {
                const response = await axiosWithToken.post('Login/RenovarToken');

                if (response.status === 401) {
                    throw new Error();
                }

                axiosWithToken.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                error.hasRefreshedToken = true;
            } catch {
                useLoginStore.getState().clearLogin();
                router.navigate({ to: '/auth/login' });
                return error;
            }
        }
    },
);
