import { notification } from 'antd';

export function notifyError({
    message,
    description,
}: { message: string; description: string }): void {
    console.error(`[notifyError] message: "${message}" description: "${description}"`);

    notification.error({
        message,
        description,
    });
}
