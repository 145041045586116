import dayjs from 'dayjs';
import { useCatalogoStore } from 'features/catalogo/store';
import type { RootDispatch, RootState } from 'state/store';
import { buildUrl } from 'std/api/buildUrl';
import { semTokenGet, semTokenPost } from 'std/api/semToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { DATE_FORMAT, TIME_FORMAT } from 'std/const/date';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { isLocalHost } from 'std/url';
import type {
    ShoppingCartProduto,
    TCatalogoData,
    TCatalogoHeaderData,
    TCatalogoPostPedidoBody,
    TCatalogoProduto,
    TPostCatalogoPedidoVendaResponse,
} from './types';

const baseUrl = isLocalHost() ? 'sandbox.app.mercurioapp.com.br' : window.location.hostname;

export const effects = (dispatch: RootDispatch) => ({
    async getCatalogoHeaderData({ slug }: { slug: string }, state: RootState): Promise<void> {
        dispatch.catalogoProdutos.setState({
            getCatalogoHeaderData: startReduxFn(state.catalogoProdutos.getCatalogoHeaderData.data),
        });

        const params = {
            url: baseUrl,
            slug,
            tipo_uso: 1,
        };

        const url = buildUrl('EmpresaSlug/Consultar' as any, params);

        try {
            const response: ApiResponse = await semTokenGet(url);
            throwIfResponseIsErr(response);

            const newCatalogoHeaderData = response.data as unknown as TCatalogoHeaderData;

            const catalogoHeaderData: TCatalogoHeaderData = {
                empresa: newCatalogoHeaderData.empresa,
                sistema: newCatalogoHeaderData.sistema,
                produto_categoria: newCatalogoHeaderData.produto_categoria,
            };

            dispatch.catalogoProdutos.setState({
                getCatalogoHeaderData: endReduxFnOk(catalogoHeaderData),
                primaryColor:
                    newCatalogoHeaderData.empresa.empresa_slug?.ems_cor_padrao ||
                    newCatalogoHeaderData.sistema.layout?.sic_cor_primaria,
                showPrice:
                    newCatalogoHeaderData.empresa.empresa_slug?.ems_catalogo_exibir_preco !== 1,
            });
        } catch (error) {
            dispatch.catalogoProdutos.setState({
                getCatalogoData: endReduxFnError(error),
            });
        }
    },

    async getCatalogoData(
        payload: {
            resetPagination: boolean;
            categoria_idpk?: number;
            qtde_registros: number;
            slug: string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.catalogoProdutos.setState({
            getCatalogoData: startReduxFn(state.catalogoProdutos.getCatalogoData.data),
        });

        const { resetPagination, categoria_idpk, qtde_registros, slug } = payload;

        const { orderby, filtroProduto, pagination } = state.catalogoProdutos;

        const params = {
            url: baseUrl,
            slug,
            qtde_registros: qtde_registros,
            registro_inicial: resetPagination ? 0 : pagination * qtde_registros,
            orderby,
            pesquisa_composta: filtroProduto,
            categoria_idpk: categoria_idpk === 0 ? undefined : categoria_idpk,
            status: 1,
        };

        const url = buildUrl('EmpresaSlug/Produto' as any, params);

        try {
            const response: ApiResponse = await semTokenGet(url);
            throwIfResponseIsErr(response);

            const newCatalogoData = response.data as unknown as TCatalogoData;

            let oldProdutos: TCatalogoProduto[] = [];

            if (
                !payload.resetPagination &&
                state.catalogoProdutos.getCatalogoData.data?.registros
            ) {
                oldProdutos = state.catalogoProdutos.getCatalogoData.data.registros;
            }

            const catalogoData: TCatalogoData = {
                registros: [...oldProdutos, ...newCatalogoData.registros],
            };

            dispatch.catalogoProdutos.setState({
                getCatalogoData: endReduxFnOk(catalogoData),
                pagination: payload.resetPagination ? 1 : pagination + 1,
            });
        } catch (error) {
            dispatch.catalogoProdutos.setState({
                getCatalogoData: endReduxFnError(error),
            });
        }
    },

    async addProdutoToCart(payload: {
        produto: TCatalogoProduto;
        quantity: number;
    }): Promise<void> {
        const { produto, quantity } = payload;
        const produtosCarrinho = useCatalogoStore.getState().produtosCarrinho;

        let newCartProdutos: ShoppingCartProduto[] = [];

        const shoppingCartProduto: ShoppingCartProduto = {
            ...produto,
            quantity,
        };

        const productIsAlreadyInCart = produtosCarrinho.some(
            (prod) => prod.pro_idpk === produto.pro_idpk,
        );

        // se já houver o produto na lista, alterar somente quantidade
        if (productIsAlreadyInCart) {
            newCartProdutos = produtosCarrinho.map((prod) =>
                prod.pro_idpk === produto.pro_idpk
                    ? { ...prod, quantity: shoppingCartProduto.quantity }
                    : prod,
            );
            // ou então adicione novo produto na lista já existente
        } else {
            newCartProdutos = [...produtosCarrinho, shoppingCartProduto];
        }

        useCatalogoStore.setState({ produtosCarrinho: newCartProdutos });
    },

    async removeProdutoFromCart(payload: { produto: TCatalogoProduto }): Promise<void> {
        const newCartProdutos = useCatalogoStore
            .getState()
            .produtosCarrinho.filter((produto) => produto.pro_idpk !== payload.produto.pro_idpk);

        useCatalogoStore.setState({ produtosCarrinho: newCartProdutos });
    },

    async postPedidoVenda(
        payload: { body: TCatalogoPostPedidoBody },
        state: RootState,
    ): Promise<TPostCatalogoPedidoVendaResponse> {
        dispatch.catalogoProdutos.setState({
            postPedidoVenda: startReduxFn(state.catalogoProdutos.postPedidoVenda.data),
        });

        const params = {
            empresa_idpk: state.catalogoProdutos.getCatalogoHeaderData.data?.empresa.emp_idpk,
        };

        const itens = useCatalogoStore.getState().produtosCarrinho.map((prod) => ({
            vei_produto_nome: prod.descricao,
            vei_produto_unidade: `${prod.unidade}`,
            vei_quantidade: prod.quantity || 1,
            vei_valor_unitario: prod.preco_venda || 1,
            vei_valor_total: prod.preco_venda * prod.quantity || 1,
            vei_produto_idpk: prod.pro_idpk,
        }));

        const somaValores = itens.reduce((total, item) => item.vei_valor_total + total, 0);

        const ven_data = dayjs().format(DATE_FORMAT);
        const ven_hora = dayjs().format(TIME_FORMAT);

        const completeBody: TCatalogoPostPedidoBody = {
            ...payload.body,
            ven_produtos_valor: somaValores,
            ven_valor_total: somaValores,
            ven_data,
            ven_hora,
            itens,
        };

        const url = buildUrl('EmpresaSlug/Venda' as any, params);

        try {
            const response: ApiResponse = await semTokenPost(url, completeBody);
            throwIfResponseIsErr(response);

            const parsedResponse = {
                mensagem: response.data.mensagem || '',
                status: response.data.status,
                ven_idpk: response.data.registros?.[0].ven_idpk,
                ven_numero: response.data.registros?.[0].ven_numero,
                ven_vendedor_sequencial: response.data.registros?.[0].ven_vendedor_sequencial,
                ven_data,
                ven_hora,
            };

            dispatch.catalogoProdutos.setState({
                postPedidoVenda: endReduxFnOk(parsedResponse),
            });

            useCatalogoStore.setState({ produtosCarrinho: [] });

            return parsedResponse;
        } catch (error) {
            dispatch.catalogoProdutos.setState({
                postPedidoVenda: endReduxFnError(error),
            });
            return { status: 'erro' };
        }
    },
});
