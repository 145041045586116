import { createFileRoute } from '@tanstack/react-router';
import { canShowExtratoTemporario } from 'features/login/util';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/extrato-pix')({
    beforeLoad: () =>
        validateRoute({
            podeExibirFintech: true,
            permissao: 'upp_fintech_administrativo',
            nivel: TipoPermissao.Completo,
            condicao: canShowExtratoTemporario([1, 3]),
        }),
});
