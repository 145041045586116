import { redirect } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { type FormEditMode, formEditMode } from 'std/types/enum';

export const Route = createFileRoute('/a/contrato/$mode/$idpk/')({
    beforeLoad({ params }) {
        const { mode } = params;

        if (!formEditMode.includes(mode as FormEditMode)) {
            throw redirect({ to: '/a/contrato' });
        }
    },
});
