import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

export type LoginAutomaticoEncoded = {
    email: string;
    senha: string;
    empresa_idpk?: number;
    redirect: string;
    redirect_idpk?: number;
    redirect_options?: AutoLoginRedirectOptions;
};

export type AutoLoginRedirectOptions = Partial<{
    tipo_pix: 'instantaneo' | 'cobranca';
    data_inicio: string;
    data_fim: string;
}>;

type AutoLoginRedirectStore = Partial<LoginAutomaticoEncoded> & {
    clear: () => void;
};

export const useAutoLoginRedirectStore = create(
    persist(
        devtools<AutoLoginRedirectStore>(
            (set) => ({
                clear: () => {
                    set({});
                },
            }),
            { name: 'auto-login-redirect' },
        ),
        {
            name: 'auto-login-redirect',
        },
    ),
);
