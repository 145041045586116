import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { Button, Col, Row, Typography } from 'antd';
import { useConfiguracaoStore } from 'features/configuracao/store';

const { Text } = Typography;

export function BannerInstrucoesDeUso() {
    const navigate = useNavigate();

    const openInstrucoesDeUso = (): void => {
        navigate({ to: '/a/suporte/instrucoes-de-uso' });
    };

    const hideBanner = (): void => {
        useConfiguracaoStore.setState({ showBannerInstrucoesUso: false });
    };

    return (
        <Row
            className='flex justify-between px-4 py-3 mb-3 mr-2 card relative w-full'
            style={{ backgroundColor: '#7092be' }}
        >
            <Col lg={14} xl={14} xxl={14} className='flex mb-2 flex-col'>
                <Text strong={true} className='text-white' style={{ fontSize: '1.2rem' }}>
                    Esperamos poder ajudar muito seu negócio!
                </Text>
                <Text className='text-white'>
                    Clique no botão para ver algumas instruções básicas para começar!
                </Text>
            </Col>

            <Col lg={9} xl={9} xxl={9} className='flex flex-row-reverse items-center'>
                <Button className='bg-gray-100' onClick={openInstrucoesDeUso}>
                    <Text
                        strong={true}
                        className='text-primary'
                        style={{
                            filter: 'brightness(80%)',
                        }}
                    >
                        Ver instruções de uso
                    </Text>
                </Button>
            </Col>

            <Button
                type='text'
                className='p-0 text-white absolute'
                style={{
                    top: 0,
                    right: 5,
                }}
                onClick={hideBanner}
            >
                <CloseOutlined />
            </Button>
        </Row>
    );
}
