import { createModel } from '@rematch/core';
import type { RootModel } from 'models';
import { initReduxFn, setStateReducer } from 'std/redux';
import { effects } from './effects';
import type { CatalogoProdutosState } from './types';

const initialState: CatalogoProdutosState = {
    getCatalogoData: initReduxFn,
    postPedidoVenda: initReduxFn,
    getCatalogoHeaderData: initReduxFn,

    pagination: 0,
    drawerContactInfo: false,
    showPrice: true,
    primaryColor: '',
    numeroColunas: 0,
    categoria_idpk: 0,
    orderby: 'pro_descricao asc',
};

export const CatalogoProdutosModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<CatalogoProdutosState>(),
    effects,
});
