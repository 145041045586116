import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { Button, Image } from 'antd';
import { useStateControlCatalogo } from 'pages/catalogo-produtos/useStateControlCatalogo';
import { useCallback } from 'react';
import { Show } from 'std/util/show';
import { ShoppingCartButton } from './ShoppingCartButton';
import style from './style.module.scss';

export function FixedBar({ show }: { show: boolean }) {
    const { catalogoProdutos, catalogoActions } = useStateControlCatalogo();

    const {
        drawerContactInfo,
        getCatalogoHeaderData: { data },
        primaryColor,
    } = catalogoProdutos;

    const navigate = useNavigate();

    const empresa = data?.empresa;
    const slugRoute = empresa?.empresa_slug?.ems_slug || '';

    const backToList = () => {
        navigate({
            to: '/p/catalogo/$slug',
            params: { slug: slugRoute },
        });
    };

    const openDrawerContactInfo = useCallback(() => {
        catalogoActions.setState({
            drawerContactInfo: !drawerContactInfo,
        });
    }, [catalogoActions, drawerContactInfo]);

    if (!empresa?.empresa_slug) {
        return null;
    }

    return (
        <Show when={show}>
            <div className={style.fixedBar} style={{ backgroundColor: primaryColor }}>
                <div className={style['drawer-info-btn-container']}>
                    <Button
                        type='text'
                        onClick={openDrawerContactInfo}
                        className={style.menuBtnFixed}
                    >
                        <MenuOutlined />
                    </Button>
                </div>

                <div className={style.fixedBarBrandContainer}>
                    <Image
                        src={empresa.emp_logotipo_foto}
                        preview={false}
                        className='rounded-full'
                        style={{
                            maxWidth: 64,
                            aspectRatio: 1,
                            cursor: 'pointer',
                        }}
                        onClick={backToList}
                    />
                </div>

                <ShoppingCartButton fixed={true} />
            </div>
        </Show>
    );
}
