import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/a/cupom-fiscal/')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        idpk?: string;
    } => {
        return {
            idpk: search?.idpk,
        };
    },
});
