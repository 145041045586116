import { Button, Row, Typography } from 'antd';
import { useLoginStore } from 'features/login/store';
import { formatTelefone } from 'std/format';

const { Title, Paragraph, Text } = Typography;

export function EntreEmContato({ showTitle }: { showTitle: boolean }) {
    const representante = useLoginStore((s) => s.getEmpresa()?.representante);

    const chamarNoWhats = (): void => {
        window.open(`https://wa.me/55${representante?.rep_suporte_whatsapp}`);
    };

    return (
        <div>
            {showTitle && <Title level={3}>Entre em contato conosco</Title>}
            <div>
                <Paragraph className='mb-1'>
                    Estamos à disposição para lhe auxiliar no que for necessário pelos nossos canais
                    de atendimento.
                </Paragraph>
                <table>
                    <tbody>
                        {!!representante?.rep_suporte_email && (
                            <tr>
                                <td className='mr-1'>Email:</td>
                                <td>
                                    <Text className='mb-1'>{representante?.rep_suporte_email}</Text>
                                </td>
                            </tr>
                        )}

                        {!!representante?.rep_suporte_telefone && (
                            <tr>
                                <td className='mr-1'>Telefone:</td>
                                <td>
                                    <Text>
                                        {formatTelefone(representante?.rep_suporte_telefone || '')}
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {!!representante?.rep_suporte_whatsapp && (
                <Row justify='center' style={{ paddingTop: 10 }}>
                    <Button type='primary' onClick={chamarNoWhats}>
                        Chamar no Whatsapp
                    </Button>
                </Row>
            )}
        </div>
    );
}
