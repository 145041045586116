import { createFileRoute, redirect } from '@tanstack/react-router';
import {
    type TipoImportacao,
    tipoImportacao,
} from 'features/importacao-exportacao/page/templateData';

export const Route = createFileRoute('/a/importacao/cadastro/$tipo')({
    beforeLoad: ({ params }) => {
        if (!tipoImportacao.includes(params.tipo as TipoImportacao)) {
            throw redirect({ to: '/' });
        }
    },
});
