import { axiosWithToken } from 'std/api/axios';

type ApiLoginRenovarToken = {
    token: string;
    token_exp: string;
};

// Não da pra usar newRequest aqui pq ele vai se bloquear
export async function apiLoginRenovarToken(): Promise<ApiLoginRenovarToken> {
    const response = await axiosWithToken.post('Login/RenovarToken');

    if (response.data?.status === 'sucesso') {
        return {
            token: response.data.token,
            token_exp: response.data.token_exp,
        };
    }

    throw new Error(response.data?.status);
}
