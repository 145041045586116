import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/a/compra/')({
    validateSearch: (
        search: Record<string, unknown>,
    ): {
        tab?: string;
    } => {
        return {
            tab: String(search?.tab || ''),
        };
    },
});
