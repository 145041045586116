import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/p/catalogo/$slug/')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        page?: string;
    } => {
        return {
            page: search?.page,
        };
    },
});
