import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { MovimentoComissaoState } from './types';

const initialState: MovimentoComissaoState = {
    get: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
};

export const MovimentoComissaoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<MovimentoComissaoState>(),
    effects,
});
