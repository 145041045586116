import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/cobranca-pix')({
    beforeLoad: () => {
        return validateRoute({
            podeExibirFintech: true,
            permissao: 'upp_financeiro_pix',
            nivel: TipoPermissao.Completo,
        });
    },
});
