import { z } from 'zod';

export const vendaSistemaIntegradoSchema = z.object({
    ven_dispositivo_idpk: z.number().nullish(),
    ven_sistema_integrado_referencia: z.string().nullish(),
    sii_idpk: z.number().nullish(),
    sii_nome: z.string().nullish(),
});

export type VendaSistemaIntegrado = z.infer<typeof vendaSistemaIntegradoSchema>;
