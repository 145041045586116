import dayjs from 'dayjs';
import type { ApiUsuarioPerfilTipo } from 'features/usuario/types/ApiUsuarioPerfilTipo';
import { type Permissao, TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { DATE_TIME_FORMAT } from 'std/const/date';
import { useLoginStore } from './store';

export function isUserAdmin(): boolean {
    const perfisAdm: ApiUsuarioPerfilTipo[] = ['Administrador', 'Gerente'];
    const perfil = useLoginStore.getState().getUsuarioEmpresa()?.usuario_perfil_tipo;
    return !!perfil && perfisAdm.includes(perfil);
}

export function canUserListAllVendedores(): boolean {
    return useLoginStore.getState().getUsuarioEmpresa()?.use_usuario_tipo_idpk !== 6;
}

export function getUserPermission(permissao: Permissao): TipoPermissao {
    const usuario_tipo_permissao = useLoginStore
        .getState()
        .getUsuarioEmpresa()?.usuario_tipo_permissao;

    return usuario_tipo_permissao?.[permissao] || TipoPermissao.Bloqueado;
}

export function userHasPermission(
    permissao: Permissao,
    nivel: TipoPermissao.Completo | TipoPermissao.Parcial,
): boolean {
    const userPermission = getUserPermission(permissao);

    if (nivel === TipoPermissao.Completo) {
        return userPermission === TipoPermissao.Completo;
    }

    return userPermission === TipoPermissao.Completo || userPermission === TipoPermissao.Parcial;
}

export function getTokenStatus(): 'valid' | 'about-to-expire' | 'expired' {
    const token_exp = useLoginStore.getState().login?.token_exp;

    if (!token_exp) {
        return 'expired';
    }

    const now = dayjs();
    const tokenExp = dayjs(token_exp, DATE_TIME_FORMAT);
    const diff = tokenExp.diff(now, 'minutes');

    if (diff <= 0) {
        return 'expired';
    }

    // Faltando 5 horas renovar o token
    if (diff <= 5 * 60) {
        return 'about-to-expire';
    }

    return 'valid';
}

export function canShowExtratoTemporario(keys: number[]): boolean {
    const empresa = useLoginStore.getState().getUsuarioEmpresa()?.empresa[0];

    if (!empresa) {
        return false;
    }

    return keys.includes(empresa.emp_fintech_extrato_temporario);
}
