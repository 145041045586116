import { FacebookFilled, InstagramFilled, YoutubeFilled } from '@ant-design/icons';
import type { ApiSistema } from 'features/sistema/types';
import { formatDocument, formatTelefone } from 'std/format';

export function ContaDigitaltermoDeUsoHeader({ sistema }: { sistema: Partial<ApiSistema> }) {
    return (
        <div className='flex items-center p-6 mb-3 bg-white rounded-md shadow'>
            {sistema?.sis_icone && (
                <img
                    src={sistema.sis_icone}
                    alt={sistema.sis_conta_digital}
                    className='w-20 h-20'
                />
            )}

            <div className='ml-2'>
                <p className='text-xl font-bold'>Conta digital: {sistema?.sis_conta_digital}</p>
                <p>{formatDocument(sistema?.sis_cnpjcpf)}</p>
                <p>{formatTelefone(sistema?.sis_telefone)}</p>
                <a href={sistema?.sis_landing_page}>{sistema?.sis_landing_page}</a>
            </div>

            <div className='flex gap-1 ml-auto'>
                {sistema?.sis_facebook && (
                    <a href={sistema.sis_facebook} target='_blank' rel='noreferrer'>
                        <FacebookFilled className='text-2xl text-facebook-blue' />
                    </a>
                )}

                {sistema?.sis_instagram && (
                    <a href={sistema.sis_instagram} target='_blank' rel='noreferrer'>
                        <InstagramFilled className='text-2xl text-instagram-pink' />
                    </a>
                )}

                {sistema?.sis_youtube && (
                    <a href={sistema.sis_youtube} target='_blank' rel='noreferrer'>
                        <YoutubeFilled className='text-2xl text-youtube-red' />
                    </a>
                )}
            </div>
        </div>
    );
}
