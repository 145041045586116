import {
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    MailOutlined,
    MenuOutlined,
    TwitterOutlined,
    WhatsAppOutlined,
    YoutubeOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Row, Typography } from 'antd';
import { useStateControlCatalogo } from 'pages/catalogo-produtos/useStateControlCatalogo';
import { formatTelefone } from 'std/format';
import { Show } from 'std/util/show';
import style from './style.module.scss';

const { Title } = Typography;

export function DrawerContactInfo() {
    const { catalogoProdutos, catalogoActions } = useStateControlCatalogo();

    const {
        drawerContactInfo,
        getCatalogoHeaderData: { data },
        primaryColor,
    } = catalogoProdutos;

    const empresa = data?.empresa;
    const empresa_slug = empresa?.empresa_slug;

    const closeDrawer = () => {
        catalogoActions.setState({ drawerContactInfo: false });
    };

    if (!(empresa && empresa_slug)) {
        return null;
    }

    return (
        <Drawer
            placement='left'
            onClose={closeDrawer}
            open={drawerContactInfo}
            mask={false}
            zIndex={199}
            style={{
                backgroundColor: primaryColor,
            }}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                />
            }
        >
            <div className='h-50'>
                <Button type='text' onClick={closeDrawer} className={style.menuBtnFixed}>
                    <MenuOutlined />
                </Button>

                <div className={style.drawerBody}>
                    <div>
                        <Title level={2} className='mb-0'>
                            Informação
                        </Title>
                        <p>Confirme seu pedido e receberemos em nosso WhatsApp 📲</p>
                    </div>

                    <div>
                        <Title level={2} className='mb-0'>
                            Contato
                        </Title>
                        <div className='flex flex-col'>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`https://web.whatsapp.com/send?phone=55${empresa.emp_telefone_principal}`}
                            >
                                <WhatsAppOutlined />{' '}
                                <span>{formatTelefone(empresa.emp_telefone_principal || '')}</span>
                            </a>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`mailto:${empresa.emp_email}`}
                            >
                                <MailOutlined /> <span>{empresa.emp_email}</span>
                            </a>
                        </div>
                    </div>

                    <div>
                        <Title level={2} className='mb-0'>
                            Redes Sociais
                        </Title>
                        <Row className='flex' style={{ gap: '10px' }}>
                            <Show when={!!empresa_slug.ems_instagram}>
                                <a
                                    href={empresa_slug.ems_instagram}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <InstagramOutlined style={{ fontSize: '20px' }} />
                                </a>
                            </Show>
                            <Show when={!!empresa_slug.ems_linkedin}>
                                <a
                                    href={empresa_slug.ems_linkedin}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <LinkedinOutlined style={{ fontSize: '20px' }} />
                                </a>
                            </Show>
                            <Show when={!!empresa_slug.ems_twitter}>
                                <a href={empresa_slug.ems_twitter} target='_blank' rel='noreferrer'>
                                    <TwitterOutlined style={{ fontSize: '20px' }} />
                                </a>
                            </Show>
                            <Show when={!!empresa_slug.ems_facebook}>
                                <a
                                    href={empresa_slug.ems_facebook}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <FacebookOutlined style={{ fontSize: '20px' }} />
                                </a>
                            </Show>
                            <Show when={!!empresa_slug.ems_youtube}>
                                <a href={empresa_slug.ems_youtube} target='_blank' rel='noreferrer'>
                                    <YoutubeOutlined style={{ fontSize: '20px' }} />
                                </a>
                            </Show>
                        </Row>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}
