import type { ShoppingCartProduto, TCatalogoClienteData } from 'models/catalogo-produtos/types';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

type CatalogoStore = {
    dadosFinalizarPedido: TCatalogoClienteData | undefined;
    produtosCarrinho: ShoppingCartProduto[];
};

export const useCatalogoStore = create<CatalogoStore>()(
    devtools(
        persist(
            (_) => ({
                dadosFinalizarPedido: undefined,
                produtosCarrinho: [],
            }),
            {
                name: 'catalogo',
                partialize: (state) => ({
                    dadosFinalizarPedido: state.dadosFinalizarPedido,
                }),
            },
        ),
        { name: 'catalogo' },
    ),
);
