// https://github.com/lodash/lodash/blob/4.17.15/lodash.js

import { deburredLetters } from 'std/const/deburredLetters';

/** Used to match Latin Unicode letters (excluding mathematical operators). */
const reLatin = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g;

const rsComboMarksRange = '\\u0300-\\u036f';
const reComboHalfMarksRange = '\\ufe20-\\ufe2f';
const rsComboSymbolsRange = '\\u20d0-\\u20ff';
const rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange;
const rsCombo = `[${rsComboRange}]`;

/**
 * Used to match [combining diacritical marks](https://en.wikipedia.org/wiki/Combining_Diacritical_Marks) and
 * [combining diacritical marks for symbols](https://en.wikipedia.org/wiki/Combining_Diacritical_Marks_for_Symbols).
 */
const reComboMark = RegExp(rsCombo, 'g');

/**
 * The base implementation of `_.propertyOf` without support for deep paths.
 * @param object The object to query.
 * @returns Returns the new accessor function.
 */
function basePropertyOf<T extends object>(obj: T) {
    return <K extends keyof T>(key: K) => (obj == null ? undefined : obj[key]);
}

const deburrLetter = basePropertyOf(deburredLetters);

// https://lodash.com/docs/4.17.15#deburr
/** Remove a acentuação de uma string */
export function removerAcento(value: string | null | undefined): string {
    if (!value) {
        return '';
    }

    //@ts-ignore - IDK
    return value.replace(reLatin, deburrLetter).replace(reComboMark, '');
}
