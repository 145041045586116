import { createModel } from '@rematch/core';
import type { RootModel } from 'models';
import { initReduxFn, setStateReducer } from 'std/redux';
import { effects } from './effects';
import type { ContasReceberState } from './types';

const initialState: ContasReceberState = {
    get: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    getOne: initReduxFn,
    estornar: initReduxFn,
    getTotais: initReduxFn,
    postLancamento: initReduxFn,
    estornarLancamento: initReduxFn,
    postAnexo: initReduxFn,
    removeAnexo: initReduxFn,
    gerarBoleto: initReduxFn,
    gerarPixCobranca: initReduxFn,
    excluirBoleto: initReduxFn,
    excluirPixCobranca: initReduxFn,

    selectedRows: [],
    selectedRowKeys: [],

    showModal: false,
    showReceber: false,
    showPixCobranca: false,
    showCompartilhar: false,
    showDrawerCompartilhar: false,
    showDrawerGetDetalhes: false,
    showSelectAll: false,

    obrigarPreenchimentoCompetenciaDRe: false,

    getTable: {
        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    tableHistorico: [],
    selectAll: false,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 22,
        showTotal: () => '',
        showSizeChanger: false,
    },
    sortParams: {
        shouldSort: false,
        fieldName: '',
        orderDirection: 'asc',
    },
    recebimentoTotais: {
        selecionadas: 0,
        valorOriginal: 0,
        valorQuitado: 0,
        jurosMulta: 0,
        valorAtualizado: 0,
    },
};

export const ContasReceberModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ContasReceberState>(),
    effects,
});
