import { redirect } from '@tanstack/react-router';

export function parseParamsSearch(hash: string): {
    params: { id: string };
    search: Record<string, string> | undefined;
} {
    const parts = hash.split('/');
    const paramsSearch = parts[2];

    if (!(paramsSearch && location)) {
        throw redirect({ to: '/' });
    }

    const [id, searchFull] = paramsSearch.split('?');

    if (!id) {
        throw redirect({ to: '/' });
    }

    if (!searchFull) {
        return {
            params: { id },
            search: undefined,
        };
    }

    const searchParts = searchFull.split('&') || [];
    const search = {};

    for (const part of searchParts) {
        const [key, value] = part.split('=');

        if (key && value) {
            search[key] = value;
        }
    }

    return {
        params: { id },
        search,
    };
}

export function oldPublicRoutesBackwardsCompatibility(locationHash: string): never {
    const parts = locationHash.split('/');

    // /#/pagamento/:id?$tipo
    if (parts[1] === 'pagamento' && parts[2]) {
        const { params, search } = parseParamsSearch(locationHash);

        throw redirect({
            to: '/p/pagamento/$id',
            params,
            search,
        });
    }

    // /#/compartilhamento/:id?$tipo$registrarVisualizacao
    if (parts[1] === 'compartilhamento' && parts[2]) {
        const { params, search } = parseParamsSearch(locationHash);

        throw redirect({
            to: '/p/compartilhamento/$id',
            params,
            search,
        });
    }

    // /#/impressao/public/:filetype
    if (parts[1] === 'impressao' && parts[2] === 'public' && parts[3]) {
        throw redirect({
            to: '/p/impressao/$filetype',
            params: {
                filetype: parts[3],
            },
        });
    }

    const slug = parts[1];

    if (!slug) {
        throw redirect({
            to: '/',
        });
    }

    // /#/meuNegocio/cliente
    if (parts[2]?.includes('cliente')) {
        const searchParts = parts[2]?.split('?') || [];
        const documento = searchParts[1]?.split('=')[1];

        throw redirect({
            to: '/p/cliente/$slug',
            params: {
                slug,
            },
            search: {
                documento,
            },
        });
    }

    // /#/meuNegocio/catalogo
    if (parts[2]?.includes('catalogo')) {
        const searchParts = parts[2]?.split('?') || [];
        const page = searchParts[1]?.split('=')[1];

        throw redirect({
            to: '/p/catalogo/$slug',
            params: {
                slug,
            },
            search: {
                page,
            },
        });
    }

    throw redirect({ to: '/' });
}
