import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/cupom-fiscal')({
    beforeLoad: () =>
        validateRoute({
            permissao: 'upp_fiscal_nfce',
            nivel: TipoPermissao.Parcial,
            podeExibirFintech: false,
        }),
});
