import { Button, Row, Typography } from 'antd';
import { useMemo } from 'react';
import { Show } from 'std/util/show';
import type { UploadedFile } from './types';

const { Title } = Typography;

type StepThreeProps = {
    finalizar: () => void;
    uploadedFiles: UploadedFile[];
};

export function StepThree(props: StepThreeProps) {
    const { finalizar, uploadedFiles } = props;

    const processamentoSucedido = useMemo(
        (): boolean => !uploadedFiles.some((file) => file.status !== 'sucesso'),
        [uploadedFiles],
    );

    return (
        <Row
            className='relative flex flex-col items-center'
            style={{
                minHeight: 200,
            }}
        >
            <Title
                level={3}
                className={`w-full text-center ${processamentoSucedido ? undefined : 'text-red-500'}`}
            >
                {processamentoSucedido
                    ? 'Processamento concluído com sucesso!'
                    : 'Processamento concluído com erros'}
            </Title>

            <Show when={!processamentoSucedido}>
                <Title level={4} className='mt-1 text-red-500'>
                    Verifique os motivos abaixo:
                </Title>
            </Show>

            <Button
                style={{
                    width: 300,
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                }}
                type='primary'
                onClick={finalizar}
            >
                Finalizar
            </Button>
        </Row>
    );
}
