import { newRequest } from 'std/api/newRequest';

type ApiRelatorioClienteAniversarianteParams = Partial<{
    where: string;
    orderby: string;
    vendedor_idpk: number;
    cidade_idpk_list: number[];
    aniversariante_dia: string;
    aniversariante_mes: string;
}>;

export type ApiRelatorioClienteAniversariante = {
    codigo: number;
    nome: string;
    documento: string;
    pes_responsavel: string;
    pes_nome_fantasia: string;
    nascimento_abertura: string;
    idade: number;
    telefone: string;
    email: string;
    end_logradouro: string;
    end_numero: string;
    end_complemento: string;
    end_bairro: string;
    end_cep: string;
    cid_cidade: string;
    cid_uf: string;
};

export function apiRelatorioClienteAniversariante({
    params,
}: {
    params: ApiRelatorioClienteAniversarianteParams;
}): Promise<ApiRelatorioClienteAniversariante[]> {
    return newRequest({
        url: 'Relatorio/ClienteAniversariante',
        type: 'get',
        params,
    });
}
