import { MenuOutlined } from '@ant-design/icons';
import { Button, Image, Typography } from 'antd';
import { useStateControlCatalogo } from 'pages/catalogo-produtos/useStateControlCatalogo';
import React, { useCallback } from 'react';
import { getTextColor } from 'std/color';
import { ShoppingCartButton } from './ShoppingCartButton';
import style from './style.module.scss';

const { Title } = Typography;

//o ref é para controlar a presença deste componente no campo visivel do browser
export const StaticBar = React.forwardRef<HTMLDivElement>((_, ref) => {
    const { catalogoProdutos, catalogoActions } = useStateControlCatalogo();
    const {
        drawerContactInfo,
        getCatalogoHeaderData: { data },
        primaryColor,
    } = catalogoProdutos;

    const empresa = data?.empresa;

    const openDrawerContactInfo = useCallback(() => {
        catalogoActions.setState({
            drawerContactInfo: !drawerContactInfo,
        });
    }, [catalogoActions, drawerContactInfo]);

    if (!empresa?.empresa_slug) {
        return null;
    }

    return (
        <div className={style.staticBar} style={{ backgroundColor: primaryColor }} ref={ref}>
            <div className={style['drawer-info-btn-container']}>
                <Button type='text' onClick={openDrawerContactInfo} className={style.menuBtnStatic}>
                    <MenuOutlined style={{ color: getTextColor(primaryColor) }} />
                </Button>
            </div>

            <div className={style.staticBarBrandContainer}>
                <Image
                    src={empresa.emp_logotipo_foto}
                    preview={false}
                    className='rounded-full'
                    style={{
                        maxWidth: 128,
                        aspectRatio: 1,
                    }}
                />
                <Title level={2} className='m-3 text-center'>
                    {empresa.emp_nome_fantasia || empresa.emp_nome_razaosocial}
                </Title>
                <Title level={3} className='mx-3 text-center'>
                    {empresa.empresa_slug.ems_descricao_titulo}
                </Title>

                <Title level={5} className='m-3 text-center w-1/2'>
                    {empresa.empresa_slug.ems_descricao_texto}
                </Title>
            </div>

            <ShoppingCartButton />
        </div>
    );
});
