import { createModel } from '@rematch/core';
import type { RootModel } from 'models';
import { getOneMonthPeriod } from 'std/datetime';
import { initReduxFn, setStateReducer } from 'std/redux';
import { effects } from './effects';
import type { RomaneioState } from './types';

const initialState: RomaneioState = {
    get: initReduxFn,
    getTotais: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    getVendas: initReduxFn,
    getNotas: initReduxFn,

    filterDataEmissao: getOneMonthPeriod(),
    filterStatus: 'Previsão',
    filterSearch: '',
    filterNumber: '',

    agruparPor: 'nenhum',

    pagination: {
        current: 1,
        pageSize: 10,
        total: 22,
        showTotal: () => '',
        showSizeChanger: false,
    },
    sortParams: {
        shouldSort: false,
        fieldName: '',
        orderDirection: 'asc',
    },
};

export const RomaneioModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<RomaneioState>(),
    effects,
});
