import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { AtendimentoState } from './types';

const initialState: AtendimentoState = {
    // EFFECTS
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,

    // TABELAS
    getTable: {
        // FILTROS
        filterCliente: 0,
        filterDateRange: undefined,
        filterNome: '',

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 5,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 5,
            total: 5,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },
};

export const AtendimentoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<AtendimentoState>(),
    effects,
});
