/** @deprecated */
export const setStateReducer = <T>() => ({
    setState: (state: T, payload: Partial<T>) => ({ ...state, ...payload }),
});

/** @deprecated */
export type ReduxFn<T> = {
    data: T | null;
    error: unknown | null;
    loading: boolean;
};

/** @deprecated */
export const initReduxFn = {
    data: null,
    error: null,
    loading: false,
};

/** @deprecated */
export function startReduxFn<T>(oldData: T | null = null): ReduxFn<T> {
    return {
        data: oldData,
        error: null,
        loading: true,
    };
}

/** @deprecated */
export function endReduxFnOk<T>(data: T | null): ReduxFn<T> {
    return {
        data,
        error: null,
        loading: false,
    };
}

/** @deprecated */
export function endReduxFnError<T>(error: unknown): ReduxFn<T> {
    console.error(error);

    return {
        data: null,
        error,
        loading: false,
    };
}
