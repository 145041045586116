import { queryClient } from 'App/queryClient';
import { notification } from 'antd';
import type { ApiEmpresa } from 'features/empresa/types/ApiEmpresa';
import type { ApiUsuarioEmpresaSemUsuario } from 'features/usuario/types/ApiUsuarioEmpresa';
import { router } from 'router/Router';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import type { ApiLoginlogar } from './api/apiLoginLogar';
import { apiLoginRenovarToken } from './api/apiLoginRenovarToken';

type LoginStore = {
    login: ApiLoginlogar | undefined;
    empresaIdpk: number | undefined;
    acessoNegadoMessage: string | undefined;
    codigoVerificado: boolean;
    renovandoToken: boolean;

    getUsuarioEmpresa: () => ApiUsuarioEmpresaSemUsuario | undefined;
    getEmpresa: () => ApiEmpresa | undefined;
    updateEmpresa: (empresa: ApiEmpresa) => void;
    renovarToken: () => Promise<void>;
    clearLogin: () => void;
    isFintech: () => boolean;
    switchEmpresa: (emp_idpk: number) => void;
};

export const useLoginStore = create<LoginStore>()(
    devtools(
        persist(
            (set, get) => ({
                login: undefined,
                empresaIdpk: undefined,
                acessoNegadoMessage: undefined,
                codigoVerificado: false,
                renovandoToken: false,

                getUsuarioEmpresa: () => {
                    return get().login?.usuario_empresa.find(
                        (ue) => ue.use_empresa_idpk === get().empresaIdpk,
                    );
                },

                getEmpresa: () => {
                    return get().getUsuarioEmpresa()?.empresa[0];
                },

                updateEmpresa: (empresa) => {
                    set((s) => {
                        const usuario_empresa =
                            s.login?.usuario_empresa.map((ue) => {
                                if (ue.use_empresa_idpk === s.empresaIdpk) {
                                    return {
                                        ...ue,
                                        empresa: [empresa],
                                    };
                                }

                                return ue;
                            }) || [];

                        return {
                            login: s.login ? { ...s.login, usuario_empresa } : undefined,
                        };
                    });
                },

                renovarToken: async () => {
                    const { login, renovandoToken } = get();

                    if (renovandoToken) {
                        return;
                    }

                    set({ renovandoToken: true });

                    try {
                        const response = await apiLoginRenovarToken();

                        set({
                            login: login
                                ? { ...login, token: response.token, token_exp: response.token_exp }
                                : undefined,
                            renovandoToken: false,
                        });

                        window.location.reload();
                    } catch (e) {
                        notification.error({
                            message: 'Falha ao renovar token',
                            description: e.message,
                        });

                        set({
                            login: undefined,
                            empresaIdpk: undefined,
                            renovandoToken: false,
                        });

                        router.navigate({ to: '/auth/login' });
                    }
                },

                clearLogin: () => {
                    set({ login: undefined, empresaIdpk: undefined });
                },

                isFintech: () => {
                    return get().getEmpresa()?.emp_sistema_tipo_uso === 'F';
                },

                switchEmpresa: (emp_idpk: number) => {
                    useLoginStore.setState({
                        empresaIdpk: emp_idpk,
                    });

                    queryClient.clear();
                },
            }),
            {
                name: 'login',
                partialize: (state) => ({
                    login: state.login,
                    empresaIdpk: state.empresaIdpk,
                }),
            },
        ),
        { name: 'login' },
    ),
);
