import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Input, Table, type TableColumnType } from 'antd';
import { apiProdutoGet } from 'features/produto/api/apiProdutoGet';
import type { ApiProduto } from 'features/produto/types/ApiProduto';
import { useEffect } from 'react';
import type { DebugSchema } from 'router/routes/a/debug/pagination';
import { useDebouncedStateSearchParam } from 'std/hooks/useDebouncedStateSearchParam';
import { usePaginationSearchParams } from 'std/hooks/usePaginationSearchParams';

const columns: TableColumnType<ApiProduto>[] = [
    {
        dataIndex: 'pro_idpk',
        title: 'Código',
        align: 'center',
        width: '6rem',
        sortDirections: ['ascend', 'descend'],
        sorter: () => 0,
    },
    {
        dataIndex: 'pro_descricao',
        title: 'Produto',
        sortDirections: ['ascend', 'descend'],
        sorter: () => 0,
    },
];

export function ExampleUsePaginationParams() {
    const [descricao, setDescricao] = useDebouncedStateSearchParam<DebugSchema, string>(
        'pesquisa_composta',
    );

    const { params, updateTotalRegistros, tablePagination, onTableChange } =
        usePaginationSearchParams();

    const { data, isFetching } = useQuery({
        queryKey: ['apiProdutoGet', params],
        queryFn: () => apiProdutoGet({ params }),
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        updateTotalRegistros(data);
    }, [data, updateTotalRegistros]);

    return (
        <>
            <Input value={descricao} onChange={(e) => setDescricao(e.target.value)} />

            <Table
                rowKey='pro_idpk'
                pagination={tablePagination}
                columns={columns}
                dataSource={data}
                loading={isFetching}
                onChange={onTableChange}
                bordered={true}
            />
        </>
    );
}
