import { newRequest } from 'std/api/newRequest';
import type { ApiBaseParams } from 'std/api/types';
import type { TipoConsulta } from 'std/types/enum';
import type { ApiVendaFormaPagamentoPost, VendaFormaPagamento } from './types';

export type ApiVendaFormaPagamentoParams = ApiBaseParams & {
    venda_idpk: number;
    tipo_consulta?: TipoConsulta;
};

export function apiVendaFormaPagamentoGet({
    idpk,
    params,
}: { idpk?: number; params: ApiVendaFormaPagamentoParams }): Promise<VendaFormaPagamento[]> {
    return newRequest({
        url: 'Venda/FormaPagamento',
        type: 'get',
        idpk,
        params,
    });
}

export function apiVendaFormaPagamentoPost({
    params,
    body,
}: {
    params: { venda_idpk: number };
    body: VendaFormaPagamento;
}): Promise<ApiVendaFormaPagamentoPost[]> {
    return newRequest({
        url: 'Venda/FormaPagamento',
        type: 'post',
        params,
        body,
    });
}

export function apiVendaFormaPagamentoPut({
    idpk,
    params,
    body,
}: {
    idpk: number;
    params: { venda_idpk: number };
    body: VendaFormaPagamento;
}): Promise<ApiVendaFormaPagamentoPost[]> {
    return newRequest({
        url: 'Venda/FormaPagamento',
        type: 'put',
        idpk,
        params,
        body,
    });
}

export function apiVendaFormaPagamentoDelete({
    idpk,
    params,
}: {
    idpk: number;
    params: { venda_idpk: number };
}): Promise<unknown> {
    return newRequest({
        url: 'Venda/FormaPagamento',
        type: 'delete',
        idpk,
        params,
    });
}
