import type { ApiCidade } from 'features/cidade-uf-pais/api';

export enum TipoPessoa {
    Fisica = 'F',
    Juridica = 'J',
    Estrangeiro = 'E',
}

export type ApiPessoa = {
    email?: ApiPessoaEmail[];
    endereco?: ApiPessoaEndereco[];
    telefone?: ApiPessoaTelefone[];
    pessoa_foto?: ApiPessoaFoto[];

    pes_idpk: number;
    pes_empresa_idpk: number;
    pes_nome_razaosocial: string;

    pes_im?: string;
    pes_cnh?: string;
    pes_crt?: number;
    pes_cnae?: string;
    pes_sexo?: string;
    pes_ie_rg?: string;
    pes_conjuge?: string;
    pes_cnpj_cpf?: string;
    pes_filiacao?: string;
    pes_rg_orgao?: string;
    pes_rg_estado?: string;
    pes_rg_emissao?: string;
    pes_cnae_codigo?: string;
    pes_observacoes?: string;
    pes_responsavel?: string;
    pes_tipo_pessoa?: string;
    pes_cnh_validade?: string;
    pes_estado_civil?: string;
    pes_nome_fantasia?: string;
    pes_cnae_descricao?: string;
    pes_doc_estrangeiro?: string;
    pes_ultima_alteracao?: string;
    pes_data_nascimento_abertura?: string;
};

export type ApiPessoaEmail = {
    ema_idpk: number;
    ema_empresa_idpk: number;
    ema_pessoa_idpk: number;
    ema_email: string;
    ema_principal: 1 | 0;

    ema_geral?: number;
    ema_comercial?: number;
    ema_descricao?: string;
    ema_financeiro?: number;
    ema_ultima_alteracao?: string;
    ema_documentos_fiscais?: number;
};

export type ApiPessoaEndereco = {
    cidade?: ApiCidade;

    end_idpk: number;
    end_empresa_idpk: number;
    end_pessoa_idpk: number;
    end_logradouro: string;

    end_cep?: string;
    end_pais?: string;
    end_bairro?: string;
    end_numero?: string;
    end_descricao?: string;
    end_pais_idpk?: number;
    end_principal?: 1 | 0;
    end_tipogeral?: number;
    end_cidade_idpk?: number;
    end_complemento?: string;
    end_tipoentrega?: number;
    end_ultima_alteracao?: string;
    end_tipocorrespondencia?: number;
};

export type ApiPessoaFoto = {
    pfo_idpk: number;
    pfo_pessoa_idpk: number;
    pfo_empresa_idpk: number;

    pfo_foto_path?: string;
    pfo_ultima_alteracao?: string;
};

export type ApiPessoaTelefone = {
    tel_idpk: number;
    tel_telefone: string;
    tel_principal: 1 | 0;
    tel_pessoa_idpk: number;
    tel_empresa_idpk: number;

    tel_descricao?: string;
    tel_ultima_alteracao?: string;
};
