import { Button, type ButtonProps } from 'antd';
import { useMemo } from 'react';
import { getTextColor } from 'std/color';

type ButtonDynamicTextColorProps = ButtonProps & {
    backgroundColor: string;
};

export function ButtonDynamicTextColor(props: ButtonDynamicTextColorProps) {
    const { onClick, backgroundColor, children, style, ...other } = props;

    const color = useMemo(() => getTextColor(backgroundColor), [backgroundColor]);

    return (
        <Button onClick={onClick} style={{ backgroundColor, color, ...style }} {...other}>
            {children}
        </Button>
    );
}
