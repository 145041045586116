import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/contas-receber')({
    beforeLoad: () =>
        validateRoute({
            podeExibirFintech: true,
            permissao: 'upp_financeiro_conta_receber',
            nivel: TipoPermissao.Completo,
        }),
});
