import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { DEBOUNCE_TIME } from 'std/const/debounceTime';
import { useDebouncedValue } from './useDebouncedValue';

/** Um useState com debounce que também atualiza o search param informado */
export function useDebouncedStateSearchParam<Search, T>(
    searchParam: keyof Search,
    delay: number = DEBOUNCE_TIME,
): [T, (value: T) => void, T] {
    const navigate = useNavigate();
    const searchParams = useSearch({ strict: false });
    const initialState = (searchParams as Search)[searchParam] as T;

    const [value, setValue] = useState(initialState);
    const [debouncedValue] = useDebouncedValue(value, delay);

    useEffect(() => {
        navigate({ to: '.', search: (prev) => ({ ...prev, [searchParam]: debouncedValue }) });
    }, [navigate, searchParam, debouncedValue]);

    return [value, setValue, debouncedValue];
}
