import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Row, Spin, Typography } from 'antd';
import { nanoid } from 'nanoid';
import { StatusColors, type UploadedFile } from './types';

const { Text } = Typography;

type UploadedFileStatusProps = {
    uploadedFiles: UploadedFile[];
    removeFilefromList: (fileName: string) => void;
    currentStep: number;
};

export function UploadedFileStatus(props: UploadedFileStatusProps) {
    const { uploadedFiles, removeFilefromList, currentStep } = props;

    return (
        <Row className='border card-body'>
            <table>
                <tbody>
                    {uploadedFiles.map((uploadedFile) => (
                        <tr key={nanoid()}>
                            <td>
                                <Text style={{ color: StatusColors[uploadedFile.status] }}>
                                    <PaperClipOutlined />
                                    {uploadedFile.file.name}:
                                </Text>
                            </td>

                            <td>
                                <Text style={{ color: StatusColors[uploadedFile.status] }}>
                                    {uploadedFile.msg}
                                </Text>
                            </td>

                            {currentStep === 0 && (
                                <td>
                                    <Button
                                        onClick={() => removeFilefromList(uploadedFile.file.name)}
                                        type='text'
                                    >
                                        <DeleteOutlined
                                            style={{ color: StatusColors[uploadedFile.status] }}
                                        />
                                    </Button>
                                </td>
                            )}

                            {currentStep === 1 && uploadedFile.status === 'aguardando' && (
                                <td>
                                    <Spin spinning={true} size='small' />
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Row>
    );
}
