/** Verifica se uma string é um número inteiro */
export function isNumber(value: string | null | undefined): boolean {
    if (!value) {
        return false;
    }

    const innerValue = value.trim();

    if (innerValue === '') {
        return false;
    }

    return !Number.isNaN(+innerValue.replaceAll(',', '.'));
}

/** Aplica tranformacao tipo "Capitalizar" uma string. */
export function capitalize(value: string | null | undefined): string {
    if (!value) {
        return '';
    }

    return `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
}

/** Força uma string a ter um tamanho n máximo de caracteres */
export function ellipsize(value: string | null | undefined, size: number): string {
    if (typeof value !== 'string' || value.length === 0) {
        return '';
    }

    if (value.length <= size) {
        return value;
    }

    return `${value.substring(0, size)}…`;
}
