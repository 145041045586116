import { TipoPessoa } from 'features/pessoa/types';
import { onlyNumbers } from 'std/format';

function sameDigit(value: string): boolean {
    const first = value[0];

    if (!first) {
        return false;
    }

    for (let i = 1; i < value.length; i += 1) {
        if (value[i] !== first) {
            return false;
        }
    }

    return true;
}

// https://www.geradorcnpj.com/javascript-validar-value.htm
export function isCNPJ(value: string | null | undefined): boolean {
    if (!value) {
        return false;
    }

    const cnpj = value.replace(/[^\d]+/g, '');

    if (cnpj === '' || cnpj.length !== 14) {
        return false;
    }

    // Se todos digitos forem iguais é invalido
    if (sameDigit(cnpj)) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos;
        pos -= 1;
        if (pos < 2) {
            pos = 9;
        }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== Number(digitos.charAt(0))) {
        return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos;
        pos -= 1;
        if (pos < 2) {
            pos = 9;
        }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== Number(digitos.charAt(1))) {
        return false;
    }

    return true;
}

export function isCPF(value: string | null | undefined): boolean {
    const input = onlyNumbers(value);

    // Se todos digitos forem iguais é invalido
    if (sameDigit(input)) {
        return false;
    }

    let soma = 0;

    for (let i = 1; i <= 9; i += 1) {
        soma += Number.parseInt(input.substring(i - 1, i), 10) * (11 - i);
    }

    let resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }

    if (resto !== Number.parseInt(input.substring(9, 10), 10)) {
        return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i += 1) {
        soma += Number.parseInt(input.substring(i - 1, i), 10) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }

    if (resto !== Number.parseInt(input.substring(10, 11), 10)) {
        return false;
    }

    return true;
}

export function validatorDocumento(
    value: unknown,
    required: boolean,
    tipoPessoa: TipoPessoa | null | undefined,
): Promise<void> {
    const error = new Error('Informe um documento válido');

    if (!value || typeof value !== 'string') {
        return required ? Promise.reject(error) : Promise.resolve();
    }

    if (!tipoPessoa) {
        return isCPF(value) || isCNPJ(value) ? Promise.resolve() : Promise.reject(error);
    }

    if (tipoPessoa === TipoPessoa.Fisica) {
        return isCPF(value) ? Promise.resolve() : Promise.reject(error);
    }

    if (tipoPessoa === TipoPessoa.Juridica) {
        return isCNPJ(value) ? Promise.resolve() : Promise.reject(error);
    }

    // Estrangeiro
    return Promise.resolve();
}

const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmail(value: string | null | undefined): boolean {
    if (!value) {
        return false;
    }

    return regexEmail.test(value);
}

export function isTelefone(value: string | number | null | undefined): boolean {
    if (!value) {
        return false;
    }

    const innerValue = typeof value === 'string' ? value : value.toString();
    return innerValue.length > 9 && innerValue.length < 12;
}

export function isCep(value: string | null | undefined): boolean {
    return value?.length === 8;
}

export function validatorCep(_rule, value: unknown): Promise<void> {
    if (typeof value === 'string' && isCep(value)) {
        return Promise.resolve();
    }

    return Promise.reject(new Error('Informe um cep válido'));
}

export function validateImage(file: File, size: number): boolean {
    const isLt2M = file.size / 1024 / 1024 < size;
    return isLt2M;
}

export function isChaveAcesso(value: string | null | undefined): boolean {
    // se precisar de mais validações: https://wiki.centrium.com.br/nota-fiscal/validacao-da-chave-de-acesso-na-nota-de-entrada/
    return value?.length === 44;
}

const regexDate = /\d{1,2}\/\d{1,2}\/\d{2,4}/;

export function isDate(value: string | null | undefined): boolean {
    if (!value) {
        return false;
    }

    return regexDate.test(value);
}

export const passwordRules = [
    {
        required: true,
        message: 'Por favor, informe uma senha',
    },
    {
        min: 8,
        message: 'A senha deve ter no mínimo 8 caracteres',
    },
    {
        pattern: /[A-Z]/,
        message: 'A senha deve ter pelo menos uma letra maiúscula',
    },
    {
        pattern: /[a-z]/,
        message: 'A senha deve ter pelo menos uma letra minúscula',
    },
    {
        pattern: /[0-9]/,
        message: 'A senha deve ter pelo menos um número',
    },
    {
        pattern: /[!@#$%^&*(),.?":{}|<>]/,
        message: 'A senha deve ter pelo menos um caractere especial',
    },
];
