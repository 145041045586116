import { newRequest } from 'std/api/newRequest';
import { useLoginStore } from '../store';

export async function apiLoginLogof(): Promise<unknown> {
    const response = await newRequest({
        url: 'Login/Logof',
        type: 'post',
    });

    useLoginStore.getState().clearLogin();

    return response;
}
