import { useLoginStore } from 'features/login/store';
import { newRequest } from 'std/api/newRequest';

export enum ErroOrigem {
    Backend = 1,
    Frontend = 2,
    Outros = 3,
}

type ApiInternoErroParams = {
    origem: ErroOrigem;
};

type ApiInternoErroBody = {
    message: string;
    url: string;
    source?: string;
    lineno?: number;
    colno?: number;
    stack?: string[];
};

export function apiInternoErro({
    params,
    body,
}: { params: ApiInternoErroParams; body: ApiInternoErroBody }): Promise<unknown> {
    return newRequest({
        url: 'Interno/Erro',
        type: 'post',
        params,
        body: {
            APP_VERSION,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...body,
        },
        token: false,
        showErrorNotification: false,
    });
}
