// TODO: Achar uma forma melhor para bloquear letras nos inputs
export const allowedKeys = [
    'a', // Pra poder dar CTRL + a para selecionar tudo
    'c', // Para poder dar CTRL + c para copiar
    'v', // Para poder dar CTRL + v para colar
    ',', // Decimais
    '.', // Decimais
    '-',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'Delete',
    'Control',
    'Home',
    'End',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
];
