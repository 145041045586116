import { LockOutlined, WarningFilled } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { Button, Modal } from 'antd';
import { useEmpresaBloqueada } from 'features/empresa/hooks/useEmpresaBloqueada';
import { useEmpresaQuery } from 'features/empresa/hooks/useEmpresaQuery';
import { ApiEmpresaSituacao } from 'features/empresa/types/ApiEmpresaSituacao';
import { apiLoginLogof } from 'features/login/api/apiLoginLogof';
import { EntreEmContato } from 'features/suporte/components/EntreEmContato';
import { useEffect, useState } from 'react';

export function ModalEmpresaSituacao() {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const { empresa } = useEmpresaQuery();
    const { bloqueado, empresaDataBloqueio, empresaSituacao } = useEmpresaBloqueada(empresa);

    const mutationLogout = useMutation({
        mutationFn: apiLoginLogof,
    });

    const closeModal = (): void => {
        if (bloqueado) {
            mutationLogout.mutate();
            navigate({ to: '/auth/login' });
            return;
        }

        setShowModal(false);
    };

    useEffect(() => {
        setShowModal(
            empresaSituacao === ApiEmpresaSituacao.Bloqueada ||
                empresaSituacao === ApiEmpresaSituacao.Pendente,
        );
    }, [empresaSituacao]);

    return (
        <Modal
            onCancel={closeModal}
            open={showModal}
            maskClosable={!bloqueado}
            title={
                <>
                    {!bloqueado && (
                        <>
                            <WarningFilled
                                style={{ fontSize: 30, paddingRight: 10, color: '#f5cd6b' }}
                            />
                            Existem pendências de pagamento
                        </>
                    )}

                    {bloqueado && (
                        <>
                            <LockOutlined
                                style={{ fontSize: 30, paddingRight: 10, color: '#d82b2b' }}
                            />
                            Sistema temporariamente bloqueado
                        </>
                    )}
                </>
            }
            footer={
                <>
                    {!bloqueado && (
                        <Button type='primary' onClick={closeModal}>
                            Fechar
                        </Button>
                    )}
                </>
            }
        >
            {mutationLogout.isPending && (
                <span style={{ fontWeight: 'bold', fontSize: 20 }}>Saindo...</span>
            )}

            {!bloqueado && (
                <>
                    <p>Atenção, existem cobranças em aberto para sua empresa.</p>
                    <p>
                        Efetue o pagamento até <b>{empresaDataBloqueio}</b> para evitar o bloqueio
                        automático do seu sistema.
                    </p>
                </>
            )}

            {bloqueado && (
                <>
                    <p>
                        Desculpe, seu sistema foi temporariamente bloqueado pois existem pendências
                        em aberto.
                    </p>
                    <p>Efetue o acerto das pendências para seu sistema ser liberado.</p>
                </>
            )}

            <EntreEmContato showTitle={false} />
        </Modal>
    );
}
