import { useNavigate } from '@tanstack/react-router';
import { Col, Image, Input, List, Row, Typography } from 'antd';
import { useConfiguracaoStore } from 'features/configuracao/store';
import { useLoginStore } from 'features/login/store';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { RootDispatch } from 'state/store';
import { formatDocument } from 'std/format';
import { useScreenAliases } from 'std/hooks/useScreenAliases';
import { removerAcento } from 'std/string/removerAcento';

const { Text } = Typography;

export function EmpresaSelector({ closeModal }: { closeModal?: () => void }) {
    const dispatch = useDispatch<RootDispatch>();

    const empresaSelectorLayout = useConfiguracaoStore((s) => s.empresaSelectorLayout);
    const usuarioEmpresaList = useLoginStore((s) => s.login?.usuario_empresa) || [];
    const switchEmpresa = useLoginStore((s) => s.switchEmpresa);

    const { isSmallestMobile } = useScreenAliases();
    const navigate = useNavigate();

    const [search, setSearch] = useState('');

    const onClickEmpresa = (emp_idpk: number): void => {
        closeModal?.();
        switchEmpresa(emp_idpk);
        navigate({ to: '/' });

        dispatch.caixaBanco.setState({
            filterContaBancaria: undefined,
            saldoAtual: undefined,
        });
    };

    const empresasList = useMemo(() => {
        if (search === '') {
            return usuarioEmpresaList;
        }

        const s = removerAcento(search.toLowerCase());

        return usuarioEmpresaList.filter((usuario_empresa) => {
            const razao_social = removerAcento(
                usuario_empresa.empresa?.[0]?.emp_nome_razaosocial?.toLowerCase() || '',
            );

            const fantasia = removerAcento(
                usuario_empresa.empresa?.[0]?.emp_nome_fantasia?.toLowerCase() || '',
            );

            const documento = usuario_empresa.empresa?.[0]?.emp_cnpj_cpf || '';

            return razao_social.includes(s) || fantasia.includes(s) || documento.includes(s);
        });
    }, [search, usuarioEmpresaList]);

    return (
        <div>
            <Row justify='center'>
                <Input
                    autoFocus={true}
                    style={{ marginBottom: 10, width: '60%' }}
                    placeholder='Pesquise uma empresa...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Row>

            {empresaSelectorLayout === 'grade' && (
                <Row className='justify-center overflow-x-hidden overflow-y-auto max-h-96'>
                    {empresasList.map((usuario_empresa) => {
                        const estaEmpresa = usuario_empresa.empresa[0];

                        if (!estaEmpresa) {
                            return <></>;
                        }

                        return (
                            <Col
                                key={usuario_empresa.use_idpk}
                                style={{ width: '200px' }}
                                className={`flex flex-col items-center p-3 mx-3 cursor-pointer card 
                                    border border-solid border-gray-100
                                    hover:bg-gray-100 hover:border-primary`}
                                onClick={() => onClickEmpresa(estaEmpresa.emp_idpk)}
                            >
                                <Image
                                    src={estaEmpresa.emp_logotipo_foto}
                                    fallback={'resources/images/empresa_fallback.png'}
                                    style={{
                                        width: 100,
                                        height: 100,
                                    }}
                                    className='mb-2'
                                    rootClassName='flex justify-center'
                                    preview={false}
                                />
                                <Text className='mb-0 text-center card-title'>
                                    {estaEmpresa.emp_nome_razaosocial}
                                </Text>
                                <Text
                                    className='text-center font-italic text-slate-500 text-nowrap'
                                    style={{ width: '30rem' }}
                                >
                                    {estaEmpresa.emp_cnpj_cpf &&
                                        `${
                                            estaEmpresa.emp_tipo_pessoa === 'F' ? 'CPF' : 'CNPJ'
                                        }: ${formatDocument(estaEmpresa.emp_cnpj_cpf)}`}
                                </Text>
                            </Col>
                        );
                    })}
                </Row>
            )}

            {empresaSelectorLayout === 'lista' && (
                <List
                    className='max-h-[50vh] overflow-y-auto'
                    itemLayout='horizontal'
                    dataSource={empresasList}
                    renderItem={(usuario_empresa) => {
                        const estaEmpresa = usuario_empresa.empresa[0];

                        if (!estaEmpresa) {
                            return <></>;
                        }

                        return (
                            <div
                                className={`px-3 mr-3 card border border-solid border-gray-100
                                    hover:bg-gray-100 hover:border-primary`}
                                role='button'
                                tabIndex={0}
                                onClick={() => onClickEmpresa(estaEmpresa.emp_idpk)}
                                onKeyDown={() => onClickEmpresa(estaEmpresa.emp_idpk)}
                                style={{ minWidth: isSmallestMobile ? '100%' : '450px' }}
                            >
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Image
                                                src={estaEmpresa.emp_logotipo_foto}
                                                fallback={'resources/images/empresa_fallback.png'}
                                                style={{
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                className='mb-2'
                                                rootClassName='flex justify-center'
                                                preview={false}
                                            />
                                        }
                                        title={<span>{estaEmpresa.emp_nome_razaosocial}</span>}
                                        description={
                                            estaEmpresa.emp_cnpj_cpf &&
                                            `${
                                                estaEmpresa.emp_tipo_pessoa === 'F' ? 'CPF' : 'CNPJ'
                                            }: ${formatDocument(estaEmpresa.emp_cnpj_cpf)}`
                                        }
                                    />
                                </List.Item>
                            </div>
                        );
                    }}
                />
            )}
        </div>
    );
}
