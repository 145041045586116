import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { ComprasState } from './types';

const initialState: ComprasState = {
    // EFFECTS
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    lancarCompra: initReduxFn,
    resumoLancamento: initReduxFn,
    imprimirCompra: initReduxFn,
    estornarCompra: initReduxFn,
    uploadCompra: initReduxFn,

    // MANIFESTADOR
    manifestar: initReduxFn,
    carregarManifestacao: initReduxFn,
    buscarNaReceita: initReduxFn,

    // MOSTRAR DRAWERS
    showDrawerComprasDetalhes: false,
    showDrawerProdutos: false,
    showDrawerProdutosFormPreco: false,
    showDrawerLancarCompra: false,
    showDrawerFormacaoPreco: false,
    showDrawerCarregar: false,
    showDrawerManifestarDetalhes: false,
    showDrawerManifestar: false,

    // VALORES PARA DRAWERS
    drawerProdutoItem: undefined,
    drawerProdutoIndex: undefined,
    drawerFormacaoPrecoValues: undefined,

    // FORMS TOTAIS
    formCalcTotais: {
        qtdItensVenda: 0,
        totalDesconto: 0,
        totalFrete: 0,
        totalSeguro: 0,
        totalOutrasDespesas: 0,
        totalProdutosSemDesconto: 0,
        totalProdutos: 0,
        totalGeral: 0,
        totalIcmsSt: 0,
        totalFcpSt: 0,
        totalIpi: 0,
        totalII: 0,
    },
};

export const ComprasModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ComprasState>(),
    effects,
});
