import { createFileRoute, redirect } from '@tanstack/react-router';
import { isSandbox } from 'std/url';

export const Route = createFileRoute('/a/debug')({
    beforeLoad: () => {
        if (!isSandbox()) {
            throw redirect({ to: '/' });
        }
    },
});
