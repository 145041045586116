import type { ApiEmpresa } from 'features/empresa/types/ApiEmpresa';
import { newRequest } from 'std/api/newRequest';
import type { ApiBaseParams } from 'std/api/types';

export function apiEmpresaGet(params?: ApiBaseParams): Promise<ApiEmpresa[]> {
    return newRequest({
        url: 'Empresa/Empresa',
        type: 'get',
        params,
    });
}
