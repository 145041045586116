import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import { useLoginStore } from 'features/login/store';
import type { TFormCaixaBoleto } from 'pages/movimentacoes/forms/types';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { isResponseErr, throwIfResponseIsErr } from 'std/api/util';
import { DATE_FORMAT } from 'std/const/date';
import { registrosToCsv } from 'std/csv';
import { roundAbnt } from 'std/math';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { DefaultColors } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            pesquisar?: string;
            data_de?: string;
            data_ate?: string;
            categoria?: string;
            usuario_idpk?: number;
            financeiro_conta_idpk?: number;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        const { caixaBanco } = state;
        const { getTable, get, filterContaBancaria } = caixaBanco;

        dispatch.caixaBanco.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        const {
            filterValor,
            filterStatus,
            filterNome,
            filterDateRange,
            filterUsuario,
            filterCategoria,
            filterMarcador,
            filterOrigem,
            registroInitial,
            qtdRegistros,
            sortParams,
            pagination,
        } = getTable;

        const {
            pesquisar,
            data_de,
            data_ate,
            categoria,
            usuario_idpk,
            financeiro_conta_idpk,
            total_registros,
            registro_inicial,
            qtde_registros,
            sort,
            resetPagination,
            ...restPayload
        } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            data_de: data_de || filterDateRange?.start.format('DD-MM-YYYY'),
            data_ate: data_ate || filterDateRange?.end.format('DD-MM-YYYY'),
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            marcadores: filterMarcador,
            origem: filterOrigem,
            orderby: 'fcl_data desc, fcl_idpk desc',
            ...restPayload,
        };

        // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
        if (filterStatus) {
            const tipos = {
                Entrada: 1,
                Saída: 2,
                'Lançamentos de saldo': 3,
            };

            params.tipo = tipos[filterStatus];
        }

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (!!pesquisar || !!filterNome) {
            params.descricao = pesquisar || filterNome;
        }

        // CASTO TIVER O FILTRO DE VALOR (*OPCIONAL)
        if (filterValor) {
            params.where = `((fcl_valor = ${filterValor}) OR (fcl_valor = ${-filterValor}))`;
        }

        // CASO TIVER O FILTRO DE USUÁRIO (*OPCIONAL)
        if (!!usuario_idpk || !!filterUsuario) {
            params.usuario_codigo = usuario_idpk || filterUsuario?.usu_idpk;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (!!financeiro_conta_idpk || !!filterContaBancaria) {
            params.financeiro_conta_idpk = financeiro_conta_idpk || filterContaBancaria?.fco_idpk;
        }

        if (!!categoria || !!filterCategoria) {
            params.financeiro_categoria_idpk = categoria || filterCategoria?.fca_idpk;
        }

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
        if (resetPagination) {
            params.registro_inicial = 0;
            params.total_registros = 'S';
        }

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoConsultar, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            const { caixaBanco: newCaixaBanco } = state;
            const { getTable: newGetTable } = newCaixaBanco;

            dispatch.caixaBanco.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...newGetTable,
                    updateTable: false,
                    filterStatus: status || filterStatus,
                    ...(totalRegistroResponse && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    registroInitial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    qtdRegistros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async totalizador(_, state: RootState): Promise<void> {
        const { caixaBanco } = state;
        const { totalizador, getTable, filterContaBancaria } = caixaBanco;

        dispatch.caixaBanco.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const {
            filterNome,
            filterDateRange,
            filterUsuario,
            filterCategoria,
            filterMarcador,
            filterValor,
            filterOrigem,
        } = getTable;

        const params: BuildUrlParams = {
            data_de: filterDateRange?.start.format('DD-MM-YYYY'),
            data_ate: filterDateRange?.end.format('DD-MM-YYYY'),
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            marcadores: filterMarcador,
            origem: filterOrigem,
        };

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (filterNome) {
            params.descricao = filterNome;
        }
        // CASO TIVER O FILTRO DE USUÁRIO (*OPCIONAL)
        if (filterUsuario) {
            params.usuario_codigo = filterUsuario.usu_idpk;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (filterContaBancaria) {
            params.financeiro_conta_idpk = filterContaBancaria?.fco_idpk;
        }

        if (filterCategoria) {
            params.financeiro_categoria_idpk = filterCategoria?.fca_idpk;
        }

        // CASTO TIVER O FILTRO DE VALOR (*OPCIONAL)
        if (filterValor) {
            params.where = `((fcl_valor = ${filterValor}) OR (fcl_valor = ${-filterValor}))`;
        }

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                'Saldo inicial': DefaultColors.Black,
                Entrada: DefaultColors.Green,
                'Saldo final': DefaultColors.Purple,
                Saída: DefaultColors.Red,
            };

            const totais: CardProperties[] = registros.map((total) => ({
                color: cores[total?.tipo] || DefaultColors.Black,
                title: total.tipo?.[0] ? total.tipo[0].toUpperCase() + total.tipo.slice(1) : '',
                amount: total?.valor_total || 0,
                number: total?.quantidade_total || 0,
                value: total?.tipo || '',
            }));

            dispatch.caixaBanco.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { fcl_idpk: number }, state: RootState): Promise<void> {
        const { caixaBanco } = state;
        const { getOne } = caixaBanco;

        dispatch.caixaBanco.setState({
            getOne: startReduxFn(getOne?.data),
        });

        const { fcl_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoConsultar, params, fcl_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.caixaBanco.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(payload: { body: any }, state: RootState): Promise<void> {
        const { caixaBanco } = state;
        const { post } = caixaBanco;

        dispatch.caixaBanco.setState({
            post: startReduxFn(post?.data),
        });

        const { body } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoInserir, params);

        try {
            const response = await comTokenPost(url, body);

            if (isResponseErr(response)) {
                // Colocado aqui para inserir o ERROR para pegar os dados personalizados
                dispatch.caixaBanco.setState({
                    post: endReduxFnError(response.data),
                });

                throw new Error(response.data?.mensagem || '');
            }

            const fcl_idpk: number = response?.data?.registros
                ? response.data.registros[0].fcl_idpk
                : 0;

            dispatch.caixaBanco.setState({
                post: endReduxFnOk({ fcl_idpk }),
            });

            notification.success({
                message: 'Feito!',
                description: 'Lançamento de caixa cadastrado',
            });
        } catch (error) {
            notification.error({
                message: 'Não foi possível cadastrar o lançamento de caixa!',
                description: error.message,
            });
        }
    },

    async put(
        payload: { fcl_idpk: number; body: TFormCaixaBoleto },
        state: RootState,
    ): Promise<void> {
        const { caixaBanco } = state;
        const { put } = caixaBanco;

        dispatch.caixaBanco.setState({
            put: startReduxFn(put?.data),
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
        const { fcl_idpk = 0, body } = payload;

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoAlterar, params, fcl_idpk);

        try {
            body.fcl_idpk = undefined;
            const response = await comTokenPut(url, body);

            if (isResponseErr(response)) {
                // Colocado aqui para inserir o ERROR para pegar os dados personalizados
                dispatch.caixaBanco.setState({
                    put: endReduxFnError(response.data),
                });

                throw new Error(response.data?.mensagem || '');
            }

            dispatch.caixaBanco.setState({
                put: endReduxFnOk('Success'),
            });

            notification.success({
                message: 'Feito!',
                description: 'Lançamento de caixa atualizado',
            });
        } catch (error) {
            notification.error({
                message: 'Não foi possível atualizar o lançamento de caixa!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { fcl_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { caixaBanco } = state;
        const { getTable, remove } = caixaBanco;

        dispatch.caixaBanco.setState({
            remove: startReduxFn(remove?.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { fcl_idpk = 0, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoRemover, params, fcl_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.caixaBanco.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.caixaBanco.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.caixaBanco.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.caixaBanco.get({ total_registros: 'S' });
                    dispatch.caixaBanco.totalizador({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Lançamento excluído!',
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async estornar(
        payload: { fcl_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { caixaBanco } = state;
        const { getTable, estornar } = caixaBanco;

        dispatch.caixaBanco.setState({
            estornar: startReduxFn(estornar?.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { fcl_idpk = 0, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroContaLancamentoEstornar, params, fcl_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.caixaBanco.setState({
                estornar: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.caixaBanco.get({ total_registros: 'S' });
                dispatch.caixaBanco.totalizador({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Lançamento estornado!',
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                estornar: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
    async getRelatorio(_, state: RootState): Promise<void> {
        const { caixaBanco } = state;
        const { get, getTable, getRelatorio, filterContaBancaria } = caixaBanco;

        dispatch.caixaBanco.setState({
            getRelatorio: startReduxFn(getRelatorio?.data),
            get: { ...get, loading: true },
        });

        const {
            filterStatus,
            filterNome,
            filterDateRange,
            filterUsuario,
            filterCategoria,
            sortParams,
        } = getTable;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            data_de: filterDateRange?.start.format('DD-MM-YYYY'),
            data_ate: filterDateRange?.end.format('DD-MM-YYYY'),
            orderby: 'fcl_data desc, fcl_idpk desc',
        };

        // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
        if (filterStatus) {
            params.tipo = filterStatus;
        }

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (filterNome) {
            params.descricao = filterNome;
        }

        // CASO TIVER O FILTRO DE USUÁRIO (*OPCIONAL)
        if (filterUsuario) {
            params.usuario_codigo = filterUsuario?.usu_idpk;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (filterContaBancaria) {
            params.financeiro_conta_idpk = filterContaBancaria?.fco_idpk;
        }

        if (filterCategoria) {
            params.financeiro_categoria_idpk = filterCategoria?.fca_idpk;
        }

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sortParams?.shouldSort) {
            params.orderby = `${sortParams?.fieldName}${sortParams?.orderDirection}`;
        }

        const url = buildUrl(Endpoint.RelatorioFinanceiroContaExtrato, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const { data = {} } = response;

            dispatch.caixaBanco.setState({
                getRelatorio: endReduxFnOk(data),
                get: { ...get, loading: false },
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                getRelatorio: endReduxFnError(error),
                get: { ...get, loading: false },
            });
        }
    },

    async exportarCsv(_, state: RootState): Promise<void> {
        const { caixaBanco } = state;
        const { get, getTable, exportarCsv, filterContaBancaria } = caixaBanco;

        dispatch.caixaBanco.setState({
            exportarCsv: startReduxFn(exportarCsv?.data),
            get: { ...get, loading: true },
        });

        const {
            filterStatus,
            filterNome,
            filterDateRange,
            filterUsuario,
            filterCategoria,
            sortParams,
        } = getTable;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            data_de: filterDateRange?.start.format('DD-MM-YYYY'),
            data_ate: filterDateRange?.end.format('DD-MM-YYYY'),
            orderby: 'fcl_data desc, fcl_idpk desc',
        };

        // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
        if (filterStatus) {
            params.tipo = filterStatus;
        }

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (filterNome) {
            params.descricao = filterNome;
        }

        // CASO TIVER O FILTRO DE USUÁRIO (*OPCIONAL)
        if (filterUsuario) {
            params.usuario_codigo = filterUsuario?.usu_idpk;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (filterContaBancaria) {
            params.financeiro_conta_idpk = filterContaBancaria?.fco_idpk;
        }

        if (filterCategoria) {
            params.financeiro_categoria_idpk = filterCategoria?.fca_idpk;
        }

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sortParams?.shouldSort) {
            params.orderby = `${sortParams?.fieldName}${sortParams?.orderDirection}`;
        }

        const url = buildUrl(Endpoint.RelatorioFinanceiroContaExtrato, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data = {},
                data: {
                    financeiro_conta = [],
                    registros = [],
                    forma_pagamento = [],
                    totais = [],
                } = {},
            } = response;

            let totalEntrada = 0;
            let totalSaida = 0;
            const dataFormatted = registros.map((d) => {
                totalEntrada = roundAbnt(totalEntrada + (d?.fcl_valor >= 0 ? d?.fcl_valor : 0));
                totalSaida = roundAbnt(totalSaida + (d?.fcl_valor < 0 ? d?.fcl_valor * -1 : 0));

                return {
                    Data: d?.fcl_data || '',
                    Descrição: d?.fcl_descricao || '',
                    ...(!filterContaBancaria && {
                        Conta: d?.financeiro_conta?.fco_descricao || '',
                    }),
                    Categoria: d?.financeiro_categoria?.fca_descricao || '',
                    Entrada: d?.fcl_valor >= 0 ? `"${d?.fcl_valor || 0}"` : '',
                    Saída: d?.fcl_valor < 0 ? `"${d?.fcl_valor * -1}"` : '',
                };
            });

            const extraContent = ['\n'];

            extraContent.push(
                `Totais;${filterContaBancaria ? '' : ';'};;"${totalEntrada}";"${totalSaida}"`,
            );
            totais.map((t) => {
                extraContent.push('\n');
                extraContent.push(`${t?.tipo};;;;"${t?.valor_total || 0}"`);

                return '';
            });

            extraContent.push('\n');
            extraContent.push('\n');
            extraContent.push('Forma de Pagamento;Qtde de lançamentos;Valor Total');
            let totalQtdRegistros = 0;
            let totalValorFormaPagamento = 0;
            forma_pagamento.map((f) => {
                totalQtdRegistros += f?.qtde_registros || 0;
                totalValorFormaPagamento = roundAbnt(
                    totalValorFormaPagamento + (f?.valor_total || 0),
                );

                extraContent.push('\n');
                extraContent.push(
                    `${f?.fop_descricao};"${f?.qtde_registros || 0}";"${f?.valor_total || 0}"`,
                );

                return '';
            });
            extraContent.push('\n');
            extraContent.push(`Total;"${totalQtdRegistros}";"${totalValorFormaPagamento}"`);

            if (!filterContaBancaria) {
                extraContent.push('\n');
                extraContent.push('\n');
                extraContent.push('Saldo atual das contas');
                let totalSaldoAtual = 0;
                financeiro_conta.map((fc) => {
                    totalSaldoAtual = roundAbnt(totalSaldoAtual + (fc?.fco_saldo_atual || 0));

                    extraContent.push('\n');
                    extraContent.push(`${fc?.fco_descricao};"${fc?.fco_saldo_atual || 0}"`);

                    return '';
                });
                extraContent.push('\n');
                extraContent.push(`Total;"${totalSaldoAtual}"`);
            }

            registrosToCsv(
                dataFormatted,
                `Movimentacoes_${filterDateRange?.start.format(
                    DATE_FORMAT,
                )}_${filterDateRange?.end.format(DATE_FORMAT)}${
                    filterStatus ? `_${filterStatus.toLocaleUpperCase()}` : ''
                }${filterNome ? `_${filterNome.toLocaleUpperCase()}` : ''}${
                    filterUsuario?.usu_idpk ? `_${filterUsuario?.usu_idpk}` : ''
                }${filterContaBancaria?.fco_idpk ? `_${filterContaBancaria?.fco_idpk}` : ''}${
                    filterCategoria?.fca_idpk ? `_${filterCategoria?.fca_idpk}` : ''
                }`,
                undefined,
                extraContent,
            );

            dispatch.caixaBanco.setState({
                exportarCsv: endReduxFnOk(data),
                get: { ...get, loading: false },
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                exportarCsv: endReduxFnError(error),
                get: { ...get, loading: false },
            });
        }
    },

    async getExtrato(
        payload: {
            pesquisar?: string;
            data_de?: string;
            data_ate?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        const { caixaBanco } = state;
        const { getTableExtrato, getExtrato, filterContaBancaria } = caixaBanco;

        dispatch.caixaBanco.setState({
            getExtrato: startReduxFn(getExtrato.data),
            getTableExtrato: {
                ...getTableExtrato,
                updateTable: false,
            },
        });

        const {
            filterNome,
            filterDateRange,
            registroInitial,
            qtdRegistros,
            sortParams,
            pagination,
        } = getTableExtrato;
        const {
            pesquisar,
            data_de,
            data_ate,
            total_registros,
            registro_inicial,
            qtde_registros,
            sort,
            resetPagination,
            ...restPayload
        } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            data_de: data_de || filterDateRange?.start.format('DD-MM-YYYY'),
            data_ate: data_ate || filterDateRange?.end.format('DD-MM-YYYY'),
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            orderby: 'fco_data desc, fco_idpk desc',
            ...restPayload,
        };

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (!!pesquisar || !!filterNome) {
            params.arquivo_nome = pesquisar || filterNome;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (filterContaBancaria) {
            params.financeiro_conta_idpk = filterContaBancaria?.fco_idpk;
        }

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
        if (resetPagination) {
            params.registro_inicial = 0;
            params.total_registros = 'S';
        }

        const url = buildUrl(Endpoint.OfxConsultar, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            const { caixaBanco: newCaixaBanco } = state;
            const { getTableExtrato: newGetTableExtrato } = newCaixaBanco;

            dispatch.caixaBanco.setState({
                getExtrato: endReduxFnOk(registros),
                getTableExtrato: {
                    ...newGetTableExtrato,
                    updateTable: false,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    registroInitial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    qtdRegistros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                getExtrato: endReduxFnError(error),
            });
        }
    },
    async removeExtrato(
        payload: { fco_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { caixaBanco } = state;
        const { getTableExtrato, removeExtrato } = caixaBanco;

        dispatch.caixaBanco.setState({
            removeExtrato: startReduxFn(removeExtrato?.data),
            getTableExtrato: {
                ...getTableExtrato,
                loadingTable: true,
            },
        });

        const { fco_idpk = 0, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.OfxRemover, params, fco_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.caixaBanco.setState({
                removeExtrato: endReduxFnOk('Success'),
                getTableExtrato: {
                    ...getTableExtrato,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTableExtrato;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.caixaBanco.getExtrato({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.caixaBanco.getExtrato({ total_registros: 'S' });
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Lançamento excluído!',
            });
        } catch (error) {
            dispatch.caixaBanco.setState({
                removeExtrato: endReduxFnError(error),
                getTableExtrato: {
                    ...getTableExtrato,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
});
