export type GetTypesProps =
    | 'first-get'
    | 'pagination-change'
    | 'record-filtering'
    | 'add-record'
    | 'edit-record'
    | 'delete-record'
    | 'column-sorting'
    | 'redirection'
    | 'custom-url'
    | 'single-record'
    | 'totalizadores'
    | 'infinito';

/** @deprecated */
export enum GetTypes {
    firstGet = 'first-get',
    paginationChange = 'pagination-change',
    recordFiltering = 'record-filtering',
    addRecord = 'add-record',
    editRecord = 'edit-record',
    deleteRecord = 'delete-record',
    columnSorting = 'column-sorting',
    redirection = 'redirection',
    customUrl = 'custom-url',
    singleRecord = 'single-record',
    totalizadores = 'totalizadores',
    infinito = 'infinito',
}
