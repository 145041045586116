import type { CardProperties } from 'components/revisar/CardList/CardList';
import type { ICompartilhar } from 'components/revisar/FormCompartilhar';
import type { Dayjs } from 'dayjs';
import type { TRowManifestoCarga } from 'pages/manifesto-carga/table/dataFormatTable';
import type { ReduxFn } from 'std/redux';
import type { TablePagination } from 'std/types';
import type { SortParams } from 'std/types/interfaces';

export enum EStatusMdf {
    Aguardando = 1,
    Incorreta = 2,
    Emitido = 3,
    Cancelado = 4,
    Encerrado = 5,
}

export type TTotaisMdf = {
    tipo: string;
    valor_total: number;
    quantidade_total: number;
};

export type TManifestoCargaCarregamento = {
    mdc_cidade: string;
    mdc_cidade_ibge?: string;
    mdc_cidade_idpk: number;
    mdc_idpk?: number;
    mdc_mdfe_idpk?: number;
    mdc_uf: string;
    mdc_ultima_alteracao?: string;
    excluir?: 'S';
};

export type TManifestoCargaDocumento = {
    mdd_chave_acesso: string;
    mdd_cidade: string;
    mdd_cidade_ibge?: string;
    mdd_cidade_idpk?: number;
    mdd_destinatario: string;
    mdd_idpk?: number;
    mdd_mdfe_idpk?: number;
    mdd_nota_fiscal_idpk?: number;
    mdd_peso: number;
    mdd_tipo: number;
    mdd_uf: string;
    mdd_ultima_alteracao?: string;
    mdd_valor: number;
    excluir?: 'S';
};

export type TManifestoCargaPercurso = {
    mdp_idpk?: number;
    mdp_mdfe_idpk?: number;
    mdp_uf: string;
    mdp_ultima_alteracao?: string;
    excluir?: 'S';
};

export type TManifestoCargaCondutor = {
    mdc_condutor: string;
    mdc_documento: string;
    mdc_idpk?: number;
    mdc_mdfe_idpk?: number;
    mdc_ultima_alteracao?: string;
    excluir?: 'S';
};

export type TManifestoCargaVeiculo = {
    mdv_capacidade_peso: number;
    mdv_capacidade_volume: number;
    mdv_carroceria: number;
    mdv_idpk?: number;
    mdv_mdfe_idpk?: number;
    mdv_placa: string;
    mdv_placa_uf: string;
    mdv_renavam: string;
    mdv_tara: number;
    mdv_ultima_alteracao?: string;
    excluir?: 'S';
};

export type TManifestoCargaSeguroAverbacao = {
    msa_averbacao: string;
    msa_idpk?: number;
    msa_mdfe_seguro_idpk?: number;
    excluir?: 'S';
};

export type TManifestoCargaSeguro = {
    mds_apolice_numero: string;
    mds_idpk?: number;
    mds_mdfe_idpk?: number;
    mds_resp_documento: string;
    mds_resp_seguro: number;
    mds_seguradora_documento: string;
    mds_seguradora_nome: string;
    mds_ultima_alteracao?: string;
    mdfe_seguro_averbacao?: TManifestoCargaSeguroAverbacao[];
    excluir?: 'S';
};

type TManifestoCargaEncerramento = {
    mdf_enc_codigo_status: number;
    mdf_enc_data: string;
    mdf_enc_descricao_status: string;
    mdf_enc_protocolo: string;
};

type TManifestoCargaCancelamento = {
    mdf_canc_codigo_status: string;
    mdf_canc_data: string;
    mdf_canc_descricao_status: string;
    mdf_canc_justificativa: string;
    mdf_canc_protocolo: string;
};

export type TManifestoCarga = {
    mdf_cep_carregamento: string;
    mdf_cep_descarregamento: string;
    mdf_cte_qtde: number;
    mdf_data_emissao: string;
    mdf_empresa_idpk: number;
    mdf_erro?: string;
    mdf_hora_emissao: string;
    mdf_idpk?: number;
    mdf_info_contribuinte: string;
    mdf_info_fisco: string;
    mdf_mdfe_chave?: string;
    mdf_mdfe_pdf?: string;
    mdf_mdfe_xml?: string;
    mdf_nfe_qtde: number;
    mdf_numero?: number;
    mdf_peso: number;
    mdf_prod_ean: string;
    mdf_prod_ncm: string;
    mdf_prod_nome: string;
    mdf_prod_tipo_carga: number;
    mdf_prop_documento: string;
    mdf_prop_ie_rg: string;
    mdf_prop_nome: string;
    mdf_prop_rntrc: string;
    mdf_prop_tipo: number;
    mdf_prop_uf: string;
    mdf_prop_veic_nao_emitente: string;
    mdf_protocolo: string;
    mdf_protocolo_data: string;
    mdf_rodoviario_ciot: string;
    mdf_rodoviario_contratante_documento: string;
    mdf_rodoviario_rntrc: string;
    mdf_serie: number;
    mdf_status?: number;
    mdf_tipo_rodado: number;
    mdf_uf_carregamento: string;
    mdf_uf_descarregamento: string;
    mdf_ultima_alteracao?: string;
    mdf_unidade: string;
    mdf_usuario: string;
    mdf_usuario_idpk: number;
    mdf_valor_total: number;
    mdfe_carregamento?: TManifestoCargaCarregamento[];
    mdfe_condutor?: TManifestoCargaCondutor[];
    mdfe_documento?: TManifestoCargaDocumento[];
    mdfe_percurso?: TManifestoCargaPercurso[];
    mdfe_seguro?: TManifestoCargaSeguro[];
    mdfe_veiculo?: TManifestoCargaVeiculo[];
    cancelamento?: TManifestoCargaCancelamento;
    encerramento?: TManifestoCargaEncerramento;
};

type TGetTable = {
    // FILTROS
    filterDateRange: Interval<Dayjs>;
    filterNome: string;
    filterStatus: string;

    // TOTAIS REGISTROS (PARA PAGINAÇÃO)
    totalRegistrosTable: number;
    registroInitial: number;
    qtdRegistros: number;

    // ORDENAÇÃO
    sortParams: SortParams;

    // PAGINAÇÃO
    pagination: TablePagination;

    // LINHA SELECIONADAS
    selectedRows: TRowManifestoCarga[];
    selectedRow: TRowManifestoCarga | undefined;

    // UPDATE TABLE ON CHANGES
    updateTable: boolean;

    // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
    loadingTable: boolean;
};

export type ManifestoCargaState = {
    // EFFECTS
    get: ReduxFn<TManifestoCarga[]>;
    getOne: ReduxFn<TManifestoCarga>;
    post: ReduxFn<{ mdf_idpk: number }>;
    put: ReduxFn<string>;
    totalizador: ReduxFn<CardProperties[]>;
    remove: ReduxFn<string>;
    imprimir: ReduxFn<null>;
    preVisualizar: ReduxFn<string>;
    compartilharSend: ReduxFn<null>;
    encerrar: ReduxFn<string>;
    cancelar: ReduxFn<string>;
    emitir: ReduxFn<string> & { cancel: boolean };

    // TABELAS
    getTable: TGetTable;

    // MOSTRAR DRAWERS
    showDrawerDetalhes: boolean;
    showDrawerCompartilhar: boolean;
    showDrawerDocumentosCarga: boolean;
    showDrawerEncerramento: boolean;
    showDrawerCancelar: boolean;
    showDrawerEmitir: boolean;

    // DADOS DO DRAWER
    selectedRow?: TManifestoCarga;
    dadosCompartilhar: ICompartilhar;

    totaisManifestoCarga: {
        totalQtdCte: number;
        totalQtdNfe: number;
        totalValorCarga: number;
        totalPesoCarga: number;
    };
};
