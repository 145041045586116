import type { AxiosRequestConfig } from 'axios';
import { axiosWithoutToken } from 'std/api/axios';

export async function semTokenGet(url: string, config?: AxiosRequestConfig): Promise<any> {
    return axiosWithoutToken
        .get(url, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}

export async function semTokenPost(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
): Promise<any> {
    return axiosWithoutToken
        .post(url, data, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}

/* export async function semTokenPut(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
): Promise<any> {
    return axiosWithoutToken
        .put(url, data, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}

export async function semTokenRemove(url: string, config?: AxiosRequestConfig): Promise<any> {
    return axiosWithoutToken
        .delete(url, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}
 */
