import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { CondicaoPagamentoState } from './types';

const initialState: CondicaoPagamentoState = {
    get: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,

    // MOSTRAR DRAWERS
    showModalCondicaoPagamento: false,

    // TABELAS
    getTable: {
        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },
};

export const CondicaoPagamentoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<CondicaoPagamentoState>(),
    effects,
});
