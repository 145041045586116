import type { ItemType, MenuItemType } from 'antd/es/menu/interface';
import type { MenuData } from './types';

export function flattenItems(items: MenuData[], key: string): MenuData[] {
    return items.reduce((flattenedItems: MenuData[], item: MenuData) => {
        flattenedItems.push(item);

        if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key));
        }

        return flattenedItems;
    }, []);
}

/** FIX ME */
export function getItemMenu(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: ItemType<MenuItemType>[],
    type?: 'group',
): ItemType<MenuItemType> {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as any;
}
