import { createModel } from '@rematch/core';
import { TipoPessoa } from 'features/pessoa/types';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { ClientesState } from './types';

const initialState: ClientesState = {
    get: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    postClienteUser: initReduxFn,

    clienteSelecionado: null,
    showModal: false,
    showModalMessageError: false,
    shouldSearch: false,
    formEditMode: undefined,
    filterNome: '',
    tipoPessoa: TipoPessoa.Juridica,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: () => '',
        showSizeChanger: false,
    },
    sortParams: {
        shouldSort: false,
        fieldName: '',
        orderDirection: 'asc',
    },
    dataSearchCNPJ: undefined,
};

export const ClientesModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<ClientesState>(),
    effects,
});
