import { createFileRoute, redirect } from '@tanstack/react-router';
import type { VendaTipo } from 'features/venda/types/Venda';
import { type FormEditMode, formEditMode } from 'std/types/enum';

export const Route = createFileRoute('/a/venda/$tipo/$mode/$idpk/')({
    beforeLoad({ params }) {
        const { tipo, mode } = params;

        if (!(tipo.includes(tipo as VendaTipo) && formEditMode.includes(mode as FormEditMode))) {
            throw redirect({ to: '/' });
        }
    },
});
