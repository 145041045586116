import type { ApiFinanceiroContaFintech } from 'features/conta-digital/types/ApiFinanceiroContaFintech';
import { newRequest } from 'std/api/newRequest';

export async function apiFintechContaConsultar(): Promise<ApiFinanceiroContaFintech | null> {
    const request: ApiFinanceiroContaFintech[] = await newRequest({
        type: 'get',
        url: 'Fintech/ContaConsultar',
    });

    return request[0] || null;
}
