import type { TablePaginationConfig } from 'antd';
import type { ColumnType, FilterValue, SortOrder, SorterResult } from 'antd/lib/table/interface';

export type TipoDesconto = '$' | '%';
export type SimNao = 'S' | 'N';
export enum SimNaoNumber {
    S = 1,
    N = 0,
}

/** Indica que o objeto passado vai ser alterado dentro da função, sem clonar */
export type Mutable<T> = T;

/**  Remove o atributo 'readonly' de todas as propriedades de uma interface */
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export type BasicSelectOption = {
    nome: string;
    idpk: number;
};

export type SelectOption = {
    value: number;
    label: string;
};

/** Substitui o evento de scroll por falta de algumas propriedades */
export interface ScrollEvent extends Omit<React.UIEvent<HTMLDivElement>, 'target'> {
    target: {
        scrollTop: number;
        offsetHeight: number;
        scrollHeight: number;
        /* caso precisar adicionar mais propriedades aqui */
    };
}

/** Usado no evento onChange do componente Table do antd */
export type TableOnChange<RecordType> = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
) => void;

/** Paginação do componente Table do antd */
export type TablePagination = {
    current: number;
    total: number;
    pageSize?: number;
    showTotal?: () => string;
    showSizeChanger?: boolean;
};

/** Usado na listagem de tabelas para filtrar pelas colunas */
export type TableSort = {
    sortColumnKey?: React.Key;
    sortOrder?: SortOrder;
};

/** Usado em totalizadores: Contas a receber/pagar, cobrança pix... */
export type ValorTotalItem = {
    status: string;
    valor_total: number;
    quantidade_total: number;
    situacao?: number;
};

/** Propriedade editable está faltando no tipo da coluna do antd */
export interface TableColumn<T> extends ColumnType<T> {
    editable?: boolean;
}

export type ImagemBase64 = {
    imagem_nome: string;
    imagem_base64: string;
};

export type FileBase64 = {
    file_name: string;
    file_base64: string;
};
