import { createModel } from '@rematch/core';
import type { RootModel } from 'models';
import { initReduxFn, setStateReducer } from 'std/redux';
import { effects } from './effects';
import type { RelatoriosState } from './types';

export const initialState: RelatoriosState = {
    // geral
    subtipoRelatorio: '',
    relatorioFoiRequisitado: false,
    impressaoFoiRequisitada: false,

    // filtros obrigatórios
    baseDRE: 2,
    custoBaseDRE: 1,

    // get relatorios fiscais
    getRelatorioNotaFiscalCliente: initReduxFn,
    getRelatorioNotaFiscalProduto: initReduxFn,
    getRelatorioNotaFiscalTipo: initReduxFn,
    getRelatorioNotaFiscalItens: initReduxFn,

    // get relatorios financeiros
    getRelatorioFinanceiroContasReceber: initReduxFn,
    getRelatorioFinanceiroContasPagar: initReduxFn,
    getRelatorioFinanceiroLancamentosReceber: initReduxFn,
    getRelatorioFinanceiroLancamentosPagar: initReduxFn,
    getRelatorioFinanceiroFluxoCaixa: initReduxFn,
    getRelatorioFinanceiroBalancete: initReduxFn,
    getRelatorioFinanceiroEntradaSaidaCaixa: initReduxFn,
    getRelatorioFinanceiroTotaisCategoria: initReduxFn,
    getRelatorioFinanceiroValoresRecebidos: initReduxFn,
    getRelatorioFinanceiroDRE: initReduxFn,
    getRelatorioFinanceiroBoletosRecebidos: initReduxFn,

    // get relatorios de estoque
    getRelatorioEstoqueSaldo: initReduxFn,
    getRelatorioEstoqueMinimo: initReduxFn,

    // get relatorios de vendas
    getRelatorioVendaRelacao: initReduxFn,
    getRelatorioVendaRelacaoResumido: initReduxFn,
    getRelatorioVendaComissao: initReduxFn,
    getRelatorioVendaRelacaoClienteVendedor: initReduxFn,

    // get cadastros
    getRelatorioCadastroRelacaoCliente: initReduxFn,
    getRelatorioCadastroClienteAniversariante: initReduxFn,

    // get compra
    getRelatorioCompraFornecedor: initReduxFn,
};

export const RelatoriosModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<RelatoriosState>(),
    effects,
});
