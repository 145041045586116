import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/p/compartilhamento/$id/')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        tipo?: string;
        registrarVisualizacao?: boolean;
    } => {
        return {
            tipo: String(search?.tipo || ''),
            registrarVisualizacao: search?.registrarVisualizacao === 'True',
        };
    },
});
