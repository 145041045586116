import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

type ConfiguracaoStore = {
    empresaSelectorLayout: 'grade' | 'lista';
    showBannerInstrucoesUso: boolean;
    hasUnsavedData: boolean;
    hideButtonSalvar: boolean;
};

export const useConfiguracaoStore = create<ConfiguracaoStore>()(
    devtools(
        persist(
            (_) => ({
                empresaSelectorLayout: 'lista',
                showBannerInstrucoesUso: true,
                hasUnsavedData: false,
                hideButtonSalvar: false,
            }),
            {
                name: 'configuracao',
                partialize: (state) => ({
                    empresaSelectorLayout: state.empresaSelectorLayout,
                    showBannerInstrucoesUso: state.showBannerInstrucoesUso,
                }),
            },
        ),
        { name: 'configuracao' },
    ),
);
