import { ShoppingFilled } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { Badge, Button } from 'antd';
import { useCatalogoStore } from 'features/catalogo/store';
import { useStateControlCatalogo } from 'pages/catalogo-produtos/useStateControlCatalogo';
import style from './style.module.scss';

export function ShoppingCartButton({ fixed = false }: { fixed?: boolean }) {
    const navigate = useNavigate();

    const produtosCarrinho = useCatalogoStore((s) => s.produtosCarrinho);

    const { catalogoProdutos } = useStateControlCatalogo();
    const { primaryColor, getCatalogoHeaderData } = catalogoProdutos;

    const slugRoute = getCatalogoHeaderData?.data?.empresa.empresa_slug?.ems_slug || '';

    const openShoppingCart = () => {
        navigate({
            to: '/p/catalogo/$slug',
            params: { slug: slugRoute },
            search: { page: 'sacola' },
        });
    };

    return (
        <div className={style['shoppping-cart-btn-container']}>
            <Button
                type='text'
                onClick={openShoppingCart}
                className={fixed ? style.menuBtnFixed : style.menuBtnStatic}
            >
                <Badge
                    count={produtosCarrinho.length}
                    showZero={true}
                    status='success'
                    style={{ fontSize: 10 }}
                >
                    <ShoppingFilled style={{ color: primaryColor }} />
                </Badge>
            </Button>
        </div>
    );
}
