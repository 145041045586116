import { useRef } from 'react';
import { useIsElementInView } from 'std/hooks/useIsElementInView';
import { FixedBar } from './FixedBar';
import { StaticBar } from './StaticBar';

export function TopBar({ proIdpk }: { proIdpk: string | undefined }) {
    const ref = useRef<HTMLDivElement>(null);
    const isStaticBarVisible = useIsElementInView(ref, '0px');

    return (
        <div className='relative'>
            <FixedBar show={!isStaticBarVisible || !!proIdpk} />
            {!proIdpk && <StaticBar ref={ref} />}
        </div>
    );
}
