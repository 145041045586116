/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthRouteImport } from './routes/auth/route'
import { Route as ARouteImport } from './routes/a/route'
import { Route as IndexImport } from './routes/index'
import { Route as AuthRedefinirSenhaImport } from './routes/auth/redefinir-senha'
import { Route as AuthRecuperarSenhaImport } from './routes/auth/recuperar-senha'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthCadastroFimImport } from './routes/auth/cadastro-fim'
import { Route as AuthCadastroImport } from './routes/auth/cadastro'
import { Route as AVendaExternaRouteImport } from './routes/a/venda-externa/route'
import { Route as ARomaneioRouteImport } from './routes/a/romaneio/route'
import { Route as ARegraFiscalRouteImport } from './routes/a/regra-fiscal/route'
import { Route as ANotificacaoRouteImport } from './routes/a/notificacao/route'
import { Route as ANotaFiscalRouteImport } from './routes/a/nota-fiscal/route'
import { Route as AMovimentacoesRouteImport } from './routes/a/movimentacoes/route'
import { Route as AManifestoCargaRouteImport } from './routes/a/manifesto-carga/route'
import { Route as AExtratoPixRouteImport } from './routes/a/extrato-pix/route'
import { Route as AExtratoBoletoRouteImport } from './routes/a/extrato-boleto/route'
import { Route as AEstoqueRouteImport } from './routes/a/estoque/route'
import { Route as ADebugRouteImport } from './routes/a/debug/route'
import { Route as ACupomFiscalRouteImport } from './routes/a/cupom-fiscal/route'
import { Route as AContasReceberRouteImport } from './routes/a/contas-receber/route'
import { Route as AContasPagarRouteImport } from './routes/a/contas-pagar/route'
import { Route as ACompraRouteImport } from './routes/a/compra/route'
import { Route as AComissaoRouteImport } from './routes/a/comissao/route'
import { Route as ACobrancaPixRouteImport } from './routes/a/cobranca-pix/route'
import { Route as ACobrancaCartaoRouteImport } from './routes/a/cobranca-cartao/route'
import { Route as ACobrancaBoletoRouteImport } from './routes/a/cobranca-boleto/route'
import { Route as PErroIndexImport } from './routes/p/erro/index'
import { Route as APdvIndexImport } from './routes/a/pdv/index'
import { Route as AMovimentacoesIndexImport } from './routes/a/movimentacoes/index'
import { Route as ACupomFiscalIndexImport } from './routes/a/cupom-fiscal/index'
import { Route as ACompraIndexImport } from './routes/a/compra/index'
import { Route as ACobrancaPixIndexImport } from './routes/a/cobranca-pix/index'
import { Route as PClienteSlugImport } from './routes/p/cliente/$slug'
import { Route as ADebugPaginationImport } from './routes/a/debug/pagination'
import { Route as ACadastroTransportadorImport } from './routes/a/cadastro/transportador'
import { Route as ACadastroProdutoImport } from './routes/a/cadastro/produto'
import { Route as ACadastroFornecedorImport } from './routes/a/cadastro/fornecedor'
import { Route as ACadastroClienteImport } from './routes/a/cadastro/cliente'
import { Route as AVendaTipoRouteImport } from './routes/a/venda/$tipo/route'
import { Route as AConfiguracaoEmpresaRouteImport } from './routes/a/configuracao/empresa/route'
import { Route as PPagamentoIdIndexImport } from './routes/p/pagamento.$id/index'
import { Route as PCompartilhamentoIdIndexImport } from './routes/p/compartilhamento/$id.index'
import { Route as PCatalogoSlugIndexImport } from './routes/p/catalogo.$slug/index'
import { Route as ARelatorioTipoIndexImport } from './routes/a/relatorio/$tipo/index'
import { Route as AConfiguracaoEmpresaIndexImport } from './routes/a/configuracao/empresa/index'
import { Route as PContaDigitalTermosDeUsoBase64Import } from './routes/p/conta-digital/termos-de-uso.$base64'
import { Route as AImportacaoXmlTipoRouteImport } from './routes/a/importacao/xml.$tipo.route'
import { Route as AImportacaoCadastroTipoRouteImport } from './routes/a/importacao/cadastro.$tipo.route'
import { Route as ARomaneioModeIdpkIndexImport } from './routes/a/romaneio/$mode.$idpk/index'
import { Route as AContratoModeIdpkIndexImport } from './routes/a/contrato/$mode.$idpk/index'
import { Route as PCatalogoSlugProdutoIdpkImport } from './routes/p/catalogo.$slug/produto.$idpk'
import { Route as AVendaTipoModeIdpkIndexImport } from './routes/a/venda/$tipo/$mode.$idpk/index'
import { Route as AVendaVendaExternaTipoModeIdpkIndexImport } from './routes/a/venda/venda-externa.$tipo.$mode.$idpk/index'

// Create Virtual Routes

const AuthSolicitarValidacaoLazyImport = createFileRoute(
  '/auth/solicitar-validacao',
)()
const AuthContaBloqueadaLazyImport = createFileRoute('/auth/conta-bloqueada')()
const AuthAcessoNegadoLazyImport = createFileRoute('/auth/acesso-negado')()
const AVendaExternaIndexLazyImport = createFileRoute('/a/venda-externa/')()
const ASuporteIndexLazyImport = createFileRoute('/a/suporte/')()
const ARomaneioIndexLazyImport = createFileRoute('/a/romaneio/')()
const ARegraFiscalIndexLazyImport = createFileRoute('/a/regra-fiscal/')()
const APainelGerencialIndexLazyImport = createFileRoute(
  '/a/painel-gerencial/',
)()
const ANotaFiscalIndexLazyImport = createFileRoute('/a/nota-fiscal/')()
const AManifestoCargaIndexLazyImport = createFileRoute('/a/manifesto-carga/')()
const AExtratoPixIndexLazyImport = createFileRoute('/a/extrato-pix/')()
const AExtratoBoletoIndexLazyImport = createFileRoute('/a/extrato-boleto/')()
const ADebugIndexLazyImport = createFileRoute('/a/debug/')()
const AContratoIndexLazyImport = createFileRoute('/a/contrato/')()
const AContasReceberIndexLazyImport = createFileRoute('/a/contas-receber/')()
const AContasPagarIndexLazyImport = createFileRoute('/a/contas-pagar/')()
const AComissaoIndexLazyImport = createFileRoute('/a/comissao/')()
const ACobrancaCartaoIndexLazyImport = createFileRoute('/a/cobranca-cartao/')()
const ACobrancaBoletoIndexLazyImport = createFileRoute('/a/cobranca-boleto/')()
const PImpressaoFiletypeLazyImport = createFileRoute('/p/impressao/$filetype')()
const PContratoIdLazyImport = createFileRoute('/p/contrato/$id')()
const PCompartilhamentoIdLazyImport = createFileRoute(
  '/p/compartilhamento/$id',
)()
const ASuporteInstrucoesDeUsoLazyImport = createFileRoute(
  '/a/suporte/instrucoes-de-uso',
)()
const ANotificacaoListagemLazyImport = createFileRoute(
  '/a/notificacao/listagem',
)()
const AImpressaoFiletypeLazyImport = createFileRoute('/a/impressao/$filetype')()
const AEstoqueMovimentacaoLazyImport = createFileRoute(
  '/a/estoque/movimentacao',
)()
const AEstoqueConferenciaLazyImport = createFileRoute(
  '/a/estoque/conferencia',
)()
const ACobrancaBoletoRotinasBancariasLazyImport = createFileRoute(
  '/a/cobranca-boleto/rotinas-bancarias',
)()
const AVendaTipoIndexLazyImport = createFileRoute('/a/venda/$tipo/')()
const AConfiguracaoUsuarioIndexLazyImport = createFileRoute(
  '/a/configuracao/usuario/',
)()
const AVendaExternaModeIdpkLazyImport = createFileRoute(
  '/a/venda-externa/$mode/$idpk',
)()
const ANotaFiscalModeIdpkLazyImport = createFileRoute(
  '/a/nota-fiscal/$mode/$idpk',
)()
const AMovimentacoesExtratoIdpkLazyImport = createFileRoute(
  '/a/movimentacoes/extrato/$idpk',
)()
const AManifestoCargaModeIdpkLazyImport = createFileRoute(
  '/a/manifesto-carga/$mode/$idpk',
)()
const AContasReceberModeIdpkLazyImport = createFileRoute(
  '/a/contas-receber/$mode/$idpk',
)()
const AContasPagarModeIdpkLazyImport = createFileRoute(
  '/a/contas-pagar/$mode/$idpk',
)()
const ACompraModeIdpkLazyImport = createFileRoute('/a/compra/$mode/$idpk')()
const ACobrancaCartaoModeIdpkLazyImport = createFileRoute(
  '/a/cobranca-cartao/$mode/$idpk',
)()
const ACobrancaBoletoModeIdpkLazyImport = createFileRoute(
  '/a/cobranca-boleto/$mode/$idpk',
)()
const AMovimentacoesModeContaIdpkIdpkLazyImport = createFileRoute(
  '/a/movimentacoes/$mode/$contaIdpk/$idpk',
)()
const ACadastroProdutoModeIdpkLazyImport = createFileRoute(
  '/a/cadastro/produto/$mode/$idpk',
)()
const ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyImport =
  createFileRoute(
    '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk',
  )()

// Create/Update Routes

const AuthRouteRoute = AuthRouteImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const ARouteRoute = ARouteImport.update({
  id: '/a',
  path: '/a',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthSolicitarValidacaoLazyRoute = AuthSolicitarValidacaoLazyImport.update(
  {
    id: '/solicitar-validacao',
    path: '/solicitar-validacao',
    getParentRoute: () => AuthRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/auth/solicitar-validacao.lazy').then((d) => d.Route),
)

const AuthContaBloqueadaLazyRoute = AuthContaBloqueadaLazyImport.update({
  id: '/conta-bloqueada',
  path: '/conta-bloqueada',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import('./routes/auth/conta-bloqueada.lazy').then((d) => d.Route),
)

const AuthAcessoNegadoLazyRoute = AuthAcessoNegadoLazyImport.update({
  id: '/acesso-negado',
  path: '/acesso-negado',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import('./routes/auth/acesso-negado.lazy').then((d) => d.Route),
)

const AuthRedefinirSenhaRoute = AuthRedefinirSenhaImport.update({
  id: '/redefinir-senha',
  path: '/redefinir-senha',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import('./routes/auth/redefinir-senha.lazy').then((d) => d.Route),
)

const AuthRecuperarSenhaRoute = AuthRecuperarSenhaImport.update({
  id: '/recuperar-senha',
  path: '/recuperar-senha',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import('./routes/auth/recuperar-senha.lazy').then((d) => d.Route),
)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() => import('./routes/auth/login.lazy').then((d) => d.Route))

const AuthCadastroFimRoute = AuthCadastroFimImport.update({
  id: '/cadastro-fim',
  path: '/cadastro-fim',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import('./routes/auth/cadastro-fim.lazy').then((d) => d.Route),
)

const AuthCadastroRoute = AuthCadastroImport.update({
  id: '/cadastro',
  path: '/cadastro',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() => import('./routes/auth/cadastro.lazy').then((d) => d.Route))

const AVendaExternaRouteRoute = AVendaExternaRouteImport.update({
  id: '/venda-externa',
  path: '/venda-externa',
  getParentRoute: () => ARouteRoute,
} as any)

const ARomaneioRouteRoute = ARomaneioRouteImport.update({
  id: '/romaneio',
  path: '/romaneio',
  getParentRoute: () => ARouteRoute,
} as any)

const ARegraFiscalRouteRoute = ARegraFiscalRouteImport.update({
  id: '/regra-fiscal',
  path: '/regra-fiscal',
  getParentRoute: () => ARouteRoute,
} as any)

const ANotificacaoRouteRoute = ANotificacaoRouteImport.update({
  id: '/notificacao',
  path: '/notificacao',
  getParentRoute: () => ARouteRoute,
} as any)

const ANotaFiscalRouteRoute = ANotaFiscalRouteImport.update({
  id: '/nota-fiscal',
  path: '/nota-fiscal',
  getParentRoute: () => ARouteRoute,
} as any)

const AMovimentacoesRouteRoute = AMovimentacoesRouteImport.update({
  id: '/movimentacoes',
  path: '/movimentacoes',
  getParentRoute: () => ARouteRoute,
} as any)

const AManifestoCargaRouteRoute = AManifestoCargaRouteImport.update({
  id: '/manifesto-carga',
  path: '/manifesto-carga',
  getParentRoute: () => ARouteRoute,
} as any)

const AExtratoPixRouteRoute = AExtratoPixRouteImport.update({
  id: '/extrato-pix',
  path: '/extrato-pix',
  getParentRoute: () => ARouteRoute,
} as any)

const AExtratoBoletoRouteRoute = AExtratoBoletoRouteImport.update({
  id: '/extrato-boleto',
  path: '/extrato-boleto',
  getParentRoute: () => ARouteRoute,
} as any)

const AEstoqueRouteRoute = AEstoqueRouteImport.update({
  id: '/estoque',
  path: '/estoque',
  getParentRoute: () => ARouteRoute,
} as any)

const ADebugRouteRoute = ADebugRouteImport.update({
  id: '/debug',
  path: '/debug',
  getParentRoute: () => ARouteRoute,
} as any)

const ACupomFiscalRouteRoute = ACupomFiscalRouteImport.update({
  id: '/cupom-fiscal',
  path: '/cupom-fiscal',
  getParentRoute: () => ARouteRoute,
} as any)

const AContasReceberRouteRoute = AContasReceberRouteImport.update({
  id: '/contas-receber',
  path: '/contas-receber',
  getParentRoute: () => ARouteRoute,
} as any)

const AContasPagarRouteRoute = AContasPagarRouteImport.update({
  id: '/contas-pagar',
  path: '/contas-pagar',
  getParentRoute: () => ARouteRoute,
} as any)

const ACompraRouteRoute = ACompraRouteImport.update({
  id: '/compra',
  path: '/compra',
  getParentRoute: () => ARouteRoute,
} as any)

const AComissaoRouteRoute = AComissaoRouteImport.update({
  id: '/comissao',
  path: '/comissao',
  getParentRoute: () => ARouteRoute,
} as any)

const ACobrancaPixRouteRoute = ACobrancaPixRouteImport.update({
  id: '/cobranca-pix',
  path: '/cobranca-pix',
  getParentRoute: () => ARouteRoute,
} as any)

const ACobrancaCartaoRouteRoute = ACobrancaCartaoRouteImport.update({
  id: '/cobranca-cartao',
  path: '/cobranca-cartao',
  getParentRoute: () => ARouteRoute,
} as any)

const ACobrancaBoletoRouteRoute = ACobrancaBoletoRouteImport.update({
  id: '/cobranca-boleto',
  path: '/cobranca-boleto',
  getParentRoute: () => ARouteRoute,
} as any)

const AVendaExternaIndexLazyRoute = AVendaExternaIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AVendaExternaRouteRoute,
} as any).lazy(() =>
  import('./routes/a/venda-externa/index.lazy').then((d) => d.Route),
)

const ASuporteIndexLazyRoute = ASuporteIndexLazyImport.update({
  id: '/suporte/',
  path: '/suporte/',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/suporte/index.lazy').then((d) => d.Route),
)

const ARomaneioIndexLazyRoute = ARomaneioIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ARomaneioRouteRoute,
} as any).lazy(() =>
  import('./routes/a/romaneio/index.lazy').then((d) => d.Route),
)

const ARegraFiscalIndexLazyRoute = ARegraFiscalIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ARegraFiscalRouteRoute,
} as any).lazy(() =>
  import('./routes/a/regra-fiscal/index.lazy').then((d) => d.Route),
)

const APainelGerencialIndexLazyRoute = APainelGerencialIndexLazyImport.update({
  id: '/painel-gerencial/',
  path: '/painel-gerencial/',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/painel-gerencial/index.lazy').then((d) => d.Route),
)

const ANotaFiscalIndexLazyRoute = ANotaFiscalIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ANotaFiscalRouteRoute,
} as any).lazy(() =>
  import('./routes/a/nota-fiscal/index.lazy').then((d) => d.Route),
)

const AManifestoCargaIndexLazyRoute = AManifestoCargaIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AManifestoCargaRouteRoute,
} as any).lazy(() =>
  import('./routes/a/manifesto-carga/index.lazy').then((d) => d.Route),
)

const AExtratoPixIndexLazyRoute = AExtratoPixIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AExtratoPixRouteRoute,
} as any).lazy(() =>
  import('./routes/a/extrato-pix/index.lazy').then((d) => d.Route),
)

const AExtratoBoletoIndexLazyRoute = AExtratoBoletoIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AExtratoBoletoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/extrato-boleto/index.lazy').then((d) => d.Route),
)

const ADebugIndexLazyRoute = ADebugIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ADebugRouteRoute,
} as any).lazy(() => import('./routes/a/debug/index.lazy').then((d) => d.Route))

const AContratoIndexLazyRoute = AContratoIndexLazyImport.update({
  id: '/contrato/',
  path: '/contrato/',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/contrato/index.lazy').then((d) => d.Route),
)

const AContasReceberIndexLazyRoute = AContasReceberIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AContasReceberRouteRoute,
} as any).lazy(() =>
  import('./routes/a/contas-receber/index.lazy').then((d) => d.Route),
)

const AContasPagarIndexLazyRoute = AContasPagarIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AContasPagarRouteRoute,
} as any).lazy(() =>
  import('./routes/a/contas-pagar/index.lazy').then((d) => d.Route),
)

const AComissaoIndexLazyRoute = AComissaoIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AComissaoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/comissao/index.lazy').then((d) => d.Route),
)

const ACobrancaCartaoIndexLazyRoute = ACobrancaCartaoIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACobrancaCartaoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/cobranca-cartao/index.lazy').then((d) => d.Route),
)

const ACobrancaBoletoIndexLazyRoute = ACobrancaBoletoIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACobrancaBoletoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/cobranca-boleto/index.lazy').then((d) => d.Route),
)

const PErroIndexRoute = PErroIndexImport.update({
  id: '/p/erro/',
  path: '/p/erro/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/p/erro/index.lazy').then((d) => d.Route))

const APdvIndexRoute = APdvIndexImport.update({
  id: '/pdv/',
  path: '/pdv/',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() => import('./routes/a/pdv/index.lazy').then((d) => d.Route))

const AMovimentacoesIndexRoute = AMovimentacoesIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AMovimentacoesRouteRoute,
} as any).lazy(() =>
  import('./routes/a/movimentacoes/index.lazy').then((d) => d.Route),
)

const ACupomFiscalIndexRoute = ACupomFiscalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACupomFiscalRouteRoute,
} as any).lazy(() =>
  import('./routes/a/cupom-fiscal/index.lazy').then((d) => d.Route),
)

const ACompraIndexRoute = ACompraIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACompraRouteRoute,
} as any).lazy(() =>
  import('./routes/a/compra/index.lazy').then((d) => d.Route),
)

const ACobrancaPixIndexRoute = ACobrancaPixIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACobrancaPixRouteRoute,
} as any).lazy(() =>
  import('./routes/a/cobranca-pix/index.lazy').then((d) => d.Route),
)

const PImpressaoFiletypeLazyRoute = PImpressaoFiletypeLazyImport.update({
  id: '/p/impressao/$filetype',
  path: '/p/impressao/$filetype',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/p/impressao.$filetype.lazy').then((d) => d.Route),
)

const PContratoIdLazyRoute = PContratoIdLazyImport.update({
  id: '/p/contrato/$id',
  path: '/p/contrato/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/p/contrato.$id.lazy').then((d) => d.Route),
)

const PCompartilhamentoIdLazyRoute = PCompartilhamentoIdLazyImport.update({
  id: '/p/compartilhamento/$id',
  path: '/p/compartilhamento/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/p/compartilhamento/$id.lazy').then((d) => d.Route),
)

const ASuporteInstrucoesDeUsoLazyRoute =
  ASuporteInstrucoesDeUsoLazyImport.update({
    id: '/suporte/instrucoes-de-uso',
    path: '/suporte/instrucoes-de-uso',
    getParentRoute: () => ARouteRoute,
  } as any).lazy(() =>
    import('./routes/a/suporte/instrucoes-de-uso.lazy').then((d) => d.Route),
  )

const ANotificacaoListagemLazyRoute = ANotificacaoListagemLazyImport.update({
  id: '/listagem',
  path: '/listagem',
  getParentRoute: () => ANotificacaoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/notificacao/listagem.lazy').then((d) => d.Route),
)

const AImpressaoFiletypeLazyRoute = AImpressaoFiletypeLazyImport.update({
  id: '/impressao/$filetype',
  path: '/impressao/$filetype',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/impressao/$filetype.lazy').then((d) => d.Route),
)

const AEstoqueMovimentacaoLazyRoute = AEstoqueMovimentacaoLazyImport.update({
  id: '/movimentacao',
  path: '/movimentacao',
  getParentRoute: () => AEstoqueRouteRoute,
} as any).lazy(() =>
  import('./routes/a/estoque/movimentacao.lazy').then((d) => d.Route),
)

const AEstoqueConferenciaLazyRoute = AEstoqueConferenciaLazyImport.update({
  id: '/conferencia',
  path: '/conferencia',
  getParentRoute: () => AEstoqueRouteRoute,
} as any).lazy(() =>
  import('./routes/a/estoque/conferencia.lazy').then((d) => d.Route),
)

const ACobrancaBoletoRotinasBancariasLazyRoute =
  ACobrancaBoletoRotinasBancariasLazyImport.update({
    id: '/rotinas-bancarias',
    path: '/rotinas-bancarias',
    getParentRoute: () => ACobrancaBoletoRouteRoute,
  } as any).lazy(() =>
    import('./routes/a/cobranca-boleto/rotinas-bancarias.lazy').then(
      (d) => d.Route,
    ),
  )

const PClienteSlugRoute = PClienteSlugImport.update({
  id: '/p/cliente/$slug',
  path: '/p/cliente/$slug',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/p/cliente/$slug.lazy').then((d) => d.Route),
)

const ADebugPaginationRoute = ADebugPaginationImport.update({
  id: '/pagination',
  path: '/pagination',
  getParentRoute: () => ADebugRouteRoute,
} as any)

const ACadastroTransportadorRoute = ACadastroTransportadorImport.update({
  id: '/cadastro/transportador',
  path: '/cadastro/transportador',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/cadastro/transportador.lazy').then((d) => d.Route),
)

const ACadastroProdutoRoute = ACadastroProdutoImport.update({
  id: '/cadastro/produto',
  path: '/cadastro/produto',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/cadastro/produto.lazy').then((d) => d.Route),
)

const ACadastroFornecedorRoute = ACadastroFornecedorImport.update({
  id: '/cadastro/fornecedor',
  path: '/cadastro/fornecedor',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/cadastro/fornecedor.lazy').then((d) => d.Route),
)

const ACadastroClienteRoute = ACadastroClienteImport.update({
  id: '/cadastro/cliente',
  path: '/cadastro/cliente',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/cadastro/cliente.lazy').then((d) => d.Route),
)

const AVendaTipoRouteRoute = AVendaTipoRouteImport.update({
  id: '/venda/$tipo',
  path: '/venda/$tipo',
  getParentRoute: () => ARouteRoute,
} as any)

const AConfiguracaoEmpresaRouteRoute = AConfiguracaoEmpresaRouteImport.update({
  id: '/configuracao/empresa',
  path: '/configuracao/empresa',
  getParentRoute: () => ARouteRoute,
} as any)

const AVendaTipoIndexLazyRoute = AVendaTipoIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AVendaTipoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/venda/$tipo/index.lazy').then((d) => d.Route),
)

const AConfiguracaoUsuarioIndexLazyRoute =
  AConfiguracaoUsuarioIndexLazyImport.update({
    id: '/configuracao/usuario/',
    path: '/configuracao/usuario/',
    getParentRoute: () => ARouteRoute,
  } as any).lazy(() =>
    import('./routes/a/configuracao/usuario/index.lazy').then((d) => d.Route),
  )

const PPagamentoIdIndexRoute = PPagamentoIdIndexImport.update({
  id: '/p/pagamento/$id/',
  path: '/p/pagamento/$id/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/p/pagamento.$id/index.lazy').then((d) => d.Route),
)

const PCompartilhamentoIdIndexRoute = PCompartilhamentoIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PCompartilhamentoIdLazyRoute,
} as any)

const PCatalogoSlugIndexRoute = PCatalogoSlugIndexImport.update({
  id: '/p/catalogo/$slug/',
  path: '/p/catalogo/$slug/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/p/catalogo.$slug/index.lazy').then((d) => d.Route),
)

const ARelatorioTipoIndexRoute = ARelatorioTipoIndexImport.update({
  id: '/relatorio/$tipo/',
  path: '/relatorio/$tipo/',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/relatorio/$tipo/index.lazy').then((d) => d.Route),
)

const AConfiguracaoEmpresaIndexRoute = AConfiguracaoEmpresaIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AConfiguracaoEmpresaRouteRoute,
} as any).lazy(() =>
  import('./routes/a/configuracao/empresa/index.lazy').then((d) => d.Route),
)

const AVendaExternaModeIdpkLazyRoute = AVendaExternaModeIdpkLazyImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AVendaExternaRouteRoute,
} as any).lazy(() =>
  import('./routes/a/venda-externa/$mode.$idpk.lazy').then((d) => d.Route),
)

const ANotaFiscalModeIdpkLazyRoute = ANotaFiscalModeIdpkLazyImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ANotaFiscalRouteRoute,
} as any).lazy(() =>
  import('./routes/a/nota-fiscal/$mode.$idpk.lazy').then((d) => d.Route),
)

const AMovimentacoesExtratoIdpkLazyRoute =
  AMovimentacoesExtratoIdpkLazyImport.update({
    id: '/extrato/$idpk',
    path: '/extrato/$idpk',
    getParentRoute: () => AMovimentacoesRouteRoute,
  } as any).lazy(() =>
    import('./routes/a/movimentacoes/extrato.$idpk.lazy').then((d) => d.Route),
  )

const AManifestoCargaModeIdpkLazyRoute =
  AManifestoCargaModeIdpkLazyImport.update({
    id: '/$mode/$idpk',
    path: '/$mode/$idpk',
    getParentRoute: () => AManifestoCargaRouteRoute,
  } as any).lazy(() =>
    import('./routes/a/manifesto-carga/$mode.$idpk.lazy').then((d) => d.Route),
  )

const AContasReceberModeIdpkLazyRoute = AContasReceberModeIdpkLazyImport.update(
  {
    id: '/$mode/$idpk',
    path: '/$mode/$idpk',
    getParentRoute: () => AContasReceberRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/a/contas-receber/$mode.$idpk.lazy').then((d) => d.Route),
)

const AContasPagarModeIdpkLazyRoute = AContasPagarModeIdpkLazyImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AContasPagarRouteRoute,
} as any).lazy(() =>
  import('./routes/a/contas-pagar/$mode.$idpk.lazy').then((d) => d.Route),
)

const ACompraModeIdpkLazyRoute = ACompraModeIdpkLazyImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ACompraRouteRoute,
} as any).lazy(() =>
  import('./routes/a/compra/$mode.$idpk.lazy').then((d) => d.Route),
)

const ACobrancaCartaoModeIdpkLazyRoute =
  ACobrancaCartaoModeIdpkLazyImport.update({
    id: '/$mode/$idpk',
    path: '/$mode/$idpk',
    getParentRoute: () => ACobrancaCartaoRouteRoute,
  } as any).lazy(() =>
    import('./routes/a/cobranca-cartao/$mode.$idpk.lazy').then((d) => d.Route),
  )

const ACobrancaBoletoModeIdpkLazyRoute =
  ACobrancaBoletoModeIdpkLazyImport.update({
    id: '/$mode/$idpk',
    path: '/$mode/$idpk',
    getParentRoute: () => ACobrancaBoletoRouteRoute,
  } as any).lazy(() =>
    import('./routes/a/cobranca-boleto/$mode.$idpk.lazy').then((d) => d.Route),
  )

const PContaDigitalTermosDeUsoBase64Route =
  PContaDigitalTermosDeUsoBase64Import.update({
    id: '/p/conta-digital/termos-de-uso/$base64',
    path: '/p/conta-digital/termos-de-uso/$base64',
    getParentRoute: () => rootRoute,
  } as any)

const AImportacaoXmlTipoRouteRoute = AImportacaoXmlTipoRouteImport.update({
  id: '/importacao/xml/$tipo',
  path: '/importacao/xml/$tipo',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/importacao/xml.$tipo.lazy').then((d) => d.Route),
)

const AImportacaoCadastroTipoRouteRoute =
  AImportacaoCadastroTipoRouteImport.update({
    id: '/importacao/cadastro/$tipo',
    path: '/importacao/cadastro/$tipo',
    getParentRoute: () => ARouteRoute,
  } as any).lazy(() =>
    import('./routes/a/importacao/cadastro.$tipo.lazy').then((d) => d.Route),
  )

const ARomaneioModeIdpkIndexRoute = ARomaneioModeIdpkIndexImport.update({
  id: '/$mode/$idpk/',
  path: '/$mode/$idpk/',
  getParentRoute: () => ARomaneioRouteRoute,
} as any).lazy(() =>
  import('./routes/a/romaneio/$mode.$idpk/index.lazy').then((d) => d.Route),
)

const AContratoModeIdpkIndexRoute = AContratoModeIdpkIndexImport.update({
  id: '/contrato/$mode/$idpk/',
  path: '/contrato/$mode/$idpk/',
  getParentRoute: () => ARouteRoute,
} as any).lazy(() =>
  import('./routes/a/contrato/$mode.$idpk/index.lazy').then((d) => d.Route),
)

const AMovimentacoesModeContaIdpkIdpkLazyRoute =
  AMovimentacoesModeContaIdpkIdpkLazyImport.update({
    id: '/$mode/$contaIdpk/$idpk',
    path: '/$mode/$contaIdpk/$idpk',
    getParentRoute: () => AMovimentacoesRouteRoute,
  } as any).lazy(() =>
    import('./routes/a/movimentacoes/$mode.$contaIdpk.$idpk.lazy').then(
      (d) => d.Route,
    ),
  )

const ACadastroProdutoModeIdpkLazyRoute =
  ACadastroProdutoModeIdpkLazyImport.update({
    id: '/$mode/$idpk',
    path: '/$mode/$idpk',
    getParentRoute: () => ACadastroProdutoRoute,
  } as any).lazy(() =>
    import('./routes/a/cadastro/produto.$mode.$idpk.lazy').then((d) => d.Route),
  )

const PCatalogoSlugProdutoIdpkRoute = PCatalogoSlugProdutoIdpkImport.update({
  id: '/p/catalogo/$slug/produto/$idpk',
  path: '/p/catalogo/$slug/produto/$idpk',
  getParentRoute: () => rootRoute,
} as any)

const AVendaTipoModeIdpkIndexRoute = AVendaTipoModeIdpkIndexImport.update({
  id: '/$mode/$idpk/',
  path: '/$mode/$idpk/',
  getParentRoute: () => AVendaTipoRouteRoute,
} as any).lazy(() =>
  import('./routes/a/venda/$tipo/$mode.$idpk/index.lazy').then((d) => d.Route),
)

const ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute =
  ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyImport.update({
    id: '/venda-externa/$tipoVendaExterna/$mode/$veeIdpk',
    path: '/venda-externa/$tipoVendaExterna/$mode/$veeIdpk',
    getParentRoute: () => ANotaFiscalRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/a/nota-fiscal/venda-externa/$tipoVendaExterna.$mode.$veeIdpk.lazy'
    ).then((d) => d.Route),
  )

const AVendaVendaExternaTipoModeIdpkIndexRoute =
  AVendaVendaExternaTipoModeIdpkIndexImport.update({
    id: '/venda/venda-externa/$tipo/$mode/$idpk/',
    path: '/venda/venda-externa/$tipo/$mode/$idpk/',
    getParentRoute: () => ARouteRoute,
  } as any).lazy(() =>
    import('./routes/a/venda/venda-externa.$tipo.$mode.$idpk/index.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/a': {
      id: '/a'
      path: '/a'
      fullPath: '/a'
      preLoaderRoute: typeof ARouteImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/a/cobranca-boleto': {
      id: '/a/cobranca-boleto'
      path: '/cobranca-boleto'
      fullPath: '/a/cobranca-boleto'
      preLoaderRoute: typeof ACobrancaBoletoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/cobranca-cartao': {
      id: '/a/cobranca-cartao'
      path: '/cobranca-cartao'
      fullPath: '/a/cobranca-cartao'
      preLoaderRoute: typeof ACobrancaCartaoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/cobranca-pix': {
      id: '/a/cobranca-pix'
      path: '/cobranca-pix'
      fullPath: '/a/cobranca-pix'
      preLoaderRoute: typeof ACobrancaPixRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/comissao': {
      id: '/a/comissao'
      path: '/comissao'
      fullPath: '/a/comissao'
      preLoaderRoute: typeof AComissaoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/compra': {
      id: '/a/compra'
      path: '/compra'
      fullPath: '/a/compra'
      preLoaderRoute: typeof ACompraRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/contas-pagar': {
      id: '/a/contas-pagar'
      path: '/contas-pagar'
      fullPath: '/a/contas-pagar'
      preLoaderRoute: typeof AContasPagarRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/contas-receber': {
      id: '/a/contas-receber'
      path: '/contas-receber'
      fullPath: '/a/contas-receber'
      preLoaderRoute: typeof AContasReceberRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/cupom-fiscal': {
      id: '/a/cupom-fiscal'
      path: '/cupom-fiscal'
      fullPath: '/a/cupom-fiscal'
      preLoaderRoute: typeof ACupomFiscalRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/debug': {
      id: '/a/debug'
      path: '/debug'
      fullPath: '/a/debug'
      preLoaderRoute: typeof ADebugRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/estoque': {
      id: '/a/estoque'
      path: '/estoque'
      fullPath: '/a/estoque'
      preLoaderRoute: typeof AEstoqueRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/extrato-boleto': {
      id: '/a/extrato-boleto'
      path: '/extrato-boleto'
      fullPath: '/a/extrato-boleto'
      preLoaderRoute: typeof AExtratoBoletoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/extrato-pix': {
      id: '/a/extrato-pix'
      path: '/extrato-pix'
      fullPath: '/a/extrato-pix'
      preLoaderRoute: typeof AExtratoPixRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/manifesto-carga': {
      id: '/a/manifesto-carga'
      path: '/manifesto-carga'
      fullPath: '/a/manifesto-carga'
      preLoaderRoute: typeof AManifestoCargaRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/movimentacoes': {
      id: '/a/movimentacoes'
      path: '/movimentacoes'
      fullPath: '/a/movimentacoes'
      preLoaderRoute: typeof AMovimentacoesRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/nota-fiscal': {
      id: '/a/nota-fiscal'
      path: '/nota-fiscal'
      fullPath: '/a/nota-fiscal'
      preLoaderRoute: typeof ANotaFiscalRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/notificacao': {
      id: '/a/notificacao'
      path: '/notificacao'
      fullPath: '/a/notificacao'
      preLoaderRoute: typeof ANotificacaoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/regra-fiscal': {
      id: '/a/regra-fiscal'
      path: '/regra-fiscal'
      fullPath: '/a/regra-fiscal'
      preLoaderRoute: typeof ARegraFiscalRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/romaneio': {
      id: '/a/romaneio'
      path: '/romaneio'
      fullPath: '/a/romaneio'
      preLoaderRoute: typeof ARomaneioRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/venda-externa': {
      id: '/a/venda-externa'
      path: '/venda-externa'
      fullPath: '/a/venda-externa'
      preLoaderRoute: typeof AVendaExternaRouteImport
      parentRoute: typeof ARouteImport
    }
    '/auth/cadastro': {
      id: '/auth/cadastro'
      path: '/cadastro'
      fullPath: '/auth/cadastro'
      preLoaderRoute: typeof AuthCadastroImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/cadastro-fim': {
      id: '/auth/cadastro-fim'
      path: '/cadastro-fim'
      fullPath: '/auth/cadastro-fim'
      preLoaderRoute: typeof AuthCadastroFimImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/recuperar-senha': {
      id: '/auth/recuperar-senha'
      path: '/recuperar-senha'
      fullPath: '/auth/recuperar-senha'
      preLoaderRoute: typeof AuthRecuperarSenhaImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/redefinir-senha': {
      id: '/auth/redefinir-senha'
      path: '/redefinir-senha'
      fullPath: '/auth/redefinir-senha'
      preLoaderRoute: typeof AuthRedefinirSenhaImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/acesso-negado': {
      id: '/auth/acesso-negado'
      path: '/acesso-negado'
      fullPath: '/auth/acesso-negado'
      preLoaderRoute: typeof AuthAcessoNegadoLazyImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/conta-bloqueada': {
      id: '/auth/conta-bloqueada'
      path: '/conta-bloqueada'
      fullPath: '/auth/conta-bloqueada'
      preLoaderRoute: typeof AuthContaBloqueadaLazyImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/solicitar-validacao': {
      id: '/auth/solicitar-validacao'
      path: '/solicitar-validacao'
      fullPath: '/auth/solicitar-validacao'
      preLoaderRoute: typeof AuthSolicitarValidacaoLazyImport
      parentRoute: typeof AuthRouteImport
    }
    '/a/configuracao/empresa': {
      id: '/a/configuracao/empresa'
      path: '/configuracao/empresa'
      fullPath: '/a/configuracao/empresa'
      preLoaderRoute: typeof AConfiguracaoEmpresaRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/venda/$tipo': {
      id: '/a/venda/$tipo'
      path: '/venda/$tipo'
      fullPath: '/a/venda/$tipo'
      preLoaderRoute: typeof AVendaTipoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/cadastro/cliente': {
      id: '/a/cadastro/cliente'
      path: '/cadastro/cliente'
      fullPath: '/a/cadastro/cliente'
      preLoaderRoute: typeof ACadastroClienteImport
      parentRoute: typeof ARouteImport
    }
    '/a/cadastro/fornecedor': {
      id: '/a/cadastro/fornecedor'
      path: '/cadastro/fornecedor'
      fullPath: '/a/cadastro/fornecedor'
      preLoaderRoute: typeof ACadastroFornecedorImport
      parentRoute: typeof ARouteImport
    }
    '/a/cadastro/produto': {
      id: '/a/cadastro/produto'
      path: '/cadastro/produto'
      fullPath: '/a/cadastro/produto'
      preLoaderRoute: typeof ACadastroProdutoImport
      parentRoute: typeof ARouteImport
    }
    '/a/cadastro/transportador': {
      id: '/a/cadastro/transportador'
      path: '/cadastro/transportador'
      fullPath: '/a/cadastro/transportador'
      preLoaderRoute: typeof ACadastroTransportadorImport
      parentRoute: typeof ARouteImport
    }
    '/a/debug/pagination': {
      id: '/a/debug/pagination'
      path: '/pagination'
      fullPath: '/a/debug/pagination'
      preLoaderRoute: typeof ADebugPaginationImport
      parentRoute: typeof ADebugRouteImport
    }
    '/p/cliente/$slug': {
      id: '/p/cliente/$slug'
      path: '/p/cliente/$slug'
      fullPath: '/p/cliente/$slug'
      preLoaderRoute: typeof PClienteSlugImport
      parentRoute: typeof rootRoute
    }
    '/a/cobranca-boleto/rotinas-bancarias': {
      id: '/a/cobranca-boleto/rotinas-bancarias'
      path: '/rotinas-bancarias'
      fullPath: '/a/cobranca-boleto/rotinas-bancarias'
      preLoaderRoute: typeof ACobrancaBoletoRotinasBancariasLazyImport
      parentRoute: typeof ACobrancaBoletoRouteImport
    }
    '/a/estoque/conferencia': {
      id: '/a/estoque/conferencia'
      path: '/conferencia'
      fullPath: '/a/estoque/conferencia'
      preLoaderRoute: typeof AEstoqueConferenciaLazyImport
      parentRoute: typeof AEstoqueRouteImport
    }
    '/a/estoque/movimentacao': {
      id: '/a/estoque/movimentacao'
      path: '/movimentacao'
      fullPath: '/a/estoque/movimentacao'
      preLoaderRoute: typeof AEstoqueMovimentacaoLazyImport
      parentRoute: typeof AEstoqueRouteImport
    }
    '/a/impressao/$filetype': {
      id: '/a/impressao/$filetype'
      path: '/impressao/$filetype'
      fullPath: '/a/impressao/$filetype'
      preLoaderRoute: typeof AImpressaoFiletypeLazyImport
      parentRoute: typeof ARouteImport
    }
    '/a/notificacao/listagem': {
      id: '/a/notificacao/listagem'
      path: '/listagem'
      fullPath: '/a/notificacao/listagem'
      preLoaderRoute: typeof ANotificacaoListagemLazyImport
      parentRoute: typeof ANotificacaoRouteImport
    }
    '/a/suporte/instrucoes-de-uso': {
      id: '/a/suporte/instrucoes-de-uso'
      path: '/suporte/instrucoes-de-uso'
      fullPath: '/a/suporte/instrucoes-de-uso'
      preLoaderRoute: typeof ASuporteInstrucoesDeUsoLazyImport
      parentRoute: typeof ARouteImport
    }
    '/p/compartilhamento/$id': {
      id: '/p/compartilhamento/$id'
      path: '/p/compartilhamento/$id'
      fullPath: '/p/compartilhamento/$id'
      preLoaderRoute: typeof PCompartilhamentoIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/p/contrato/$id': {
      id: '/p/contrato/$id'
      path: '/p/contrato/$id'
      fullPath: '/p/contrato/$id'
      preLoaderRoute: typeof PContratoIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/p/impressao/$filetype': {
      id: '/p/impressao/$filetype'
      path: '/p/impressao/$filetype'
      fullPath: '/p/impressao/$filetype'
      preLoaderRoute: typeof PImpressaoFiletypeLazyImport
      parentRoute: typeof rootRoute
    }
    '/a/cobranca-pix/': {
      id: '/a/cobranca-pix/'
      path: '/'
      fullPath: '/a/cobranca-pix/'
      preLoaderRoute: typeof ACobrancaPixIndexImport
      parentRoute: typeof ACobrancaPixRouteImport
    }
    '/a/compra/': {
      id: '/a/compra/'
      path: '/'
      fullPath: '/a/compra/'
      preLoaderRoute: typeof ACompraIndexImport
      parentRoute: typeof ACompraRouteImport
    }
    '/a/cupom-fiscal/': {
      id: '/a/cupom-fiscal/'
      path: '/'
      fullPath: '/a/cupom-fiscal/'
      preLoaderRoute: typeof ACupomFiscalIndexImport
      parentRoute: typeof ACupomFiscalRouteImport
    }
    '/a/movimentacoes/': {
      id: '/a/movimentacoes/'
      path: '/'
      fullPath: '/a/movimentacoes/'
      preLoaderRoute: typeof AMovimentacoesIndexImport
      parentRoute: typeof AMovimentacoesRouteImport
    }
    '/a/pdv/': {
      id: '/a/pdv/'
      path: '/pdv'
      fullPath: '/a/pdv'
      preLoaderRoute: typeof APdvIndexImport
      parentRoute: typeof ARouteImport
    }
    '/p/erro/': {
      id: '/p/erro/'
      path: '/p/erro'
      fullPath: '/p/erro'
      preLoaderRoute: typeof PErroIndexImport
      parentRoute: typeof rootRoute
    }
    '/a/cobranca-boleto/': {
      id: '/a/cobranca-boleto/'
      path: '/'
      fullPath: '/a/cobranca-boleto/'
      preLoaderRoute: typeof ACobrancaBoletoIndexLazyImport
      parentRoute: typeof ACobrancaBoletoRouteImport
    }
    '/a/cobranca-cartao/': {
      id: '/a/cobranca-cartao/'
      path: '/'
      fullPath: '/a/cobranca-cartao/'
      preLoaderRoute: typeof ACobrancaCartaoIndexLazyImport
      parentRoute: typeof ACobrancaCartaoRouteImport
    }
    '/a/comissao/': {
      id: '/a/comissao/'
      path: '/'
      fullPath: '/a/comissao/'
      preLoaderRoute: typeof AComissaoIndexLazyImport
      parentRoute: typeof AComissaoRouteImport
    }
    '/a/contas-pagar/': {
      id: '/a/contas-pagar/'
      path: '/'
      fullPath: '/a/contas-pagar/'
      preLoaderRoute: typeof AContasPagarIndexLazyImport
      parentRoute: typeof AContasPagarRouteImport
    }
    '/a/contas-receber/': {
      id: '/a/contas-receber/'
      path: '/'
      fullPath: '/a/contas-receber/'
      preLoaderRoute: typeof AContasReceberIndexLazyImport
      parentRoute: typeof AContasReceberRouteImport
    }
    '/a/contrato/': {
      id: '/a/contrato/'
      path: '/contrato'
      fullPath: '/a/contrato'
      preLoaderRoute: typeof AContratoIndexLazyImport
      parentRoute: typeof ARouteImport
    }
    '/a/debug/': {
      id: '/a/debug/'
      path: '/'
      fullPath: '/a/debug/'
      preLoaderRoute: typeof ADebugIndexLazyImport
      parentRoute: typeof ADebugRouteImport
    }
    '/a/extrato-boleto/': {
      id: '/a/extrato-boleto/'
      path: '/'
      fullPath: '/a/extrato-boleto/'
      preLoaderRoute: typeof AExtratoBoletoIndexLazyImport
      parentRoute: typeof AExtratoBoletoRouteImport
    }
    '/a/extrato-pix/': {
      id: '/a/extrato-pix/'
      path: '/'
      fullPath: '/a/extrato-pix/'
      preLoaderRoute: typeof AExtratoPixIndexLazyImport
      parentRoute: typeof AExtratoPixRouteImport
    }
    '/a/manifesto-carga/': {
      id: '/a/manifesto-carga/'
      path: '/'
      fullPath: '/a/manifesto-carga/'
      preLoaderRoute: typeof AManifestoCargaIndexLazyImport
      parentRoute: typeof AManifestoCargaRouteImport
    }
    '/a/nota-fiscal/': {
      id: '/a/nota-fiscal/'
      path: '/'
      fullPath: '/a/nota-fiscal/'
      preLoaderRoute: typeof ANotaFiscalIndexLazyImport
      parentRoute: typeof ANotaFiscalRouteImport
    }
    '/a/painel-gerencial/': {
      id: '/a/painel-gerencial/'
      path: '/painel-gerencial'
      fullPath: '/a/painel-gerencial'
      preLoaderRoute: typeof APainelGerencialIndexLazyImport
      parentRoute: typeof ARouteImport
    }
    '/a/regra-fiscal/': {
      id: '/a/regra-fiscal/'
      path: '/'
      fullPath: '/a/regra-fiscal/'
      preLoaderRoute: typeof ARegraFiscalIndexLazyImport
      parentRoute: typeof ARegraFiscalRouteImport
    }
    '/a/romaneio/': {
      id: '/a/romaneio/'
      path: '/'
      fullPath: '/a/romaneio/'
      preLoaderRoute: typeof ARomaneioIndexLazyImport
      parentRoute: typeof ARomaneioRouteImport
    }
    '/a/suporte/': {
      id: '/a/suporte/'
      path: '/suporte'
      fullPath: '/a/suporte'
      preLoaderRoute: typeof ASuporteIndexLazyImport
      parentRoute: typeof ARouteImport
    }
    '/a/venda-externa/': {
      id: '/a/venda-externa/'
      path: '/'
      fullPath: '/a/venda-externa/'
      preLoaderRoute: typeof AVendaExternaIndexLazyImport
      parentRoute: typeof AVendaExternaRouteImport
    }
    '/a/importacao/cadastro/$tipo': {
      id: '/a/importacao/cadastro/$tipo'
      path: '/importacao/cadastro/$tipo'
      fullPath: '/a/importacao/cadastro/$tipo'
      preLoaderRoute: typeof AImportacaoCadastroTipoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/a/importacao/xml/$tipo': {
      id: '/a/importacao/xml/$tipo'
      path: '/importacao/xml/$tipo'
      fullPath: '/a/importacao/xml/$tipo'
      preLoaderRoute: typeof AImportacaoXmlTipoRouteImport
      parentRoute: typeof ARouteImport
    }
    '/p/conta-digital/termos-de-uso/$base64': {
      id: '/p/conta-digital/termos-de-uso/$base64'
      path: '/p/conta-digital/termos-de-uso/$base64'
      fullPath: '/p/conta-digital/termos-de-uso/$base64'
      preLoaderRoute: typeof PContaDigitalTermosDeUsoBase64Import
      parentRoute: typeof rootRoute
    }
    '/a/cobranca-boleto/$mode/$idpk': {
      id: '/a/cobranca-boleto/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/cobranca-boleto/$mode/$idpk'
      preLoaderRoute: typeof ACobrancaBoletoModeIdpkLazyImport
      parentRoute: typeof ACobrancaBoletoRouteImport
    }
    '/a/cobranca-cartao/$mode/$idpk': {
      id: '/a/cobranca-cartao/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/cobranca-cartao/$mode/$idpk'
      preLoaderRoute: typeof ACobrancaCartaoModeIdpkLazyImport
      parentRoute: typeof ACobrancaCartaoRouteImport
    }
    '/a/compra/$mode/$idpk': {
      id: '/a/compra/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/compra/$mode/$idpk'
      preLoaderRoute: typeof ACompraModeIdpkLazyImport
      parentRoute: typeof ACompraRouteImport
    }
    '/a/contas-pagar/$mode/$idpk': {
      id: '/a/contas-pagar/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/contas-pagar/$mode/$idpk'
      preLoaderRoute: typeof AContasPagarModeIdpkLazyImport
      parentRoute: typeof AContasPagarRouteImport
    }
    '/a/contas-receber/$mode/$idpk': {
      id: '/a/contas-receber/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/contas-receber/$mode/$idpk'
      preLoaderRoute: typeof AContasReceberModeIdpkLazyImport
      parentRoute: typeof AContasReceberRouteImport
    }
    '/a/manifesto-carga/$mode/$idpk': {
      id: '/a/manifesto-carga/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/manifesto-carga/$mode/$idpk'
      preLoaderRoute: typeof AManifestoCargaModeIdpkLazyImport
      parentRoute: typeof AManifestoCargaRouteImport
    }
    '/a/movimentacoes/extrato/$idpk': {
      id: '/a/movimentacoes/extrato/$idpk'
      path: '/extrato/$idpk'
      fullPath: '/a/movimentacoes/extrato/$idpk'
      preLoaderRoute: typeof AMovimentacoesExtratoIdpkLazyImport
      parentRoute: typeof AMovimentacoesRouteImport
    }
    '/a/nota-fiscal/$mode/$idpk': {
      id: '/a/nota-fiscal/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/nota-fiscal/$mode/$idpk'
      preLoaderRoute: typeof ANotaFiscalModeIdpkLazyImport
      parentRoute: typeof ANotaFiscalRouteImport
    }
    '/a/venda-externa/$mode/$idpk': {
      id: '/a/venda-externa/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/venda-externa/$mode/$idpk'
      preLoaderRoute: typeof AVendaExternaModeIdpkLazyImport
      parentRoute: typeof AVendaExternaRouteImport
    }
    '/a/configuracao/empresa/': {
      id: '/a/configuracao/empresa/'
      path: '/'
      fullPath: '/a/configuracao/empresa/'
      preLoaderRoute: typeof AConfiguracaoEmpresaIndexImport
      parentRoute: typeof AConfiguracaoEmpresaRouteImport
    }
    '/a/relatorio/$tipo/': {
      id: '/a/relatorio/$tipo/'
      path: '/relatorio/$tipo'
      fullPath: '/a/relatorio/$tipo'
      preLoaderRoute: typeof ARelatorioTipoIndexImport
      parentRoute: typeof ARouteImport
    }
    '/p/catalogo/$slug/': {
      id: '/p/catalogo/$slug/'
      path: '/p/catalogo/$slug'
      fullPath: '/p/catalogo/$slug'
      preLoaderRoute: typeof PCatalogoSlugIndexImport
      parentRoute: typeof rootRoute
    }
    '/p/compartilhamento/$id/': {
      id: '/p/compartilhamento/$id/'
      path: '/'
      fullPath: '/p/compartilhamento/$id/'
      preLoaderRoute: typeof PCompartilhamentoIdIndexImport
      parentRoute: typeof PCompartilhamentoIdLazyImport
    }
    '/p/pagamento/$id/': {
      id: '/p/pagamento/$id/'
      path: '/p/pagamento/$id'
      fullPath: '/p/pagamento/$id'
      preLoaderRoute: typeof PPagamentoIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/a/configuracao/usuario/': {
      id: '/a/configuracao/usuario/'
      path: '/configuracao/usuario'
      fullPath: '/a/configuracao/usuario'
      preLoaderRoute: typeof AConfiguracaoUsuarioIndexLazyImport
      parentRoute: typeof ARouteImport
    }
    '/a/venda/$tipo/': {
      id: '/a/venda/$tipo/'
      path: '/'
      fullPath: '/a/venda/$tipo/'
      preLoaderRoute: typeof AVendaTipoIndexLazyImport
      parentRoute: typeof AVendaTipoRouteImport
    }
    '/p/catalogo/$slug/produto/$idpk': {
      id: '/p/catalogo/$slug/produto/$idpk'
      path: '/p/catalogo/$slug/produto/$idpk'
      fullPath: '/p/catalogo/$slug/produto/$idpk'
      preLoaderRoute: typeof PCatalogoSlugProdutoIdpkImport
      parentRoute: typeof rootRoute
    }
    '/a/cadastro/produto/$mode/$idpk': {
      id: '/a/cadastro/produto/$mode/$idpk'
      path: '/$mode/$idpk'
      fullPath: '/a/cadastro/produto/$mode/$idpk'
      preLoaderRoute: typeof ACadastroProdutoModeIdpkLazyImport
      parentRoute: typeof ACadastroProdutoImport
    }
    '/a/movimentacoes/$mode/$contaIdpk/$idpk': {
      id: '/a/movimentacoes/$mode/$contaIdpk/$idpk'
      path: '/$mode/$contaIdpk/$idpk'
      fullPath: '/a/movimentacoes/$mode/$contaIdpk/$idpk'
      preLoaderRoute: typeof AMovimentacoesModeContaIdpkIdpkLazyImport
      parentRoute: typeof AMovimentacoesRouteImport
    }
    '/a/contrato/$mode/$idpk/': {
      id: '/a/contrato/$mode/$idpk/'
      path: '/contrato/$mode/$idpk'
      fullPath: '/a/contrato/$mode/$idpk'
      preLoaderRoute: typeof AContratoModeIdpkIndexImport
      parentRoute: typeof ARouteImport
    }
    '/a/romaneio/$mode/$idpk/': {
      id: '/a/romaneio/$mode/$idpk/'
      path: '/$mode/$idpk'
      fullPath: '/a/romaneio/$mode/$idpk'
      preLoaderRoute: typeof ARomaneioModeIdpkIndexImport
      parentRoute: typeof ARomaneioRouteImport
    }
    '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': {
      id: '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk'
      path: '/venda-externa/$tipoVendaExterna/$mode/$veeIdpk'
      fullPath: '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk'
      preLoaderRoute: typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyImport
      parentRoute: typeof ANotaFiscalRouteImport
    }
    '/a/venda/$tipo/$mode/$idpk/': {
      id: '/a/venda/$tipo/$mode/$idpk/'
      path: '/$mode/$idpk'
      fullPath: '/a/venda/$tipo/$mode/$idpk'
      preLoaderRoute: typeof AVendaTipoModeIdpkIndexImport
      parentRoute: typeof AVendaTipoRouteImport
    }
    '/a/venda/venda-externa/$tipo/$mode/$idpk/': {
      id: '/a/venda/venda-externa/$tipo/$mode/$idpk/'
      path: '/venda/venda-externa/$tipo/$mode/$idpk'
      fullPath: '/a/venda/venda-externa/$tipo/$mode/$idpk'
      preLoaderRoute: typeof AVendaVendaExternaTipoModeIdpkIndexImport
      parentRoute: typeof ARouteImport
    }
  }
}

// Create and export the route tree

interface ACobrancaBoletoRouteRouteChildren {
  ACobrancaBoletoRotinasBancariasLazyRoute: typeof ACobrancaBoletoRotinasBancariasLazyRoute
  ACobrancaBoletoIndexLazyRoute: typeof ACobrancaBoletoIndexLazyRoute
  ACobrancaBoletoModeIdpkLazyRoute: typeof ACobrancaBoletoModeIdpkLazyRoute
}

const ACobrancaBoletoRouteRouteChildren: ACobrancaBoletoRouteRouteChildren = {
  ACobrancaBoletoRotinasBancariasLazyRoute:
    ACobrancaBoletoRotinasBancariasLazyRoute,
  ACobrancaBoletoIndexLazyRoute: ACobrancaBoletoIndexLazyRoute,
  ACobrancaBoletoModeIdpkLazyRoute: ACobrancaBoletoModeIdpkLazyRoute,
}

const ACobrancaBoletoRouteRouteWithChildren =
  ACobrancaBoletoRouteRoute._addFileChildren(ACobrancaBoletoRouteRouteChildren)

interface ACobrancaCartaoRouteRouteChildren {
  ACobrancaCartaoIndexLazyRoute: typeof ACobrancaCartaoIndexLazyRoute
  ACobrancaCartaoModeIdpkLazyRoute: typeof ACobrancaCartaoModeIdpkLazyRoute
}

const ACobrancaCartaoRouteRouteChildren: ACobrancaCartaoRouteRouteChildren = {
  ACobrancaCartaoIndexLazyRoute: ACobrancaCartaoIndexLazyRoute,
  ACobrancaCartaoModeIdpkLazyRoute: ACobrancaCartaoModeIdpkLazyRoute,
}

const ACobrancaCartaoRouteRouteWithChildren =
  ACobrancaCartaoRouteRoute._addFileChildren(ACobrancaCartaoRouteRouteChildren)

interface ACobrancaPixRouteRouteChildren {
  ACobrancaPixIndexRoute: typeof ACobrancaPixIndexRoute
}

const ACobrancaPixRouteRouteChildren: ACobrancaPixRouteRouteChildren = {
  ACobrancaPixIndexRoute: ACobrancaPixIndexRoute,
}

const ACobrancaPixRouteRouteWithChildren =
  ACobrancaPixRouteRoute._addFileChildren(ACobrancaPixRouteRouteChildren)

interface AComissaoRouteRouteChildren {
  AComissaoIndexLazyRoute: typeof AComissaoIndexLazyRoute
}

const AComissaoRouteRouteChildren: AComissaoRouteRouteChildren = {
  AComissaoIndexLazyRoute: AComissaoIndexLazyRoute,
}

const AComissaoRouteRouteWithChildren = AComissaoRouteRoute._addFileChildren(
  AComissaoRouteRouteChildren,
)

interface ACompraRouteRouteChildren {
  ACompraIndexRoute: typeof ACompraIndexRoute
  ACompraModeIdpkLazyRoute: typeof ACompraModeIdpkLazyRoute
}

const ACompraRouteRouteChildren: ACompraRouteRouteChildren = {
  ACompraIndexRoute: ACompraIndexRoute,
  ACompraModeIdpkLazyRoute: ACompraModeIdpkLazyRoute,
}

const ACompraRouteRouteWithChildren = ACompraRouteRoute._addFileChildren(
  ACompraRouteRouteChildren,
)

interface AContasPagarRouteRouteChildren {
  AContasPagarIndexLazyRoute: typeof AContasPagarIndexLazyRoute
  AContasPagarModeIdpkLazyRoute: typeof AContasPagarModeIdpkLazyRoute
}

const AContasPagarRouteRouteChildren: AContasPagarRouteRouteChildren = {
  AContasPagarIndexLazyRoute: AContasPagarIndexLazyRoute,
  AContasPagarModeIdpkLazyRoute: AContasPagarModeIdpkLazyRoute,
}

const AContasPagarRouteRouteWithChildren =
  AContasPagarRouteRoute._addFileChildren(AContasPagarRouteRouteChildren)

interface AContasReceberRouteRouteChildren {
  AContasReceberIndexLazyRoute: typeof AContasReceberIndexLazyRoute
  AContasReceberModeIdpkLazyRoute: typeof AContasReceberModeIdpkLazyRoute
}

const AContasReceberRouteRouteChildren: AContasReceberRouteRouteChildren = {
  AContasReceberIndexLazyRoute: AContasReceberIndexLazyRoute,
  AContasReceberModeIdpkLazyRoute: AContasReceberModeIdpkLazyRoute,
}

const AContasReceberRouteRouteWithChildren =
  AContasReceberRouteRoute._addFileChildren(AContasReceberRouteRouteChildren)

interface ACupomFiscalRouteRouteChildren {
  ACupomFiscalIndexRoute: typeof ACupomFiscalIndexRoute
}

const ACupomFiscalRouteRouteChildren: ACupomFiscalRouteRouteChildren = {
  ACupomFiscalIndexRoute: ACupomFiscalIndexRoute,
}

const ACupomFiscalRouteRouteWithChildren =
  ACupomFiscalRouteRoute._addFileChildren(ACupomFiscalRouteRouteChildren)

interface ADebugRouteRouteChildren {
  ADebugPaginationRoute: typeof ADebugPaginationRoute
  ADebugIndexLazyRoute: typeof ADebugIndexLazyRoute
}

const ADebugRouteRouteChildren: ADebugRouteRouteChildren = {
  ADebugPaginationRoute: ADebugPaginationRoute,
  ADebugIndexLazyRoute: ADebugIndexLazyRoute,
}

const ADebugRouteRouteWithChildren = ADebugRouteRoute._addFileChildren(
  ADebugRouteRouteChildren,
)

interface AEstoqueRouteRouteChildren {
  AEstoqueConferenciaLazyRoute: typeof AEstoqueConferenciaLazyRoute
  AEstoqueMovimentacaoLazyRoute: typeof AEstoqueMovimentacaoLazyRoute
}

const AEstoqueRouteRouteChildren: AEstoqueRouteRouteChildren = {
  AEstoqueConferenciaLazyRoute: AEstoqueConferenciaLazyRoute,
  AEstoqueMovimentacaoLazyRoute: AEstoqueMovimentacaoLazyRoute,
}

const AEstoqueRouteRouteWithChildren = AEstoqueRouteRoute._addFileChildren(
  AEstoqueRouteRouteChildren,
)

interface AExtratoBoletoRouteRouteChildren {
  AExtratoBoletoIndexLazyRoute: typeof AExtratoBoletoIndexLazyRoute
}

const AExtratoBoletoRouteRouteChildren: AExtratoBoletoRouteRouteChildren = {
  AExtratoBoletoIndexLazyRoute: AExtratoBoletoIndexLazyRoute,
}

const AExtratoBoletoRouteRouteWithChildren =
  AExtratoBoletoRouteRoute._addFileChildren(AExtratoBoletoRouteRouteChildren)

interface AExtratoPixRouteRouteChildren {
  AExtratoPixIndexLazyRoute: typeof AExtratoPixIndexLazyRoute
}

const AExtratoPixRouteRouteChildren: AExtratoPixRouteRouteChildren = {
  AExtratoPixIndexLazyRoute: AExtratoPixIndexLazyRoute,
}

const AExtratoPixRouteRouteWithChildren =
  AExtratoPixRouteRoute._addFileChildren(AExtratoPixRouteRouteChildren)

interface AManifestoCargaRouteRouteChildren {
  AManifestoCargaIndexLazyRoute: typeof AManifestoCargaIndexLazyRoute
  AManifestoCargaModeIdpkLazyRoute: typeof AManifestoCargaModeIdpkLazyRoute
}

const AManifestoCargaRouteRouteChildren: AManifestoCargaRouteRouteChildren = {
  AManifestoCargaIndexLazyRoute: AManifestoCargaIndexLazyRoute,
  AManifestoCargaModeIdpkLazyRoute: AManifestoCargaModeIdpkLazyRoute,
}

const AManifestoCargaRouteRouteWithChildren =
  AManifestoCargaRouteRoute._addFileChildren(AManifestoCargaRouteRouteChildren)

interface AMovimentacoesRouteRouteChildren {
  AMovimentacoesIndexRoute: typeof AMovimentacoesIndexRoute
  AMovimentacoesExtratoIdpkLazyRoute: typeof AMovimentacoesExtratoIdpkLazyRoute
  AMovimentacoesModeContaIdpkIdpkLazyRoute: typeof AMovimentacoesModeContaIdpkIdpkLazyRoute
}

const AMovimentacoesRouteRouteChildren: AMovimentacoesRouteRouteChildren = {
  AMovimentacoesIndexRoute: AMovimentacoesIndexRoute,
  AMovimentacoesExtratoIdpkLazyRoute: AMovimentacoesExtratoIdpkLazyRoute,
  AMovimentacoesModeContaIdpkIdpkLazyRoute:
    AMovimentacoesModeContaIdpkIdpkLazyRoute,
}

const AMovimentacoesRouteRouteWithChildren =
  AMovimentacoesRouteRoute._addFileChildren(AMovimentacoesRouteRouteChildren)

interface ANotaFiscalRouteRouteChildren {
  ANotaFiscalIndexLazyRoute: typeof ANotaFiscalIndexLazyRoute
  ANotaFiscalModeIdpkLazyRoute: typeof ANotaFiscalModeIdpkLazyRoute
  ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute: typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute
}

const ANotaFiscalRouteRouteChildren: ANotaFiscalRouteRouteChildren = {
  ANotaFiscalIndexLazyRoute: ANotaFiscalIndexLazyRoute,
  ANotaFiscalModeIdpkLazyRoute: ANotaFiscalModeIdpkLazyRoute,
  ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute:
    ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute,
}

const ANotaFiscalRouteRouteWithChildren =
  ANotaFiscalRouteRoute._addFileChildren(ANotaFiscalRouteRouteChildren)

interface ANotificacaoRouteRouteChildren {
  ANotificacaoListagemLazyRoute: typeof ANotificacaoListagemLazyRoute
}

const ANotificacaoRouteRouteChildren: ANotificacaoRouteRouteChildren = {
  ANotificacaoListagemLazyRoute: ANotificacaoListagemLazyRoute,
}

const ANotificacaoRouteRouteWithChildren =
  ANotificacaoRouteRoute._addFileChildren(ANotificacaoRouteRouteChildren)

interface ARegraFiscalRouteRouteChildren {
  ARegraFiscalIndexLazyRoute: typeof ARegraFiscalIndexLazyRoute
}

const ARegraFiscalRouteRouteChildren: ARegraFiscalRouteRouteChildren = {
  ARegraFiscalIndexLazyRoute: ARegraFiscalIndexLazyRoute,
}

const ARegraFiscalRouteRouteWithChildren =
  ARegraFiscalRouteRoute._addFileChildren(ARegraFiscalRouteRouteChildren)

interface ARomaneioRouteRouteChildren {
  ARomaneioIndexLazyRoute: typeof ARomaneioIndexLazyRoute
  ARomaneioModeIdpkIndexRoute: typeof ARomaneioModeIdpkIndexRoute
}

const ARomaneioRouteRouteChildren: ARomaneioRouteRouteChildren = {
  ARomaneioIndexLazyRoute: ARomaneioIndexLazyRoute,
  ARomaneioModeIdpkIndexRoute: ARomaneioModeIdpkIndexRoute,
}

const ARomaneioRouteRouteWithChildren = ARomaneioRouteRoute._addFileChildren(
  ARomaneioRouteRouteChildren,
)

interface AVendaExternaRouteRouteChildren {
  AVendaExternaIndexLazyRoute: typeof AVendaExternaIndexLazyRoute
  AVendaExternaModeIdpkLazyRoute: typeof AVendaExternaModeIdpkLazyRoute
}

const AVendaExternaRouteRouteChildren: AVendaExternaRouteRouteChildren = {
  AVendaExternaIndexLazyRoute: AVendaExternaIndexLazyRoute,
  AVendaExternaModeIdpkLazyRoute: AVendaExternaModeIdpkLazyRoute,
}

const AVendaExternaRouteRouteWithChildren =
  AVendaExternaRouteRoute._addFileChildren(AVendaExternaRouteRouteChildren)

interface AConfiguracaoEmpresaRouteRouteChildren {
  AConfiguracaoEmpresaIndexRoute: typeof AConfiguracaoEmpresaIndexRoute
}

const AConfiguracaoEmpresaRouteRouteChildren: AConfiguracaoEmpresaRouteRouteChildren =
  {
    AConfiguracaoEmpresaIndexRoute: AConfiguracaoEmpresaIndexRoute,
  }

const AConfiguracaoEmpresaRouteRouteWithChildren =
  AConfiguracaoEmpresaRouteRoute._addFileChildren(
    AConfiguracaoEmpresaRouteRouteChildren,
  )

interface AVendaTipoRouteRouteChildren {
  AVendaTipoIndexLazyRoute: typeof AVendaTipoIndexLazyRoute
  AVendaTipoModeIdpkIndexRoute: typeof AVendaTipoModeIdpkIndexRoute
}

const AVendaTipoRouteRouteChildren: AVendaTipoRouteRouteChildren = {
  AVendaTipoIndexLazyRoute: AVendaTipoIndexLazyRoute,
  AVendaTipoModeIdpkIndexRoute: AVendaTipoModeIdpkIndexRoute,
}

const AVendaTipoRouteRouteWithChildren = AVendaTipoRouteRoute._addFileChildren(
  AVendaTipoRouteRouteChildren,
)

interface ACadastroProdutoRouteChildren {
  ACadastroProdutoModeIdpkLazyRoute: typeof ACadastroProdutoModeIdpkLazyRoute
}

const ACadastroProdutoRouteChildren: ACadastroProdutoRouteChildren = {
  ACadastroProdutoModeIdpkLazyRoute: ACadastroProdutoModeIdpkLazyRoute,
}

const ACadastroProdutoRouteWithChildren =
  ACadastroProdutoRoute._addFileChildren(ACadastroProdutoRouteChildren)

interface ARouteRouteChildren {
  ACobrancaBoletoRouteRoute: typeof ACobrancaBoletoRouteRouteWithChildren
  ACobrancaCartaoRouteRoute: typeof ACobrancaCartaoRouteRouteWithChildren
  ACobrancaPixRouteRoute: typeof ACobrancaPixRouteRouteWithChildren
  AComissaoRouteRoute: typeof AComissaoRouteRouteWithChildren
  ACompraRouteRoute: typeof ACompraRouteRouteWithChildren
  AContasPagarRouteRoute: typeof AContasPagarRouteRouteWithChildren
  AContasReceberRouteRoute: typeof AContasReceberRouteRouteWithChildren
  ACupomFiscalRouteRoute: typeof ACupomFiscalRouteRouteWithChildren
  ADebugRouteRoute: typeof ADebugRouteRouteWithChildren
  AEstoqueRouteRoute: typeof AEstoqueRouteRouteWithChildren
  AExtratoBoletoRouteRoute: typeof AExtratoBoletoRouteRouteWithChildren
  AExtratoPixRouteRoute: typeof AExtratoPixRouteRouteWithChildren
  AManifestoCargaRouteRoute: typeof AManifestoCargaRouteRouteWithChildren
  AMovimentacoesRouteRoute: typeof AMovimentacoesRouteRouteWithChildren
  ANotaFiscalRouteRoute: typeof ANotaFiscalRouteRouteWithChildren
  ANotificacaoRouteRoute: typeof ANotificacaoRouteRouteWithChildren
  ARegraFiscalRouteRoute: typeof ARegraFiscalRouteRouteWithChildren
  ARomaneioRouteRoute: typeof ARomaneioRouteRouteWithChildren
  AVendaExternaRouteRoute: typeof AVendaExternaRouteRouteWithChildren
  AConfiguracaoEmpresaRouteRoute: typeof AConfiguracaoEmpresaRouteRouteWithChildren
  AVendaTipoRouteRoute: typeof AVendaTipoRouteRouteWithChildren
  ACadastroClienteRoute: typeof ACadastroClienteRoute
  ACadastroFornecedorRoute: typeof ACadastroFornecedorRoute
  ACadastroProdutoRoute: typeof ACadastroProdutoRouteWithChildren
  ACadastroTransportadorRoute: typeof ACadastroTransportadorRoute
  AImpressaoFiletypeLazyRoute: typeof AImpressaoFiletypeLazyRoute
  ASuporteInstrucoesDeUsoLazyRoute: typeof ASuporteInstrucoesDeUsoLazyRoute
  APdvIndexRoute: typeof APdvIndexRoute
  AContratoIndexLazyRoute: typeof AContratoIndexLazyRoute
  APainelGerencialIndexLazyRoute: typeof APainelGerencialIndexLazyRoute
  ASuporteIndexLazyRoute: typeof ASuporteIndexLazyRoute
  AImportacaoCadastroTipoRouteRoute: typeof AImportacaoCadastroTipoRouteRoute
  AImportacaoXmlTipoRouteRoute: typeof AImportacaoXmlTipoRouteRoute
  ARelatorioTipoIndexRoute: typeof ARelatorioTipoIndexRoute
  AConfiguracaoUsuarioIndexLazyRoute: typeof AConfiguracaoUsuarioIndexLazyRoute
  AContratoModeIdpkIndexRoute: typeof AContratoModeIdpkIndexRoute
  AVendaVendaExternaTipoModeIdpkIndexRoute: typeof AVendaVendaExternaTipoModeIdpkIndexRoute
}

const ARouteRouteChildren: ARouteRouteChildren = {
  ACobrancaBoletoRouteRoute: ACobrancaBoletoRouteRouteWithChildren,
  ACobrancaCartaoRouteRoute: ACobrancaCartaoRouteRouteWithChildren,
  ACobrancaPixRouteRoute: ACobrancaPixRouteRouteWithChildren,
  AComissaoRouteRoute: AComissaoRouteRouteWithChildren,
  ACompraRouteRoute: ACompraRouteRouteWithChildren,
  AContasPagarRouteRoute: AContasPagarRouteRouteWithChildren,
  AContasReceberRouteRoute: AContasReceberRouteRouteWithChildren,
  ACupomFiscalRouteRoute: ACupomFiscalRouteRouteWithChildren,
  ADebugRouteRoute: ADebugRouteRouteWithChildren,
  AEstoqueRouteRoute: AEstoqueRouteRouteWithChildren,
  AExtratoBoletoRouteRoute: AExtratoBoletoRouteRouteWithChildren,
  AExtratoPixRouteRoute: AExtratoPixRouteRouteWithChildren,
  AManifestoCargaRouteRoute: AManifestoCargaRouteRouteWithChildren,
  AMovimentacoesRouteRoute: AMovimentacoesRouteRouteWithChildren,
  ANotaFiscalRouteRoute: ANotaFiscalRouteRouteWithChildren,
  ANotificacaoRouteRoute: ANotificacaoRouteRouteWithChildren,
  ARegraFiscalRouteRoute: ARegraFiscalRouteRouteWithChildren,
  ARomaneioRouteRoute: ARomaneioRouteRouteWithChildren,
  AVendaExternaRouteRoute: AVendaExternaRouteRouteWithChildren,
  AConfiguracaoEmpresaRouteRoute: AConfiguracaoEmpresaRouteRouteWithChildren,
  AVendaTipoRouteRoute: AVendaTipoRouteRouteWithChildren,
  ACadastroClienteRoute: ACadastroClienteRoute,
  ACadastroFornecedorRoute: ACadastroFornecedorRoute,
  ACadastroProdutoRoute: ACadastroProdutoRouteWithChildren,
  ACadastroTransportadorRoute: ACadastroTransportadorRoute,
  AImpressaoFiletypeLazyRoute: AImpressaoFiletypeLazyRoute,
  ASuporteInstrucoesDeUsoLazyRoute: ASuporteInstrucoesDeUsoLazyRoute,
  APdvIndexRoute: APdvIndexRoute,
  AContratoIndexLazyRoute: AContratoIndexLazyRoute,
  APainelGerencialIndexLazyRoute: APainelGerencialIndexLazyRoute,
  ASuporteIndexLazyRoute: ASuporteIndexLazyRoute,
  AImportacaoCadastroTipoRouteRoute: AImportacaoCadastroTipoRouteRoute,
  AImportacaoXmlTipoRouteRoute: AImportacaoXmlTipoRouteRoute,
  ARelatorioTipoIndexRoute: ARelatorioTipoIndexRoute,
  AConfiguracaoUsuarioIndexLazyRoute: AConfiguracaoUsuarioIndexLazyRoute,
  AContratoModeIdpkIndexRoute: AContratoModeIdpkIndexRoute,
  AVendaVendaExternaTipoModeIdpkIndexRoute:
    AVendaVendaExternaTipoModeIdpkIndexRoute,
}

const ARouteRouteWithChildren =
  ARouteRoute._addFileChildren(ARouteRouteChildren)

interface AuthRouteRouteChildren {
  AuthCadastroRoute: typeof AuthCadastroRoute
  AuthCadastroFimRoute: typeof AuthCadastroFimRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthRecuperarSenhaRoute: typeof AuthRecuperarSenhaRoute
  AuthRedefinirSenhaRoute: typeof AuthRedefinirSenhaRoute
  AuthAcessoNegadoLazyRoute: typeof AuthAcessoNegadoLazyRoute
  AuthContaBloqueadaLazyRoute: typeof AuthContaBloqueadaLazyRoute
  AuthSolicitarValidacaoLazyRoute: typeof AuthSolicitarValidacaoLazyRoute
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthCadastroRoute: AuthCadastroRoute,
  AuthCadastroFimRoute: AuthCadastroFimRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthRecuperarSenhaRoute: AuthRecuperarSenhaRoute,
  AuthRedefinirSenhaRoute: AuthRedefinirSenhaRoute,
  AuthAcessoNegadoLazyRoute: AuthAcessoNegadoLazyRoute,
  AuthContaBloqueadaLazyRoute: AuthContaBloqueadaLazyRoute,
  AuthSolicitarValidacaoLazyRoute: AuthSolicitarValidacaoLazyRoute,
}

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
)

interface PCompartilhamentoIdLazyRouteChildren {
  PCompartilhamentoIdIndexRoute: typeof PCompartilhamentoIdIndexRoute
}

const PCompartilhamentoIdLazyRouteChildren: PCompartilhamentoIdLazyRouteChildren =
  {
    PCompartilhamentoIdIndexRoute: PCompartilhamentoIdIndexRoute,
  }

const PCompartilhamentoIdLazyRouteWithChildren =
  PCompartilhamentoIdLazyRoute._addFileChildren(
    PCompartilhamentoIdLazyRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/a': typeof ARouteRouteWithChildren
  '/auth': typeof AuthRouteRouteWithChildren
  '/a/cobranca-boleto': typeof ACobrancaBoletoRouteRouteWithChildren
  '/a/cobranca-cartao': typeof ACobrancaCartaoRouteRouteWithChildren
  '/a/cobranca-pix': typeof ACobrancaPixRouteRouteWithChildren
  '/a/comissao': typeof AComissaoRouteRouteWithChildren
  '/a/compra': typeof ACompraRouteRouteWithChildren
  '/a/contas-pagar': typeof AContasPagarRouteRouteWithChildren
  '/a/contas-receber': typeof AContasReceberRouteRouteWithChildren
  '/a/cupom-fiscal': typeof ACupomFiscalRouteRouteWithChildren
  '/a/debug': typeof ADebugRouteRouteWithChildren
  '/a/estoque': typeof AEstoqueRouteRouteWithChildren
  '/a/extrato-boleto': typeof AExtratoBoletoRouteRouteWithChildren
  '/a/extrato-pix': typeof AExtratoPixRouteRouteWithChildren
  '/a/manifesto-carga': typeof AManifestoCargaRouteRouteWithChildren
  '/a/movimentacoes': typeof AMovimentacoesRouteRouteWithChildren
  '/a/nota-fiscal': typeof ANotaFiscalRouteRouteWithChildren
  '/a/notificacao': typeof ANotificacaoRouteRouteWithChildren
  '/a/regra-fiscal': typeof ARegraFiscalRouteRouteWithChildren
  '/a/romaneio': typeof ARomaneioRouteRouteWithChildren
  '/a/venda-externa': typeof AVendaExternaRouteRouteWithChildren
  '/auth/cadastro': typeof AuthCadastroRoute
  '/auth/cadastro-fim': typeof AuthCadastroFimRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/recuperar-senha': typeof AuthRecuperarSenhaRoute
  '/auth/redefinir-senha': typeof AuthRedefinirSenhaRoute
  '/auth/acesso-negado': typeof AuthAcessoNegadoLazyRoute
  '/auth/conta-bloqueada': typeof AuthContaBloqueadaLazyRoute
  '/auth/solicitar-validacao': typeof AuthSolicitarValidacaoLazyRoute
  '/a/configuracao/empresa': typeof AConfiguracaoEmpresaRouteRouteWithChildren
  '/a/venda/$tipo': typeof AVendaTipoRouteRouteWithChildren
  '/a/cadastro/cliente': typeof ACadastroClienteRoute
  '/a/cadastro/fornecedor': typeof ACadastroFornecedorRoute
  '/a/cadastro/produto': typeof ACadastroProdutoRouteWithChildren
  '/a/cadastro/transportador': typeof ACadastroTransportadorRoute
  '/a/debug/pagination': typeof ADebugPaginationRoute
  '/p/cliente/$slug': typeof PClienteSlugRoute
  '/a/cobranca-boleto/rotinas-bancarias': typeof ACobrancaBoletoRotinasBancariasLazyRoute
  '/a/estoque/conferencia': typeof AEstoqueConferenciaLazyRoute
  '/a/estoque/movimentacao': typeof AEstoqueMovimentacaoLazyRoute
  '/a/impressao/$filetype': typeof AImpressaoFiletypeLazyRoute
  '/a/notificacao/listagem': typeof ANotificacaoListagemLazyRoute
  '/a/suporte/instrucoes-de-uso': typeof ASuporteInstrucoesDeUsoLazyRoute
  '/p/compartilhamento/$id': typeof PCompartilhamentoIdLazyRouteWithChildren
  '/p/contrato/$id': typeof PContratoIdLazyRoute
  '/p/impressao/$filetype': typeof PImpressaoFiletypeLazyRoute
  '/a/cobranca-pix/': typeof ACobrancaPixIndexRoute
  '/a/compra/': typeof ACompraIndexRoute
  '/a/cupom-fiscal/': typeof ACupomFiscalIndexRoute
  '/a/movimentacoes/': typeof AMovimentacoesIndexRoute
  '/a/pdv': typeof APdvIndexRoute
  '/p/erro': typeof PErroIndexRoute
  '/a/cobranca-boleto/': typeof ACobrancaBoletoIndexLazyRoute
  '/a/cobranca-cartao/': typeof ACobrancaCartaoIndexLazyRoute
  '/a/comissao/': typeof AComissaoIndexLazyRoute
  '/a/contas-pagar/': typeof AContasPagarIndexLazyRoute
  '/a/contas-receber/': typeof AContasReceberIndexLazyRoute
  '/a/contrato': typeof AContratoIndexLazyRoute
  '/a/debug/': typeof ADebugIndexLazyRoute
  '/a/extrato-boleto/': typeof AExtratoBoletoIndexLazyRoute
  '/a/extrato-pix/': typeof AExtratoPixIndexLazyRoute
  '/a/manifesto-carga/': typeof AManifestoCargaIndexLazyRoute
  '/a/nota-fiscal/': typeof ANotaFiscalIndexLazyRoute
  '/a/painel-gerencial': typeof APainelGerencialIndexLazyRoute
  '/a/regra-fiscal/': typeof ARegraFiscalIndexLazyRoute
  '/a/romaneio/': typeof ARomaneioIndexLazyRoute
  '/a/suporte': typeof ASuporteIndexLazyRoute
  '/a/venda-externa/': typeof AVendaExternaIndexLazyRoute
  '/a/importacao/cadastro/$tipo': typeof AImportacaoCadastroTipoRouteRoute
  '/a/importacao/xml/$tipo': typeof AImportacaoXmlTipoRouteRoute
  '/p/conta-digital/termos-de-uso/$base64': typeof PContaDigitalTermosDeUsoBase64Route
  '/a/cobranca-boleto/$mode/$idpk': typeof ACobrancaBoletoModeIdpkLazyRoute
  '/a/cobranca-cartao/$mode/$idpk': typeof ACobrancaCartaoModeIdpkLazyRoute
  '/a/compra/$mode/$idpk': typeof ACompraModeIdpkLazyRoute
  '/a/contas-pagar/$mode/$idpk': typeof AContasPagarModeIdpkLazyRoute
  '/a/contas-receber/$mode/$idpk': typeof AContasReceberModeIdpkLazyRoute
  '/a/manifesto-carga/$mode/$idpk': typeof AManifestoCargaModeIdpkLazyRoute
  '/a/movimentacoes/extrato/$idpk': typeof AMovimentacoesExtratoIdpkLazyRoute
  '/a/nota-fiscal/$mode/$idpk': typeof ANotaFiscalModeIdpkLazyRoute
  '/a/venda-externa/$mode/$idpk': typeof AVendaExternaModeIdpkLazyRoute
  '/a/configuracao/empresa/': typeof AConfiguracaoEmpresaIndexRoute
  '/a/relatorio/$tipo': typeof ARelatorioTipoIndexRoute
  '/p/catalogo/$slug': typeof PCatalogoSlugIndexRoute
  '/p/compartilhamento/$id/': typeof PCompartilhamentoIdIndexRoute
  '/p/pagamento/$id': typeof PPagamentoIdIndexRoute
  '/a/configuracao/usuario': typeof AConfiguracaoUsuarioIndexLazyRoute
  '/a/venda/$tipo/': typeof AVendaTipoIndexLazyRoute
  '/p/catalogo/$slug/produto/$idpk': typeof PCatalogoSlugProdutoIdpkRoute
  '/a/cadastro/produto/$mode/$idpk': typeof ACadastroProdutoModeIdpkLazyRoute
  '/a/movimentacoes/$mode/$contaIdpk/$idpk': typeof AMovimentacoesModeContaIdpkIdpkLazyRoute
  '/a/contrato/$mode/$idpk': typeof AContratoModeIdpkIndexRoute
  '/a/romaneio/$mode/$idpk': typeof ARomaneioModeIdpkIndexRoute
  '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute
  '/a/venda/$tipo/$mode/$idpk': typeof AVendaTipoModeIdpkIndexRoute
  '/a/venda/venda-externa/$tipo/$mode/$idpk': typeof AVendaVendaExternaTipoModeIdpkIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/a': typeof ARouteRouteWithChildren
  '/auth': typeof AuthRouteRouteWithChildren
  '/a/estoque': typeof AEstoqueRouteRouteWithChildren
  '/a/notificacao': typeof ANotificacaoRouteRouteWithChildren
  '/auth/cadastro': typeof AuthCadastroRoute
  '/auth/cadastro-fim': typeof AuthCadastroFimRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/recuperar-senha': typeof AuthRecuperarSenhaRoute
  '/auth/redefinir-senha': typeof AuthRedefinirSenhaRoute
  '/auth/acesso-negado': typeof AuthAcessoNegadoLazyRoute
  '/auth/conta-bloqueada': typeof AuthContaBloqueadaLazyRoute
  '/auth/solicitar-validacao': typeof AuthSolicitarValidacaoLazyRoute
  '/a/cadastro/cliente': typeof ACadastroClienteRoute
  '/a/cadastro/fornecedor': typeof ACadastroFornecedorRoute
  '/a/cadastro/produto': typeof ACadastroProdutoRouteWithChildren
  '/a/cadastro/transportador': typeof ACadastroTransportadorRoute
  '/a/debug/pagination': typeof ADebugPaginationRoute
  '/p/cliente/$slug': typeof PClienteSlugRoute
  '/a/cobranca-boleto/rotinas-bancarias': typeof ACobrancaBoletoRotinasBancariasLazyRoute
  '/a/estoque/conferencia': typeof AEstoqueConferenciaLazyRoute
  '/a/estoque/movimentacao': typeof AEstoqueMovimentacaoLazyRoute
  '/a/impressao/$filetype': typeof AImpressaoFiletypeLazyRoute
  '/a/notificacao/listagem': typeof ANotificacaoListagemLazyRoute
  '/a/suporte/instrucoes-de-uso': typeof ASuporteInstrucoesDeUsoLazyRoute
  '/p/contrato/$id': typeof PContratoIdLazyRoute
  '/p/impressao/$filetype': typeof PImpressaoFiletypeLazyRoute
  '/a/cobranca-pix': typeof ACobrancaPixIndexRoute
  '/a/compra': typeof ACompraIndexRoute
  '/a/cupom-fiscal': typeof ACupomFiscalIndexRoute
  '/a/movimentacoes': typeof AMovimentacoesIndexRoute
  '/a/pdv': typeof APdvIndexRoute
  '/p/erro': typeof PErroIndexRoute
  '/a/cobranca-boleto': typeof ACobrancaBoletoIndexLazyRoute
  '/a/cobranca-cartao': typeof ACobrancaCartaoIndexLazyRoute
  '/a/comissao': typeof AComissaoIndexLazyRoute
  '/a/contas-pagar': typeof AContasPagarIndexLazyRoute
  '/a/contas-receber': typeof AContasReceberIndexLazyRoute
  '/a/contrato': typeof AContratoIndexLazyRoute
  '/a/debug': typeof ADebugIndexLazyRoute
  '/a/extrato-boleto': typeof AExtratoBoletoIndexLazyRoute
  '/a/extrato-pix': typeof AExtratoPixIndexLazyRoute
  '/a/manifesto-carga': typeof AManifestoCargaIndexLazyRoute
  '/a/nota-fiscal': typeof ANotaFiscalIndexLazyRoute
  '/a/painel-gerencial': typeof APainelGerencialIndexLazyRoute
  '/a/regra-fiscal': typeof ARegraFiscalIndexLazyRoute
  '/a/romaneio': typeof ARomaneioIndexLazyRoute
  '/a/suporte': typeof ASuporteIndexLazyRoute
  '/a/venda-externa': typeof AVendaExternaIndexLazyRoute
  '/a/importacao/cadastro/$tipo': typeof AImportacaoCadastroTipoRouteRoute
  '/a/importacao/xml/$tipo': typeof AImportacaoXmlTipoRouteRoute
  '/p/conta-digital/termos-de-uso/$base64': typeof PContaDigitalTermosDeUsoBase64Route
  '/a/cobranca-boleto/$mode/$idpk': typeof ACobrancaBoletoModeIdpkLazyRoute
  '/a/cobranca-cartao/$mode/$idpk': typeof ACobrancaCartaoModeIdpkLazyRoute
  '/a/compra/$mode/$idpk': typeof ACompraModeIdpkLazyRoute
  '/a/contas-pagar/$mode/$idpk': typeof AContasPagarModeIdpkLazyRoute
  '/a/contas-receber/$mode/$idpk': typeof AContasReceberModeIdpkLazyRoute
  '/a/manifesto-carga/$mode/$idpk': typeof AManifestoCargaModeIdpkLazyRoute
  '/a/movimentacoes/extrato/$idpk': typeof AMovimentacoesExtratoIdpkLazyRoute
  '/a/nota-fiscal/$mode/$idpk': typeof ANotaFiscalModeIdpkLazyRoute
  '/a/venda-externa/$mode/$idpk': typeof AVendaExternaModeIdpkLazyRoute
  '/a/configuracao/empresa': typeof AConfiguracaoEmpresaIndexRoute
  '/a/relatorio/$tipo': typeof ARelatorioTipoIndexRoute
  '/p/catalogo/$slug': typeof PCatalogoSlugIndexRoute
  '/p/compartilhamento/$id': typeof PCompartilhamentoIdIndexRoute
  '/p/pagamento/$id': typeof PPagamentoIdIndexRoute
  '/a/configuracao/usuario': typeof AConfiguracaoUsuarioIndexLazyRoute
  '/a/venda/$tipo': typeof AVendaTipoIndexLazyRoute
  '/p/catalogo/$slug/produto/$idpk': typeof PCatalogoSlugProdutoIdpkRoute
  '/a/cadastro/produto/$mode/$idpk': typeof ACadastroProdutoModeIdpkLazyRoute
  '/a/movimentacoes/$mode/$contaIdpk/$idpk': typeof AMovimentacoesModeContaIdpkIdpkLazyRoute
  '/a/contrato/$mode/$idpk': typeof AContratoModeIdpkIndexRoute
  '/a/romaneio/$mode/$idpk': typeof ARomaneioModeIdpkIndexRoute
  '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute
  '/a/venda/$tipo/$mode/$idpk': typeof AVendaTipoModeIdpkIndexRoute
  '/a/venda/venda-externa/$tipo/$mode/$idpk': typeof AVendaVendaExternaTipoModeIdpkIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/a': typeof ARouteRouteWithChildren
  '/auth': typeof AuthRouteRouteWithChildren
  '/a/cobranca-boleto': typeof ACobrancaBoletoRouteRouteWithChildren
  '/a/cobranca-cartao': typeof ACobrancaCartaoRouteRouteWithChildren
  '/a/cobranca-pix': typeof ACobrancaPixRouteRouteWithChildren
  '/a/comissao': typeof AComissaoRouteRouteWithChildren
  '/a/compra': typeof ACompraRouteRouteWithChildren
  '/a/contas-pagar': typeof AContasPagarRouteRouteWithChildren
  '/a/contas-receber': typeof AContasReceberRouteRouteWithChildren
  '/a/cupom-fiscal': typeof ACupomFiscalRouteRouteWithChildren
  '/a/debug': typeof ADebugRouteRouteWithChildren
  '/a/estoque': typeof AEstoqueRouteRouteWithChildren
  '/a/extrato-boleto': typeof AExtratoBoletoRouteRouteWithChildren
  '/a/extrato-pix': typeof AExtratoPixRouteRouteWithChildren
  '/a/manifesto-carga': typeof AManifestoCargaRouteRouteWithChildren
  '/a/movimentacoes': typeof AMovimentacoesRouteRouteWithChildren
  '/a/nota-fiscal': typeof ANotaFiscalRouteRouteWithChildren
  '/a/notificacao': typeof ANotificacaoRouteRouteWithChildren
  '/a/regra-fiscal': typeof ARegraFiscalRouteRouteWithChildren
  '/a/romaneio': typeof ARomaneioRouteRouteWithChildren
  '/a/venda-externa': typeof AVendaExternaRouteRouteWithChildren
  '/auth/cadastro': typeof AuthCadastroRoute
  '/auth/cadastro-fim': typeof AuthCadastroFimRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/recuperar-senha': typeof AuthRecuperarSenhaRoute
  '/auth/redefinir-senha': typeof AuthRedefinirSenhaRoute
  '/auth/acesso-negado': typeof AuthAcessoNegadoLazyRoute
  '/auth/conta-bloqueada': typeof AuthContaBloqueadaLazyRoute
  '/auth/solicitar-validacao': typeof AuthSolicitarValidacaoLazyRoute
  '/a/configuracao/empresa': typeof AConfiguracaoEmpresaRouteRouteWithChildren
  '/a/venda/$tipo': typeof AVendaTipoRouteRouteWithChildren
  '/a/cadastro/cliente': typeof ACadastroClienteRoute
  '/a/cadastro/fornecedor': typeof ACadastroFornecedorRoute
  '/a/cadastro/produto': typeof ACadastroProdutoRouteWithChildren
  '/a/cadastro/transportador': typeof ACadastroTransportadorRoute
  '/a/debug/pagination': typeof ADebugPaginationRoute
  '/p/cliente/$slug': typeof PClienteSlugRoute
  '/a/cobranca-boleto/rotinas-bancarias': typeof ACobrancaBoletoRotinasBancariasLazyRoute
  '/a/estoque/conferencia': typeof AEstoqueConferenciaLazyRoute
  '/a/estoque/movimentacao': typeof AEstoqueMovimentacaoLazyRoute
  '/a/impressao/$filetype': typeof AImpressaoFiletypeLazyRoute
  '/a/notificacao/listagem': typeof ANotificacaoListagemLazyRoute
  '/a/suporte/instrucoes-de-uso': typeof ASuporteInstrucoesDeUsoLazyRoute
  '/p/compartilhamento/$id': typeof PCompartilhamentoIdLazyRouteWithChildren
  '/p/contrato/$id': typeof PContratoIdLazyRoute
  '/p/impressao/$filetype': typeof PImpressaoFiletypeLazyRoute
  '/a/cobranca-pix/': typeof ACobrancaPixIndexRoute
  '/a/compra/': typeof ACompraIndexRoute
  '/a/cupom-fiscal/': typeof ACupomFiscalIndexRoute
  '/a/movimentacoes/': typeof AMovimentacoesIndexRoute
  '/a/pdv/': typeof APdvIndexRoute
  '/p/erro/': typeof PErroIndexRoute
  '/a/cobranca-boleto/': typeof ACobrancaBoletoIndexLazyRoute
  '/a/cobranca-cartao/': typeof ACobrancaCartaoIndexLazyRoute
  '/a/comissao/': typeof AComissaoIndexLazyRoute
  '/a/contas-pagar/': typeof AContasPagarIndexLazyRoute
  '/a/contas-receber/': typeof AContasReceberIndexLazyRoute
  '/a/contrato/': typeof AContratoIndexLazyRoute
  '/a/debug/': typeof ADebugIndexLazyRoute
  '/a/extrato-boleto/': typeof AExtratoBoletoIndexLazyRoute
  '/a/extrato-pix/': typeof AExtratoPixIndexLazyRoute
  '/a/manifesto-carga/': typeof AManifestoCargaIndexLazyRoute
  '/a/nota-fiscal/': typeof ANotaFiscalIndexLazyRoute
  '/a/painel-gerencial/': typeof APainelGerencialIndexLazyRoute
  '/a/regra-fiscal/': typeof ARegraFiscalIndexLazyRoute
  '/a/romaneio/': typeof ARomaneioIndexLazyRoute
  '/a/suporte/': typeof ASuporteIndexLazyRoute
  '/a/venda-externa/': typeof AVendaExternaIndexLazyRoute
  '/a/importacao/cadastro/$tipo': typeof AImportacaoCadastroTipoRouteRoute
  '/a/importacao/xml/$tipo': typeof AImportacaoXmlTipoRouteRoute
  '/p/conta-digital/termos-de-uso/$base64': typeof PContaDigitalTermosDeUsoBase64Route
  '/a/cobranca-boleto/$mode/$idpk': typeof ACobrancaBoletoModeIdpkLazyRoute
  '/a/cobranca-cartao/$mode/$idpk': typeof ACobrancaCartaoModeIdpkLazyRoute
  '/a/compra/$mode/$idpk': typeof ACompraModeIdpkLazyRoute
  '/a/contas-pagar/$mode/$idpk': typeof AContasPagarModeIdpkLazyRoute
  '/a/contas-receber/$mode/$idpk': typeof AContasReceberModeIdpkLazyRoute
  '/a/manifesto-carga/$mode/$idpk': typeof AManifestoCargaModeIdpkLazyRoute
  '/a/movimentacoes/extrato/$idpk': typeof AMovimentacoesExtratoIdpkLazyRoute
  '/a/nota-fiscal/$mode/$idpk': typeof ANotaFiscalModeIdpkLazyRoute
  '/a/venda-externa/$mode/$idpk': typeof AVendaExternaModeIdpkLazyRoute
  '/a/configuracao/empresa/': typeof AConfiguracaoEmpresaIndexRoute
  '/a/relatorio/$tipo/': typeof ARelatorioTipoIndexRoute
  '/p/catalogo/$slug/': typeof PCatalogoSlugIndexRoute
  '/p/compartilhamento/$id/': typeof PCompartilhamentoIdIndexRoute
  '/p/pagamento/$id/': typeof PPagamentoIdIndexRoute
  '/a/configuracao/usuario/': typeof AConfiguracaoUsuarioIndexLazyRoute
  '/a/venda/$tipo/': typeof AVendaTipoIndexLazyRoute
  '/p/catalogo/$slug/produto/$idpk': typeof PCatalogoSlugProdutoIdpkRoute
  '/a/cadastro/produto/$mode/$idpk': typeof ACadastroProdutoModeIdpkLazyRoute
  '/a/movimentacoes/$mode/$contaIdpk/$idpk': typeof AMovimentacoesModeContaIdpkIdpkLazyRoute
  '/a/contrato/$mode/$idpk/': typeof AContratoModeIdpkIndexRoute
  '/a/romaneio/$mode/$idpk/': typeof ARomaneioModeIdpkIndexRoute
  '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkLazyRoute
  '/a/venda/$tipo/$mode/$idpk/': typeof AVendaTipoModeIdpkIndexRoute
  '/a/venda/venda-externa/$tipo/$mode/$idpk/': typeof AVendaVendaExternaTipoModeIdpkIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/a'
    | '/auth'
    | '/a/cobranca-boleto'
    | '/a/cobranca-cartao'
    | '/a/cobranca-pix'
    | '/a/comissao'
    | '/a/compra'
    | '/a/contas-pagar'
    | '/a/contas-receber'
    | '/a/cupom-fiscal'
    | '/a/debug'
    | '/a/estoque'
    | '/a/extrato-boleto'
    | '/a/extrato-pix'
    | '/a/manifesto-carga'
    | '/a/movimentacoes'
    | '/a/nota-fiscal'
    | '/a/notificacao'
    | '/a/regra-fiscal'
    | '/a/romaneio'
    | '/a/venda-externa'
    | '/auth/cadastro'
    | '/auth/cadastro-fim'
    | '/auth/login'
    | '/auth/recuperar-senha'
    | '/auth/redefinir-senha'
    | '/auth/acesso-negado'
    | '/auth/conta-bloqueada'
    | '/auth/solicitar-validacao'
    | '/a/configuracao/empresa'
    | '/a/venda/$tipo'
    | '/a/cadastro/cliente'
    | '/a/cadastro/fornecedor'
    | '/a/cadastro/produto'
    | '/a/cadastro/transportador'
    | '/a/debug/pagination'
    | '/p/cliente/$slug'
    | '/a/cobranca-boleto/rotinas-bancarias'
    | '/a/estoque/conferencia'
    | '/a/estoque/movimentacao'
    | '/a/impressao/$filetype'
    | '/a/notificacao/listagem'
    | '/a/suporte/instrucoes-de-uso'
    | '/p/compartilhamento/$id'
    | '/p/contrato/$id'
    | '/p/impressao/$filetype'
    | '/a/cobranca-pix/'
    | '/a/compra/'
    | '/a/cupom-fiscal/'
    | '/a/movimentacoes/'
    | '/a/pdv'
    | '/p/erro'
    | '/a/cobranca-boleto/'
    | '/a/cobranca-cartao/'
    | '/a/comissao/'
    | '/a/contas-pagar/'
    | '/a/contas-receber/'
    | '/a/contrato'
    | '/a/debug/'
    | '/a/extrato-boleto/'
    | '/a/extrato-pix/'
    | '/a/manifesto-carga/'
    | '/a/nota-fiscal/'
    | '/a/painel-gerencial'
    | '/a/regra-fiscal/'
    | '/a/romaneio/'
    | '/a/suporte'
    | '/a/venda-externa/'
    | '/a/importacao/cadastro/$tipo'
    | '/a/importacao/xml/$tipo'
    | '/p/conta-digital/termos-de-uso/$base64'
    | '/a/cobranca-boleto/$mode/$idpk'
    | '/a/cobranca-cartao/$mode/$idpk'
    | '/a/compra/$mode/$idpk'
    | '/a/contas-pagar/$mode/$idpk'
    | '/a/contas-receber/$mode/$idpk'
    | '/a/manifesto-carga/$mode/$idpk'
    | '/a/movimentacoes/extrato/$idpk'
    | '/a/nota-fiscal/$mode/$idpk'
    | '/a/venda-externa/$mode/$idpk'
    | '/a/configuracao/empresa/'
    | '/a/relatorio/$tipo'
    | '/p/catalogo/$slug'
    | '/p/compartilhamento/$id/'
    | '/p/pagamento/$id'
    | '/a/configuracao/usuario'
    | '/a/venda/$tipo/'
    | '/p/catalogo/$slug/produto/$idpk'
    | '/a/cadastro/produto/$mode/$idpk'
    | '/a/movimentacoes/$mode/$contaIdpk/$idpk'
    | '/a/contrato/$mode/$idpk'
    | '/a/romaneio/$mode/$idpk'
    | '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk'
    | '/a/venda/$tipo/$mode/$idpk'
    | '/a/venda/venda-externa/$tipo/$mode/$idpk'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/a'
    | '/auth'
    | '/a/estoque'
    | '/a/notificacao'
    | '/auth/cadastro'
    | '/auth/cadastro-fim'
    | '/auth/login'
    | '/auth/recuperar-senha'
    | '/auth/redefinir-senha'
    | '/auth/acesso-negado'
    | '/auth/conta-bloqueada'
    | '/auth/solicitar-validacao'
    | '/a/cadastro/cliente'
    | '/a/cadastro/fornecedor'
    | '/a/cadastro/produto'
    | '/a/cadastro/transportador'
    | '/a/debug/pagination'
    | '/p/cliente/$slug'
    | '/a/cobranca-boleto/rotinas-bancarias'
    | '/a/estoque/conferencia'
    | '/a/estoque/movimentacao'
    | '/a/impressao/$filetype'
    | '/a/notificacao/listagem'
    | '/a/suporte/instrucoes-de-uso'
    | '/p/contrato/$id'
    | '/p/impressao/$filetype'
    | '/a/cobranca-pix'
    | '/a/compra'
    | '/a/cupom-fiscal'
    | '/a/movimentacoes'
    | '/a/pdv'
    | '/p/erro'
    | '/a/cobranca-boleto'
    | '/a/cobranca-cartao'
    | '/a/comissao'
    | '/a/contas-pagar'
    | '/a/contas-receber'
    | '/a/contrato'
    | '/a/debug'
    | '/a/extrato-boleto'
    | '/a/extrato-pix'
    | '/a/manifesto-carga'
    | '/a/nota-fiscal'
    | '/a/painel-gerencial'
    | '/a/regra-fiscal'
    | '/a/romaneio'
    | '/a/suporte'
    | '/a/venda-externa'
    | '/a/importacao/cadastro/$tipo'
    | '/a/importacao/xml/$tipo'
    | '/p/conta-digital/termos-de-uso/$base64'
    | '/a/cobranca-boleto/$mode/$idpk'
    | '/a/cobranca-cartao/$mode/$idpk'
    | '/a/compra/$mode/$idpk'
    | '/a/contas-pagar/$mode/$idpk'
    | '/a/contas-receber/$mode/$idpk'
    | '/a/manifesto-carga/$mode/$idpk'
    | '/a/movimentacoes/extrato/$idpk'
    | '/a/nota-fiscal/$mode/$idpk'
    | '/a/venda-externa/$mode/$idpk'
    | '/a/configuracao/empresa'
    | '/a/relatorio/$tipo'
    | '/p/catalogo/$slug'
    | '/p/compartilhamento/$id'
    | '/p/pagamento/$id'
    | '/a/configuracao/usuario'
    | '/a/venda/$tipo'
    | '/p/catalogo/$slug/produto/$idpk'
    | '/a/cadastro/produto/$mode/$idpk'
    | '/a/movimentacoes/$mode/$contaIdpk/$idpk'
    | '/a/contrato/$mode/$idpk'
    | '/a/romaneio/$mode/$idpk'
    | '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk'
    | '/a/venda/$tipo/$mode/$idpk'
    | '/a/venda/venda-externa/$tipo/$mode/$idpk'
  id:
    | '__root__'
    | '/'
    | '/a'
    | '/auth'
    | '/a/cobranca-boleto'
    | '/a/cobranca-cartao'
    | '/a/cobranca-pix'
    | '/a/comissao'
    | '/a/compra'
    | '/a/contas-pagar'
    | '/a/contas-receber'
    | '/a/cupom-fiscal'
    | '/a/debug'
    | '/a/estoque'
    | '/a/extrato-boleto'
    | '/a/extrato-pix'
    | '/a/manifesto-carga'
    | '/a/movimentacoes'
    | '/a/nota-fiscal'
    | '/a/notificacao'
    | '/a/regra-fiscal'
    | '/a/romaneio'
    | '/a/venda-externa'
    | '/auth/cadastro'
    | '/auth/cadastro-fim'
    | '/auth/login'
    | '/auth/recuperar-senha'
    | '/auth/redefinir-senha'
    | '/auth/acesso-negado'
    | '/auth/conta-bloqueada'
    | '/auth/solicitar-validacao'
    | '/a/configuracao/empresa'
    | '/a/venda/$tipo'
    | '/a/cadastro/cliente'
    | '/a/cadastro/fornecedor'
    | '/a/cadastro/produto'
    | '/a/cadastro/transportador'
    | '/a/debug/pagination'
    | '/p/cliente/$slug'
    | '/a/cobranca-boleto/rotinas-bancarias'
    | '/a/estoque/conferencia'
    | '/a/estoque/movimentacao'
    | '/a/impressao/$filetype'
    | '/a/notificacao/listagem'
    | '/a/suporte/instrucoes-de-uso'
    | '/p/compartilhamento/$id'
    | '/p/contrato/$id'
    | '/p/impressao/$filetype'
    | '/a/cobranca-pix/'
    | '/a/compra/'
    | '/a/cupom-fiscal/'
    | '/a/movimentacoes/'
    | '/a/pdv/'
    | '/p/erro/'
    | '/a/cobranca-boleto/'
    | '/a/cobranca-cartao/'
    | '/a/comissao/'
    | '/a/contas-pagar/'
    | '/a/contas-receber/'
    | '/a/contrato/'
    | '/a/debug/'
    | '/a/extrato-boleto/'
    | '/a/extrato-pix/'
    | '/a/manifesto-carga/'
    | '/a/nota-fiscal/'
    | '/a/painel-gerencial/'
    | '/a/regra-fiscal/'
    | '/a/romaneio/'
    | '/a/suporte/'
    | '/a/venda-externa/'
    | '/a/importacao/cadastro/$tipo'
    | '/a/importacao/xml/$tipo'
    | '/p/conta-digital/termos-de-uso/$base64'
    | '/a/cobranca-boleto/$mode/$idpk'
    | '/a/cobranca-cartao/$mode/$idpk'
    | '/a/compra/$mode/$idpk'
    | '/a/contas-pagar/$mode/$idpk'
    | '/a/contas-receber/$mode/$idpk'
    | '/a/manifesto-carga/$mode/$idpk'
    | '/a/movimentacoes/extrato/$idpk'
    | '/a/nota-fiscal/$mode/$idpk'
    | '/a/venda-externa/$mode/$idpk'
    | '/a/configuracao/empresa/'
    | '/a/relatorio/$tipo/'
    | '/p/catalogo/$slug/'
    | '/p/compartilhamento/$id/'
    | '/p/pagamento/$id/'
    | '/a/configuracao/usuario/'
    | '/a/venda/$tipo/'
    | '/p/catalogo/$slug/produto/$idpk'
    | '/a/cadastro/produto/$mode/$idpk'
    | '/a/movimentacoes/$mode/$contaIdpk/$idpk'
    | '/a/contrato/$mode/$idpk/'
    | '/a/romaneio/$mode/$idpk/'
    | '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk'
    | '/a/venda/$tipo/$mode/$idpk/'
    | '/a/venda/venda-externa/$tipo/$mode/$idpk/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ARouteRoute: typeof ARouteRouteWithChildren
  AuthRouteRoute: typeof AuthRouteRouteWithChildren
  PClienteSlugRoute: typeof PClienteSlugRoute
  PCompartilhamentoIdLazyRoute: typeof PCompartilhamentoIdLazyRouteWithChildren
  PContratoIdLazyRoute: typeof PContratoIdLazyRoute
  PImpressaoFiletypeLazyRoute: typeof PImpressaoFiletypeLazyRoute
  PErroIndexRoute: typeof PErroIndexRoute
  PContaDigitalTermosDeUsoBase64Route: typeof PContaDigitalTermosDeUsoBase64Route
  PCatalogoSlugIndexRoute: typeof PCatalogoSlugIndexRoute
  PPagamentoIdIndexRoute: typeof PPagamentoIdIndexRoute
  PCatalogoSlugProdutoIdpkRoute: typeof PCatalogoSlugProdutoIdpkRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ARouteRoute: ARouteRouteWithChildren,
  AuthRouteRoute: AuthRouteRouteWithChildren,
  PClienteSlugRoute: PClienteSlugRoute,
  PCompartilhamentoIdLazyRoute: PCompartilhamentoIdLazyRouteWithChildren,
  PContratoIdLazyRoute: PContratoIdLazyRoute,
  PImpressaoFiletypeLazyRoute: PImpressaoFiletypeLazyRoute,
  PErroIndexRoute: PErroIndexRoute,
  PContaDigitalTermosDeUsoBase64Route: PContaDigitalTermosDeUsoBase64Route,
  PCatalogoSlugIndexRoute: PCatalogoSlugIndexRoute,
  PPagamentoIdIndexRoute: PPagamentoIdIndexRoute,
  PCatalogoSlugProdutoIdpkRoute: PCatalogoSlugProdutoIdpkRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/a",
        "/auth",
        "/p/cliente/$slug",
        "/p/compartilhamento/$id",
        "/p/contrato/$id",
        "/p/impressao/$filetype",
        "/p/erro/",
        "/p/conta-digital/termos-de-uso/$base64",
        "/p/catalogo/$slug/",
        "/p/pagamento/$id/",
        "/p/catalogo/$slug/produto/$idpk"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/a": {
      "filePath": "a/route.tsx",
      "children": [
        "/a/cobranca-boleto",
        "/a/cobranca-cartao",
        "/a/cobranca-pix",
        "/a/comissao",
        "/a/compra",
        "/a/contas-pagar",
        "/a/contas-receber",
        "/a/cupom-fiscal",
        "/a/debug",
        "/a/estoque",
        "/a/extrato-boleto",
        "/a/extrato-pix",
        "/a/manifesto-carga",
        "/a/movimentacoes",
        "/a/nota-fiscal",
        "/a/notificacao",
        "/a/regra-fiscal",
        "/a/romaneio",
        "/a/venda-externa",
        "/a/configuracao/empresa",
        "/a/venda/$tipo",
        "/a/cadastro/cliente",
        "/a/cadastro/fornecedor",
        "/a/cadastro/produto",
        "/a/cadastro/transportador",
        "/a/impressao/$filetype",
        "/a/suporte/instrucoes-de-uso",
        "/a/pdv/",
        "/a/contrato/",
        "/a/painel-gerencial/",
        "/a/suporte/",
        "/a/importacao/cadastro/$tipo",
        "/a/importacao/xml/$tipo",
        "/a/relatorio/$tipo/",
        "/a/configuracao/usuario/",
        "/a/contrato/$mode/$idpk/",
        "/a/venda/venda-externa/$tipo/$mode/$idpk/"
      ]
    },
    "/auth": {
      "filePath": "auth/route.tsx",
      "children": [
        "/auth/cadastro",
        "/auth/cadastro-fim",
        "/auth/login",
        "/auth/recuperar-senha",
        "/auth/redefinir-senha",
        "/auth/acesso-negado",
        "/auth/conta-bloqueada",
        "/auth/solicitar-validacao"
      ]
    },
    "/a/cobranca-boleto": {
      "filePath": "a/cobranca-boleto/route.tsx",
      "parent": "/a",
      "children": [
        "/a/cobranca-boleto/rotinas-bancarias",
        "/a/cobranca-boleto/",
        "/a/cobranca-boleto/$mode/$idpk"
      ]
    },
    "/a/cobranca-cartao": {
      "filePath": "a/cobranca-cartao/route.tsx",
      "parent": "/a",
      "children": [
        "/a/cobranca-cartao/",
        "/a/cobranca-cartao/$mode/$idpk"
      ]
    },
    "/a/cobranca-pix": {
      "filePath": "a/cobranca-pix/route.tsx",
      "parent": "/a",
      "children": [
        "/a/cobranca-pix/"
      ]
    },
    "/a/comissao": {
      "filePath": "a/comissao/route.tsx",
      "parent": "/a",
      "children": [
        "/a/comissao/"
      ]
    },
    "/a/compra": {
      "filePath": "a/compra/route.tsx",
      "parent": "/a",
      "children": [
        "/a/compra/",
        "/a/compra/$mode/$idpk"
      ]
    },
    "/a/contas-pagar": {
      "filePath": "a/contas-pagar/route.tsx",
      "parent": "/a",
      "children": [
        "/a/contas-pagar/",
        "/a/contas-pagar/$mode/$idpk"
      ]
    },
    "/a/contas-receber": {
      "filePath": "a/contas-receber/route.tsx",
      "parent": "/a",
      "children": [
        "/a/contas-receber/",
        "/a/contas-receber/$mode/$idpk"
      ]
    },
    "/a/cupom-fiscal": {
      "filePath": "a/cupom-fiscal/route.tsx",
      "parent": "/a",
      "children": [
        "/a/cupom-fiscal/"
      ]
    },
    "/a/debug": {
      "filePath": "a/debug/route.tsx",
      "parent": "/a",
      "children": [
        "/a/debug/pagination",
        "/a/debug/"
      ]
    },
    "/a/estoque": {
      "filePath": "a/estoque/route.tsx",
      "parent": "/a",
      "children": [
        "/a/estoque/conferencia",
        "/a/estoque/movimentacao"
      ]
    },
    "/a/extrato-boleto": {
      "filePath": "a/extrato-boleto/route.tsx",
      "parent": "/a",
      "children": [
        "/a/extrato-boleto/"
      ]
    },
    "/a/extrato-pix": {
      "filePath": "a/extrato-pix/route.tsx",
      "parent": "/a",
      "children": [
        "/a/extrato-pix/"
      ]
    },
    "/a/manifesto-carga": {
      "filePath": "a/manifesto-carga/route.tsx",
      "parent": "/a",
      "children": [
        "/a/manifesto-carga/",
        "/a/manifesto-carga/$mode/$idpk"
      ]
    },
    "/a/movimentacoes": {
      "filePath": "a/movimentacoes/route.tsx",
      "parent": "/a",
      "children": [
        "/a/movimentacoes/",
        "/a/movimentacoes/extrato/$idpk",
        "/a/movimentacoes/$mode/$contaIdpk/$idpk"
      ]
    },
    "/a/nota-fiscal": {
      "filePath": "a/nota-fiscal/route.tsx",
      "parent": "/a",
      "children": [
        "/a/nota-fiscal/",
        "/a/nota-fiscal/$mode/$idpk",
        "/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk"
      ]
    },
    "/a/notificacao": {
      "filePath": "a/notificacao/route.tsx",
      "parent": "/a",
      "children": [
        "/a/notificacao/listagem"
      ]
    },
    "/a/regra-fiscal": {
      "filePath": "a/regra-fiscal/route.tsx",
      "parent": "/a",
      "children": [
        "/a/regra-fiscal/"
      ]
    },
    "/a/romaneio": {
      "filePath": "a/romaneio/route.tsx",
      "parent": "/a",
      "children": [
        "/a/romaneio/",
        "/a/romaneio/$mode/$idpk/"
      ]
    },
    "/a/venda-externa": {
      "filePath": "a/venda-externa/route.tsx",
      "parent": "/a",
      "children": [
        "/a/venda-externa/",
        "/a/venda-externa/$mode/$idpk"
      ]
    },
    "/auth/cadastro": {
      "filePath": "auth/cadastro.ts",
      "parent": "/auth"
    },
    "/auth/cadastro-fim": {
      "filePath": "auth/cadastro-fim.ts",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/auth/recuperar-senha": {
      "filePath": "auth/recuperar-senha.tsx",
      "parent": "/auth"
    },
    "/auth/redefinir-senha": {
      "filePath": "auth/redefinir-senha.tsx",
      "parent": "/auth"
    },
    "/auth/acesso-negado": {
      "filePath": "auth/acesso-negado.lazy.tsx",
      "parent": "/auth"
    },
    "/auth/conta-bloqueada": {
      "filePath": "auth/conta-bloqueada.lazy.tsx",
      "parent": "/auth"
    },
    "/auth/solicitar-validacao": {
      "filePath": "auth/solicitar-validacao.lazy.tsx",
      "parent": "/auth"
    },
    "/a/configuracao/empresa": {
      "filePath": "a/configuracao/empresa/route.tsx",
      "parent": "/a",
      "children": [
        "/a/configuracao/empresa/"
      ]
    },
    "/a/venda/$tipo": {
      "filePath": "a/venda/$tipo/route.ts",
      "parent": "/a",
      "children": [
        "/a/venda/$tipo/",
        "/a/venda/$tipo/$mode/$idpk/"
      ]
    },
    "/a/cadastro/cliente": {
      "filePath": "a/cadastro/cliente.tsx",
      "parent": "/a"
    },
    "/a/cadastro/fornecedor": {
      "filePath": "a/cadastro/fornecedor.tsx",
      "parent": "/a"
    },
    "/a/cadastro/produto": {
      "filePath": "a/cadastro/produto.tsx",
      "parent": "/a",
      "children": [
        "/a/cadastro/produto/$mode/$idpk"
      ]
    },
    "/a/cadastro/transportador": {
      "filePath": "a/cadastro/transportador.tsx",
      "parent": "/a"
    },
    "/a/debug/pagination": {
      "filePath": "a/debug/pagination.tsx",
      "parent": "/a/debug"
    },
    "/p/cliente/$slug": {
      "filePath": "p/cliente/$slug.ts"
    },
    "/a/cobranca-boleto/rotinas-bancarias": {
      "filePath": "a/cobranca-boleto/rotinas-bancarias.lazy.tsx",
      "parent": "/a/cobranca-boleto"
    },
    "/a/estoque/conferencia": {
      "filePath": "a/estoque/conferencia.lazy.tsx",
      "parent": "/a/estoque"
    },
    "/a/estoque/movimentacao": {
      "filePath": "a/estoque/movimentacao.lazy.tsx",
      "parent": "/a/estoque"
    },
    "/a/impressao/$filetype": {
      "filePath": "a/impressao/$filetype.lazy.tsx",
      "parent": "/a"
    },
    "/a/notificacao/listagem": {
      "filePath": "a/notificacao/listagem.lazy.tsx",
      "parent": "/a/notificacao"
    },
    "/a/suporte/instrucoes-de-uso": {
      "filePath": "a/suporte/instrucoes-de-uso.lazy.tsx",
      "parent": "/a"
    },
    "/p/compartilhamento/$id": {
      "filePath": "p/compartilhamento/$id.lazy.tsx",
      "children": [
        "/p/compartilhamento/$id/"
      ]
    },
    "/p/contrato/$id": {
      "filePath": "p/contrato.$id.lazy.tsx"
    },
    "/p/impressao/$filetype": {
      "filePath": "p/impressao.$filetype.lazy.tsx"
    },
    "/a/cobranca-pix/": {
      "filePath": "a/cobranca-pix/index.tsx",
      "parent": "/a/cobranca-pix"
    },
    "/a/compra/": {
      "filePath": "a/compra/index.tsx",
      "parent": "/a/compra"
    },
    "/a/cupom-fiscal/": {
      "filePath": "a/cupom-fiscal/index.ts",
      "parent": "/a/cupom-fiscal"
    },
    "/a/movimentacoes/": {
      "filePath": "a/movimentacoes/index.tsx",
      "parent": "/a/movimentacoes"
    },
    "/a/pdv/": {
      "filePath": "a/pdv/index.tsx",
      "parent": "/a"
    },
    "/p/erro/": {
      "filePath": "p/erro/index.tsx"
    },
    "/a/cobranca-boleto/": {
      "filePath": "a/cobranca-boleto/index.lazy.tsx",
      "parent": "/a/cobranca-boleto"
    },
    "/a/cobranca-cartao/": {
      "filePath": "a/cobranca-cartao/index.lazy.tsx",
      "parent": "/a/cobranca-cartao"
    },
    "/a/comissao/": {
      "filePath": "a/comissao/index.lazy.tsx",
      "parent": "/a/comissao"
    },
    "/a/contas-pagar/": {
      "filePath": "a/contas-pagar/index.lazy.tsx",
      "parent": "/a/contas-pagar"
    },
    "/a/contas-receber/": {
      "filePath": "a/contas-receber/index.lazy.tsx",
      "parent": "/a/contas-receber"
    },
    "/a/contrato/": {
      "filePath": "a/contrato/index.lazy.tsx",
      "parent": "/a"
    },
    "/a/debug/": {
      "filePath": "a/debug/index.lazy.tsx",
      "parent": "/a/debug"
    },
    "/a/extrato-boleto/": {
      "filePath": "a/extrato-boleto/index.lazy.tsx",
      "parent": "/a/extrato-boleto"
    },
    "/a/extrato-pix/": {
      "filePath": "a/extrato-pix/index.lazy.tsx",
      "parent": "/a/extrato-pix"
    },
    "/a/manifesto-carga/": {
      "filePath": "a/manifesto-carga/index.lazy.tsx",
      "parent": "/a/manifesto-carga"
    },
    "/a/nota-fiscal/": {
      "filePath": "a/nota-fiscal/index.lazy.tsx",
      "parent": "/a/nota-fiscal"
    },
    "/a/painel-gerencial/": {
      "filePath": "a/painel-gerencial/index.lazy.tsx",
      "parent": "/a"
    },
    "/a/regra-fiscal/": {
      "filePath": "a/regra-fiscal/index.lazy.tsx",
      "parent": "/a/regra-fiscal"
    },
    "/a/romaneio/": {
      "filePath": "a/romaneio/index.lazy.tsx",
      "parent": "/a/romaneio"
    },
    "/a/suporte/": {
      "filePath": "a/suporte/index.lazy.tsx",
      "parent": "/a"
    },
    "/a/venda-externa/": {
      "filePath": "a/venda-externa/index.lazy.tsx",
      "parent": "/a/venda-externa"
    },
    "/a/importacao/cadastro/$tipo": {
      "filePath": "a/importacao/cadastro.$tipo.route.ts",
      "parent": "/a"
    },
    "/a/importacao/xml/$tipo": {
      "filePath": "a/importacao/xml.$tipo.route.ts",
      "parent": "/a"
    },
    "/p/conta-digital/termos-de-uso/$base64": {
      "filePath": "p/conta-digital/termos-de-uso.$base64.tsx"
    },
    "/a/cobranca-boleto/$mode/$idpk": {
      "filePath": "a/cobranca-boleto/$mode.$idpk.lazy.tsx",
      "parent": "/a/cobranca-boleto"
    },
    "/a/cobranca-cartao/$mode/$idpk": {
      "filePath": "a/cobranca-cartao/$mode.$idpk.lazy.tsx",
      "parent": "/a/cobranca-cartao"
    },
    "/a/compra/$mode/$idpk": {
      "filePath": "a/compra/$mode.$idpk.lazy.tsx",
      "parent": "/a/compra"
    },
    "/a/contas-pagar/$mode/$idpk": {
      "filePath": "a/contas-pagar/$mode.$idpk.lazy.tsx",
      "parent": "/a/contas-pagar"
    },
    "/a/contas-receber/$mode/$idpk": {
      "filePath": "a/contas-receber/$mode.$idpk.lazy.tsx",
      "parent": "/a/contas-receber"
    },
    "/a/manifesto-carga/$mode/$idpk": {
      "filePath": "a/manifesto-carga/$mode.$idpk.lazy.tsx",
      "parent": "/a/manifesto-carga"
    },
    "/a/movimentacoes/extrato/$idpk": {
      "filePath": "a/movimentacoes/extrato.$idpk.lazy.tsx",
      "parent": "/a/movimentacoes"
    },
    "/a/nota-fiscal/$mode/$idpk": {
      "filePath": "a/nota-fiscal/$mode.$idpk.lazy.tsx",
      "parent": "/a/nota-fiscal"
    },
    "/a/venda-externa/$mode/$idpk": {
      "filePath": "a/venda-externa/$mode.$idpk.lazy.tsx",
      "parent": "/a/venda-externa"
    },
    "/a/configuracao/empresa/": {
      "filePath": "a/configuracao/empresa/index.ts",
      "parent": "/a/configuracao/empresa"
    },
    "/a/relatorio/$tipo/": {
      "filePath": "a/relatorio/$tipo/index.ts",
      "parent": "/a"
    },
    "/p/catalogo/$slug/": {
      "filePath": "p/catalogo.$slug/index.ts"
    },
    "/p/compartilhamento/$id/": {
      "filePath": "p/compartilhamento/$id.index.ts",
      "parent": "/p/compartilhamento/$id"
    },
    "/p/pagamento/$id/": {
      "filePath": "p/pagamento.$id/index.ts"
    },
    "/a/configuracao/usuario/": {
      "filePath": "a/configuracao/usuario/index.lazy.tsx",
      "parent": "/a"
    },
    "/a/venda/$tipo/": {
      "filePath": "a/venda/$tipo/index.lazy.ts",
      "parent": "/a/venda/$tipo"
    },
    "/p/catalogo/$slug/produto/$idpk": {
      "filePath": "p/catalogo.$slug/produto.$idpk.ts"
    },
    "/a/cadastro/produto/$mode/$idpk": {
      "filePath": "a/cadastro/produto.$mode.$idpk.lazy.tsx",
      "parent": "/a/cadastro/produto"
    },
    "/a/movimentacoes/$mode/$contaIdpk/$idpk": {
      "filePath": "a/movimentacoes/$mode.$contaIdpk.$idpk.lazy.tsx",
      "parent": "/a/movimentacoes"
    },
    "/a/contrato/$mode/$idpk/": {
      "filePath": "a/contrato/$mode.$idpk/index.ts",
      "parent": "/a"
    },
    "/a/romaneio/$mode/$idpk/": {
      "filePath": "a/romaneio/$mode.$idpk/index.ts",
      "parent": "/a/romaneio"
    },
    "/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk": {
      "filePath": "a/nota-fiscal/venda-externa/$tipoVendaExterna.$mode.$veeIdpk.lazy.tsx",
      "parent": "/a/nota-fiscal"
    },
    "/a/venda/$tipo/$mode/$idpk/": {
      "filePath": "a/venda/$tipo/$mode.$idpk/index.ts",
      "parent": "/a/venda/$tipo"
    },
    "/a/venda/venda-externa/$tipo/$mode/$idpk/": {
      "filePath": "a/venda/venda-externa.$tipo.$mode.$idpk/index.ts",
      "parent": "/a"
    }
  }
}
ROUTE_MANIFEST_END */
