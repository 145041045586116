import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { Spin } from 'antd';
import { Helmet } from 'components/revisar/Helmet';
import {
    type ApiFintechTermosDeUsoGetParams,
    apiFintechTermosDeUsoGet,
} from 'features/conta-digital/api/apiFintechTermosDeUso';
import { useMemo } from 'react';
import { base64ToStr } from 'std/base64';
import { ContaDigitaltermoDeUsoContent } from './ContaDigitaltermoDeUsoContent';
import { ContaDigitaltermoDeUsoHeader } from './ContaDigitaltermoDeUsoHeader';

const title = 'Aceite dos termos de uso e da criação da conta';

export function ContaDigitalTermoDeUso() {
    const { base64 } = useParams({ from: '/p/conta-digital/termos-de-uso/$base64' });

    const params: ApiFintechTermosDeUsoGetParams = useMemo(() => {
        const decoded = base64ToStr(base64);

        if (!decoded.includes(':')) {
            return {
                empresa_idpk: 0,
                empresa_documento: '',
                url: '',
            };
        }

        const split = decoded.split(':');

        return {
            empresa_idpk: Number(split[0]) || 0,
            empresa_documento: split[1] || '',
            url: window.location.origin,
        };
    }, [base64]);

    const { data, isFetching } = useQuery({
        queryKey: ['apiFintechTermosDeUso', params],
        queryFn: () => apiFintechTermosDeUsoGet({ params }),
        enabled: params.empresa_idpk !== 0 && params.empresa_documento !== '',
    });

    const { sistema, fintech } = data || {};

    return (
        <>
            <Helmet title={title} />

            <div className='flex items-center justify-center w-screen h-screen bg-gray-100'>
                <div className='w-full max-w-[800px]'>
                    <Spin spinning={isFetching}>
                        {sistema && <ContaDigitaltermoDeUsoHeader sistema={sistema} />}
                        {sistema && fintech && (
                            <ContaDigitaltermoDeUsoContent
                                title={title}
                                params={params}
                                fintech={fintech}
                                sistema={sistema}
                            />
                        )}
                    </Spin>
                </div>
            </div>
        </>
    );
}
