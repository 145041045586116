import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/a/movimentacoes/')({
    validateSearch: (
        search: Record<string, any>,
    ): {
        tab?: string;
    } => {
        return {
            tab: search?.tab,
        };
    },
});
