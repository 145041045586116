import { createModel } from '@rematch/core';
import { getOneMonthPeriod } from 'std/datetime';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { CobrancaBoletoState } from './types';

const initialState: CobrancaBoletoState = {
    // EFFECTS
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    totalizador: initReduxFn,
    gerarListaPDFs: initReduxFn,
    consultarSituacaoBoleto: initReduxFn,
    getRetornosBancarios: initReduxFn,
    postRetornosBancarios: initReduxFn,
    postRetornosBancariosFile: initReduxFn,

    // TABELAS
    getTable: {
        // FILTROS
        filterDataRangeEmissao: getOneMonthPeriod(),
        filterStatus: '',
        filterNome: '',

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    // MOSTRAR DRAWERS
    showDrawerDetalhes: false,
    showFiltroAvancado: false,
    showDrawerCompartilhar: false,

    // VALORES PARA DRAWERS
    dadosCompartilhar: {
        link: '',
        assunto: '',
        mensagem: '',
        idpk: 0,
    },
};

export const CobrancaBoletoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<CobrancaBoletoState>(),
    effects,
});
