import { HomeOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Breadcrumb, Grid } from 'antd';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useLayoutStore } from 'features/layout/store';
import { useMemo } from 'react';

export function Breadcrumbs() {
    const screens = Grid.useBreakpoint();
    const breadcrumbs = useLayoutStore((s) => s.breadcrumbs);

    const items = useMemo(() => {
        const innerItems: ItemType[] = [];

        const home = {
            title: (
                <Link to='/a/painel-gerencial'>
                    <HomeOutlined style={{ paddingRight: 5 }} />
                    Início
                </Link>
            ),
        };

        innerItems.push(home);

        breadcrumbs?.map((title) => {
            innerItems.push({
                title,
            });
        });

        return innerItems;
    }, [breadcrumbs]);

    return (
        <div
            style={{
                padding: screens.md ? '0.53rem 2rem 0' : '0.53rem 0.66rem 0',
            }}
        >
            <Breadcrumb items={items} separator='>' style={{ fontSize: 17 }} />
        </div>
    );
}
