import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/a/relatorio/$tipo/')({
    validateSearch: (
        search: Record<string, unknown>,
    ): {
        subtipo?: string;
    } => {
        return {
            subtipo: String(search?.subtipo || ''),
        };
    },
});
