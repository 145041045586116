import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/cobranca-cartao')({
    beforeLoad: () =>
        validateRoute({
            podeExibirFintech: true,
            permissao: 'upp_financeiro_cartao',
            nivel: TipoPermissao.Completo,
        }),
    validateSearch: (search: Record<string, unknown>): { idpk?: string } => {
        return {
            idpk: search.idpk as string,
        };
    },
});
