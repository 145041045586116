import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { FinanceiroCategoriaDespesaState } from './types';

const initialState: FinanceiroCategoriaDespesaState = {
    get: initReduxFn,
    getTreeViewData: initReduxFn,
    getOne: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,

    totalRegister: 0,
    initialRegister: 0,
    registersPerPage: 10,
    currentPage: 0,
    updated: false,
    loading: false,
    showModal: false,
    showModalMessageError: false,
};

export const FinanceiroCategoriaDespesaModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<FinanceiroCategoriaDespesaState>(),
    effects,
});
