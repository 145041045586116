import { CheckOutlined } from '@ant-design/icons';
import { Button, Checkbox, notification } from 'antd';
import {
    type ApiFintechTermosDeUsoGetFintech,
    type ApiFintechTermosDeUsoGetParams,
    apiFintechTermosDeUsoPut,
} from 'features/conta-digital/api/apiFintechTermosDeUso';
import type { ApiSistema } from 'features/sistema/types';
import { useState } from 'react';
import { formatDocument } from 'std/format';
import { getGeoLocation, getIp, getOs } from 'std/util';

export function ContaDigitaltermoDeUsoContent({
    title,
    params,
    fintech,
    sistema,
}: {
    title: string;
    params: ApiFintechTermosDeUsoGetParams;
    fintech: ApiFintechTermosDeUsoGetFintech;
    sistema: Partial<ApiSistema>;
}) {
    const [checkCiente, setCheckCiente] = useState(false);
    const [checkAceito, setCheckAceito] = useState(false);
    const [loading, setLoading] = useState(false);

    const confirmar = async (): Promise<void> => {
        try {
            setLoading(true);

            const location = await getGeoLocation();
            if (location.err) {
                notification.error({ message: 'Falha ao obter dados de geolocalização' });
                return;
            }

            const ip = await getIp();
            if (ip.err) {
                notification.error({ message: 'Falha ao obter dados de IP' });
                return;
            }

            const os = getOs();

            await apiFintechTermosDeUsoPut({
                params: {
                    empresa_idpk: params.empresa_idpk,
                    empresa_documento: params.empresa_documento,
                },
                body: {
                    geo_latitude: location.value.latitude.toString(),
                    geo_longitude: location.value.longitude.toString(),
                    geo_ip: ip.value,
                    geo_sistema_operacional: os.os,
                    geo_sistema_operacional_versao: os.version,
                },
            });

            window.location.reload();
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='flex flex-col justify-center p-6 bg-white rounded-md shadow'>
            <p className='text-2xl font-bold text-center'>{title}</p>

            {fintech?.fcp_termos_de_uso_aceito === 'S' && (
                <>
                    <div className='p-3 my-4 bg-gray-100 border rounded-md w-fit broder-gray-300'>
                        <p>{fintech.mensagem}</p>
                    </div>

                    <div className='flex flex-col items-center justify-center'>
                        <a
                            href='https://14-arquivos-777.s3.sa-east-1.amazonaws.com/ContaDigital-Termo-de-uso-flagship.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Button type='primary' icon={<CheckOutlined />}>
                                Ver termos de uso
                            </Button>
                        </a>
                    </div>
                </>
            )}

            {fintech?.fcp_termos_de_uso_aceito === 'N' && (
                <>
                    <div className='flex justify-center my-4'>
                        <div className='p-3 bg-gray-100 border rounded-md w-fit broder-gray-300'>
                            <p>Beneficiário: {fintech?.fcp_empresa_nome}</p>
                            <p>Documento: {formatDocument(fintech?.fcp_cnpj_cpf)}</p>
                            <p>Sócio administrador: {fintech?.csp_nome}</p>
                        </div>
                    </div>

                    <Checkbox
                        checked={checkCiente}
                        onChange={(e) => setCheckCiente(e.target.checked)}
                    >
                        Declaro que estou ciente e concordo com a criação da minha conta digital
                        conforme os
                        {sistema?.sis_termos_de_uso ? (
                            <a href={sistema.sis_termos_de_uso} target='_blank' rel='noreferrer'>
                                {' '}
                                termos e condições da plataforma
                            </a>
                        ) : (
                            ' termos e condições da plataforma'
                        )}
                        .
                    </Checkbox>

                    <Checkbox
                        checked={checkAceito}
                        onChange={(e) => setCheckAceito(e.target.checked)}
                    >
                        Declaro que li e aceito os termos de uso conforme descrito neste{' '}
                        <a
                            href='https://14-arquivos-777.s3.sa-east-1.amazonaws.com/ContaDigital-Termo-de-uso-flagship.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            documento
                        </a>
                        .
                    </Checkbox>

                    <div className='flex flex-col items-center justify-center mt-4'>
                        <Button
                            type='primary'
                            icon={<CheckOutlined />}
                            disabled={!(checkCiente && checkAceito)}
                            loading={loading}
                            onClick={confirmar}
                        >
                            Confirmar
                        </Button>
                    </div>
                </>
            )}

            <div className='flex flex-col items-center justify-center mt-4'>
                <a
                    href={`https://transparencyreport.google.com/safe-browsing/search?url=${sistema?.sis_base_url}&hl=pt-PT`}
                    target='_blank'
                    rel='noreferrer'
                >
                    <img
                        src='/resources/images/google-safe-browsing.3c36fead.png'
                        alt='safe browsing'
                        width={100}
                    />
                </a>
            </div>
        </div>
    );
}
