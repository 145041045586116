import {
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    TwitterOutlined,
    WhatsAppOutlined,
    YoutubeOutlined,
} from '@ant-design/icons';
import { Button, Col, Image, Row, Typography } from 'antd';
import { useStateControlCatalogo } from 'pages/catalogo-produtos/useStateControlCatalogo';
import { useMemo } from 'react';
import { styled } from 'styled-components';
import style from './style.module.scss';

const { Paragraph } = Typography;

const StyledLinksContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    font-size: 24px !important;
    &:focus {
      border: 0 !important;
    }
  }
`;

export function Footer() {
    const {
        catalogoProdutos: {
            getCatalogoHeaderData: { data },
            primaryColor,
        },
    } = useStateControlCatalogo();

    const empresa = data?.empresa;

    const linksRedesSociais = useMemo(() => {
        const links: { title; target: string }[] = [];

        if (!empresa?.empresa_slug) {
            return links;
        }

        const {
            ems_instagram,
            ems_whatsapp,
            ems_youtube,
            ems_linkedin,
            ems_twitter,
            ems_facebook,
        } = empresa.empresa_slug;

        if (ems_instagram) {
            links.push({
                title: <InstagramOutlined />,
                target: ems_instagram,
            });
        }

        if (ems_whatsapp) {
            links.push({
                title: <WhatsAppOutlined />,
                target: ems_whatsapp,
            });
        }

        if (ems_youtube) {
            links.push({
                title: <YoutubeOutlined />,
                target: ems_youtube,
            });
        }

        if (ems_linkedin) {
            links.push({
                title: <LinkedinOutlined />,
                target: ems_linkedin,
            });
        }

        if (ems_twitter) {
            links.push({
                title: <TwitterOutlined />,
                target: ems_twitter,
            });
        }

        if (ems_facebook) {
            links.push({
                title: <FacebookOutlined />,
                target: ems_facebook,
            });
        }

        return links;
    }, [empresa]);

    if (!empresa?.empresa_slug) {
        return null;
    }
    return (
        <>
            <Row
                className={style['footer-container']}
                style={{ backgroundColor: primaryColor }}
                hidden={!linksRedesSociais.length}
            >
                <Col className={style['footer-brand-col']}>
                    <Paragraph className={style['footer-brand-title']}>
                        Acompanhe-nos nas redes sociais
                    </Paragraph>
                    <StyledLinksContainer>
                        {linksRedesSociais.map((link) => (
                            <Button
                                type='link'
                                key={link.target}
                                href={link.target}
                                target='_blank'
                            >
                                {link.title}
                            </Button>
                        ))}
                    </StyledLinksContainer>
                </Col>
            </Row>

            <Row>
                <Col className={style['footer-brand-col']}>
                    <StyledLinksContainer>
                        <Button type='link' target='_blank' href={data?.sistema.sis_landing_page}>
                            <Paragraph className={style['footer-desenvolvido-por']}>
                                Desenvolvido por:
                                <Image src={data?.sistema.sis_logotipo} preview={false} />
                            </Paragraph>
                        </Button>
                    </StyledLinksContainer>
                </Col>
            </Row>
        </>
    );
}
