import dayjs, { type Dayjs } from 'dayjs';
import type { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'std/const/date';

/**
 * Converte segundos em 'minutos:segundos'
 * ex: 60 > "01:00"
 */
export function formatSecondsToMinutes(seconds: number): string {
    const min = Math.floor((seconds / 60) % 60)
        .toString()
        .padStart(2, '0');

    const sec = Math.floor((seconds % 60) % 60)
        .toString()
        .padStart(2, '0');

    return `${min}:${sec}`;
}

/** Retorna a data atual no formato dia/mes/ano */
export function getCurrentDate(): string {
    return dayjs().format(DATE_FORMAT);
}

/** Retorna a data e tempo atual no formato dia/mes/ano hora:minuto:segundo */
export function getCurrentDateTime(): string {
    return dayjs().format(DATE_TIME_FORMAT);
}

/** Retorna o período de um mês até a data atual */
export function getOneMonthPeriod(): Interval<Dayjs> {
    return {
        start: dayjs().add(-1, 'month'),
        end: dayjs(),
    };
}

/** NoUndefinedRangeValueType (antd) -> Interval (std) */
export function rangePickerToInterval(
    value: NoUndefinedRangeValueType<dayjs.Dayjs> | null | undefined,
): Interval<Dayjs> | undefined {
    if (value?.[0] && value[1]) {
        const [start, end] = value;
        return { start, end };
    }

    return undefined;
}

/** Interval (std) -> NoUndefinedRangeValueType (antd) */
export function intervalToRangePicker(value: Interval<Dayjs>): NoUndefinedRangeValueType<Dayjs> {
    return [value.start, value.end];
}

export function getMonthAgo(): Interval<Dayjs> {
    const oneMonthAgo = dayjs().subtract(1, 'month');

    return {
        start: oneMonthAgo.startOf('month'),
        end: oneMonthAgo.endOf('month'),
    };
}

export enum DateInterval {
    TODAY_INTERVAL = 'todayInterval',
    WEEK_INTERVAL = 'weekInterval',
    MONTH_INTERVAL = 'monthInterval',
    LAST_30_DAYS = 'last30Days',
    NEXT_30_DAYS = 'next30Days',
    NEXT_7_DAYS = 'next7Days',
}

/** Retorna um intervalo de datas */
export function getDateInterval(interval: DateInterval): Interval<dayjs.Dayjs> {
    const now = dayjs();

    let start = now;
    let end = now;

    switch (interval) {
        case DateInterval.TODAY_INTERVAL:
            break;

        case DateInterval.WEEK_INTERVAL: {
            start = now.startOf('week');
            end = now.endOf('week');
            break;
        }

        case DateInterval.MONTH_INTERVAL: {
            start = now.startOf('month');
            end = now.endOf('month');
            break;
        }

        case DateInterval.LAST_30_DAYS: {
            start = now.subtract(29, 'day');
            end = now;
            break;
        }

        case DateInterval.NEXT_30_DAYS: {
            start = now;
            end = now.add(30, 'day');
            break;
        }

        case DateInterval.NEXT_7_DAYS: {
            start = now;
            end = now.add(7, 'day');
            break;
        }

        default: {
            break;
        }
    }

    return { start, end };
}
