import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ApiEmpresaSituacao } from 'features/empresa/types/ApiEmpresaSituacao';
import { useEmpresaBloqueada } from '../hooks/useEmpresaBloqueada';
import { useEmpresaQuery } from '../hooks/useEmpresaQuery';

export function HeaderPendente() {
    const { empresa } = useEmpresaQuery();
    const { bloqueado, empresaSituacao } = useEmpresaBloqueada(empresa);

    const chamarNoWhats = (): void => {
        const whats = empresa?.representante?.rep_suporte_whatsapp;

        if (whats) {
            window.open(`https://wa.me/55${whats}`);
        }
    };

    if (empresaSituacao === ApiEmpresaSituacao.Aprovada || bloqueado) {
        return null;
    }

    return (
        <div style={{ position: 'sticky', top: 0, background: '#e6cf02', zIndex: 999, padding: 6 }}>
            <WarningOutlined style={{ fontSize: 16, paddingRight: 5, fontWeight: 'bold' }} />
            <span style={{ fontSize: 14 }}>
                Atenção, existem cobranças em aberto para sua empresa. Efetue o pagamento até{' '}
                <b>{empresa?.emp_data_bloqueio}</b> e evite o bloqueio de seu sistema.
            </span>

            <Button style={{ float: 'right' }} size='small' onClick={chamarNoWhats}>
                Entrar em contato com o suporte
            </Button>
        </div>
    );
}
