import { numberToCurrency } from 'std/format';

const parseUrl = (cadastro: string): string => {
    return `https://14-arquivos-777.s3.sa-east-1.amazonaws.com/${cadastro}.xlsx`;
};

// obs: isso deve ser igual ao backend e igual ao tipo ApiImportacaoTipo
export const tipoImportacao = [
    'clientes',
    'fornecedores',
    'transportadores',
    'produtos',
    'contasreceber',
    'contaspagar',
] as const;

export type TipoImportacao = (typeof tipoImportacao)[number];

export const cadastrosInfo: Record<
    TipoImportacao,
    {
        artigo: string;
        texto: string;
        modeloLink: string;
        observacao: string;
        colunasAceitas: string;
        colunas: {
            title: string;
            dataIndex: string;
            render?: (value: any) => React.ReactNode;
        }[];
    }
> = {
    clientes: {
        artigo: 'os',
        texto: 'clientes',
        modeloLink: parseUrl('Clientes'),
        observacao:
            'Atenção: Caso um dos campos de endereço seja especificado, os demais (com exceção de complemento e bairro) são obrigatórios.',
        colunasAceitas: `
      Código | CPF/CNPJ | RG/IE | Nome/Razão social* | Apelido/Nome fantasia |
      Consumidor Final | CRT | Data Nascimento/Criação | Responável | Limite de Crédito |
      Email 01 | Email 02 | Fone 01 | Fone 02 | Vendedor 1 | Vendedor 2 | Endereço | Número |
      Complemento | Bairro | Cidade | UF | CEP | Tabela de Preço | Atividade do Cliente |
      Transportador | Observações
    `,
        colunas: [
            {
                title: 'Nome/Razão social',
                dataIndex: 'pes_nome_razaosocial',
            },
            {
                title: 'CPF/CNPJ',
                dataIndex: 'pes_cnpj_cpf',
            },
            {
                title: 'RG/IE',
                dataIndex: 'pes_ie_rg',
            },
            {
                title: 'Mensagem',
                dataIndex: 'mensagem',
            },
        ],
    },
    fornecedores: {
        artigo: 'os',
        texto: 'fornecedores',
        modeloLink: parseUrl('Fornecedores'),
        observacao:
            'Atenção: Caso um dos campos de endereço seja especificado, os demais (com exceção de complemento e bairro) são obrigatórios.',
        colunasAceitas: `
      CÓDIGO | CPF/CNPJ | RG/IE | Nome/Razão social* | Apelido/Nome fantasia |
      Email 01 | Email 02 | Fone 01 | Fone 02 | Endereço | Número | Complemento |
      Bairro | Cidade | UF | CEP | Observações
    `,
        colunas: [
            {
                title: 'Nome/Razão social',
                dataIndex: 'pes_nome_razaosocial',
            },
            {
                title: 'CPF/CNPJ',
                dataIndex: 'pes_cnpj_cpf',
            },
            {
                title: 'RG/IE',
                dataIndex: 'pes_ie_rg',
            },
            {
                title: 'Mensagem',
                dataIndex: 'mensagem',
            },
        ],
    },
    produtos: {
        artigo: 'os',
        texto: 'produtos',
        modeloLink: parseUrl('Produtos'),
        observacao: '',
        colunasAceitas: `
      CÓDIGO | CÓDIGO SKU | ESTOQUE | DESCRIÇÃO DO PRODUTO | PREÇO DE COMPRA |
      PREÇO DE VENDA | CÓDIGO DE BARRAS (GTIN/EAN) | UNIDADE | PESO BRUTO (KG) |
      PESO LÍQUIDO (KG) | ESTOQUE MÍNIMO | NCM | CEST | CÓDIGO DA REGRA FISCAL |
      BC ICMS ST RET ANTERIORMENTE | ALÍQUOTA ICMS ST RET ANTERIORMENTE |
      VALOR ICMS ST RET ANTERIORMENTE | VALOR ICMS PRÓPRIO DO SUBSTITUTO RET ANTERIORMENTE |
      PERCENTUAL FCP DO ICMS ST RET ANTERIORMENTE`,
        colunas: [
            {
                title: 'Código de Barras',
                dataIndex: 'pro_cod_barra',
            },
            {
                title: 'Descrição do Produto',
                dataIndex: 'pro_descricao',
            },
            {
                title: 'Preço de Custo',
                dataIndex: 'pro_preco_custo',
                render: (value) => numberToCurrency(value),
            },
            {
                title: 'Mensagem',
                dataIndex: 'mensagem',
            },
        ],
    },
    transportadores: {
        artigo: 'os',
        texto: 'transportadores',
        modeloLink: parseUrl('Transportadores'),
        observacao:
            'Atenção: Caso um dos campos de endereço seja especificado, os demais (com exceção de complemento e bairro) são obrigatórios.',
        colunasAceitas: `
      CÓDIGO | CPF/CNPJ | RG/IE | Nome/Razão social* | Apelido/Nome fantasia |
      Email 01 | Email 02 | Fone 01 | Fone 02 | Endereço | Número | Complemento |
      Bairro | Cidade | UF | CEP | Observações
    `,
        colunas: [
            {
                title: 'Nome/Razão social',
                dataIndex: 'pes_nome_razaosocial',
            },
            {
                title: 'CPF/CNPJ',
                dataIndex: 'pes_cnpj_cpf',
            },
            {
                title: 'RG/IE',
                dataIndex: 'pes_ie_rg',
            },
            {
                title: 'Mensagem',
                dataIndex: 'mensagem',
            },
        ],
    },
    contaspagar: {
        artigo: 'as',
        texto: 'contas a pagar',
        modeloLink: parseUrl('Contas+a+Pagar'),
        observacao: '',
        colunasAceitas: `
      CÓDIGO DO FORNECEDOR | CPF/CNPJ | NOME/RAZÃO SOCIAL |
      GRUPO DE PARCELAS | PARCELA | VENCIMENTO | VALOR TOTAL |
      VALOR PAGO | DATA ÚLTIMO PAGAMENTO | CÓDIGO DA CONTA FINANCEIRA | DESCRIÇÃO
    `,
        colunas: [
            {
                title: 'Data de vencimento',
                dataIndex: 'fmo_data_vencimento',
            },
            {
                title: 'Nome/Razão Social',
                dataIndex: 'fmo_sacado_nome',
            },
            {
                title: 'Valor',
                dataIndex: 'fmo_valor',
                render: (value) => numberToCurrency(value),
            },
            {
                title: 'Mensagem',
                dataIndex: 'mensagem',
            },
        ],
    },
    contasreceber: {
        artigo: 'as',
        texto: 'contas a receber',
        modeloLink: parseUrl('Contas+a+Receber'),
        observacao: '',
        colunasAceitas: `
      CÓDIGO DO CLIENTE | CPF/CNPJ | NOME/RAZÃO SOCIAL | GRUPO DE PARCELAS |
      PARCELA | VENCIMENTO | VALOR TOTAL | VALOR PAGO | DATA ÚLTIMO PAGAMENTO |
      MULTA (%) | JUROS MENSAL (%) | DESCRIÇÃO
    `,
        colunas: [
            {
                title: 'Data de vencimento',
                dataIndex: 'fmo_data_vencimento',
            },
            {
                title: 'Nome/Razão Social',
                dataIndex: 'fmo_sacado_nome',
            },
            {
                title: 'Valor',
                dataIndex: 'fmo_valor',
                render: (value) => numberToCurrency(value),
            },
            {
                title: 'Mensagem',
                dataIndex: 'mensagem',
            },
        ],
    },
};
